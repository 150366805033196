import { useState } from "react"
import type { KeyboardEvent } from 'react'
import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import TextField, { type BaseTextFieldProps } from "@mui/material/TextField"
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'

interface RHFPasswordProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    size?: BaseTextFieldProps["size"],
    required?: BaseTextFieldProps["required"],
    autoFocus?: BaseTextFieldProps["autoFocus"],
    disabled?: BaseTextFieldProps["disabled"],
}

const RHFPassword = <
    TField extends FieldValues,
>(
    props: RHFPasswordProps<TField>
) => {

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const {
        control,
        name,
        label,
        size,
        required,
        autoFocus,
        disabled,
    } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <TextField
                        id={'rhf-password-'+name}
                        label={label}
                        size={size}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        onKeyDown={
                            (e:KeyboardEvent<HTMLInputElement>) => {
                                if (!(e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={
                                            showPassword ? 'hide the password' : 'display the password'
                                        }
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        onMouseUp={handleMouseUpPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...field}
                    />
                )
            }}
        />
    )
}

export default RHFPassword
