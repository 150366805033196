import Button from '@mui/material/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'

import { HISTORY_RETURN_PARAM_KEY } from '@/constants/general'

export default function HistoryReturnButton() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const historyReturn = params.get(HISTORY_RETURN_PARAM_KEY)

    const navigate = useNavigate()

    return historyReturn === 'true' ? (
        <Button
            onClick={() => navigate(-1)}
            variant='text'
            startIcon={<ArrowBackRoundedIcon />}
            size='small'
            color='terciary'
            sx={{
                mt: -2,
                ml: -2,
            }}
        >
            Zpět na seznam
        </Button>
    ) : null
}
