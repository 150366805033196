import {
    createRoutesFromElements,
    createBrowserRouter,
    Route,
    Navigate,
} from 'react-router-dom'

// Views Municipality
import CollectionCalendarView from './views/municipality/collection-calendar'
import CollectingYardsView from './views/municipality/collection-yards'
import CollectionNotificationView from './views/municipality/collection-notification'
import WasteFeeView from './views/municipality/waste-fee'
import HowToPayLessView from './views/municipality/how-to-pay-less'
import LargeContainerMapView from './views/municipality/large-container-map'
import CollectionPointListView from './views/municipality/collection-point-list'
import ReportIllegalDumpView from './views/municipality/report-illegal-dump'
import MunicipalitySortingTrendView from './views/municipality/municipality-sorting-trend'
// import ContactsMunicipalityView from './views/municipality/contacts-municipality'
// import ContactsCollectionCompanyView from './views/municipality/contacts-collection-company'
import ContactsView from './views/municipality/contacts'
// Views Collection point
import DetailView from './views/collection-point/detail'
import DisposalTrendView from './views/collection-point/disposal-trend'
import CollectionClaimView from './views/collection-point/collection-claim'
import ExtraCollectionView from './views/collection-point/extra-collection'
import LargeContainerView from './views/collection-point/large-container'
// Views Municipality management
import MotivationModelView from './views/municipality-management/motivation-model'
import MotivationModelDetailView from './views/municipality-management/motivation-model/detail'
import MotivationModelNewView from './views/municipality-management/motivation-model/new'
import CollectionPointCategoriesView from './views/municipality-management/collection-point-categories'
import IllegalDumpsView from './views/municipality-management/illegal-dumps'
import IllegalDumpDetailView from './views/municipality-management/illegal-dumps/detail'
import CollectionNotificationmanagementView from './views/municipality-management/collection-nofitication-management'
import CollectedWasteStatDetailView from './views/municipality-management/collected-waste-stats/detail'
import CollectedWasteStatsView from './views/municipality-management/collected-waste-stats'
import FinancesView from './views/municipality-management/finances'
// Views App management
import EkokomBonusView from './views/app-management/ekokom-bonus'
import HouseholdRefreshView from './views/municipality-management/household-refresh'
// Views Public
import PublicHomeView from './views/public/home'

import ErrorView from './views/error'
import HomeView from './views/home'
import { ROUTE_SEGMENTS } from './constants/general'
import PrivateLayout from './layouts/private'
import PublicLayout from './layouts/public'
import UsersView from './views/app-management/users'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorView />}>
            <Route path="/" element={<Navigate to={ROUTE_SEGMENTS.public} />} />
            <Route
                path={`${ROUTE_SEGMENTS.public}`}
                element={<PublicLayout />}
            >
                <Route index element={<PublicHomeView />} />
                    <Route
                        path={ROUTE_SEGMENTS.collectionCalendar}
                        element={<CollectionCalendarView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectingYards}
                        element={<CollectingYardsView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionNotification}
                        element={<CollectionNotificationView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.wasteFee}
                        element={<WasteFeeView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.howToPayLess}
                        element={<HowToPayLessView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.largeContainerMap}
                        element={<LargeContainerMapView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionPointList}
                        element={<CollectionPointListView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.reportIllegalDump}
                        element={<ReportIllegalDumpView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.municipalitySortingTrend}
                        element={<MunicipalitySortingTrendView />}
                    />
                    {/* <Route
                        path={ROUTE_SEGMENTS.contactsMunicipality}
                        element={<ContactsMunicipalityView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.contactsCollectionCompany}
                        element={<ContactsCollectionCompanyView />}
                    /> */}
                    <Route
                        path={ROUTE_SEGMENTS.contacts}
                        element={<ContactsView />}
                    />
            </Route>
            <Route
                path={ROUTE_SEGMENTS.base}
                element={<PrivateLayout />}
            >
                <Route index element={<HomeView />} />
                <Route
                    path={ROUTE_SEGMENTS.municipality}
                >
                    <Route
                        path={ROUTE_SEGMENTS.collectionCalendar}
                        element={<CollectionCalendarView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectingYards}
                        element={<CollectingYardsView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionNotification}
                        element={<CollectionNotificationView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.wasteFee}
                        element={<WasteFeeView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.howToPayLess}
                        element={<HowToPayLessView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.largeContainerMap}
                        element={<LargeContainerMapView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionPointList}
                        element={<CollectionPointListView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.reportIllegalDump}
                        element={<ReportIllegalDumpView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.municipalitySortingTrend}
                        element={<MunicipalitySortingTrendView />}
                    />
                    {/* <Route
                        path={ROUTE_SEGMENTS.contactsMunicipality}
                        element={<ContactsMunicipalityView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.contactsCollectionCompany}
                        element={<ContactsCollectionCompanyView />}
                    /> */}
                    <Route
                        path={ROUTE_SEGMENTS.contacts}
                        element={<ContactsView />}
                    />
                </Route>
                <Route
                    path={ROUTE_SEGMENTS.collectionPoint}
                >
                    <Route
                        path={ROUTE_SEGMENTS.detail}
                    >
                        <Route index element={<DetailView />} />
                        {/* <Route
                            path={ROUTE_SEGMENTS.edit}
                            element={<HomeView />}
                        /> */}
                    </Route>
                    <Route
                        path={ROUTE_SEGMENTS.disposalTrend}
                        element={<DisposalTrendView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionClaim}
                        element={<CollectionClaimView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.extraCollection}
                        element={<ExtraCollectionView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.largeContainer}
                        element={<LargeContainerView />}
                    />
                </Route>
                <Route
                    path={ROUTE_SEGMENTS.municipalityManagement}
                >
                    <Route
                        path={ROUTE_SEGMENTS.motivationProgram}
                    >
                        <Route index element={<MotivationModelView />} />
                        <Route
                            path={ROUTE_SEGMENTS.new}
                            element={<MotivationModelNewView />}
                        />
                        <Route
                            path={`:motivationModelId/:${ROUTE_SEGMENTS.step}?/:stepNumber?`}
                            element={<MotivationModelDetailView />}
                        />
                    </Route>
                    <Route
                        path={ROUTE_SEGMENTS.collectionPointCategories}
                        element={<CollectionPointCategoriesView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.illegalDumps}
                    >
                        <Route index element={<IllegalDumpsView />} />
                        <Route
                            path={':illegalDumpId'}
                            element={<IllegalDumpDetailView />}
                        />
                    </Route>
                    <Route
                        path={ROUTE_SEGMENTS.finances}
                        element={<FinancesView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectionNotificationManagement}
                        element={<CollectionNotificationmanagementView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.collectedWasteStats}
                    >
                        <Route index element={<CollectedWasteStatsView />} />
                        <Route
                            path={':monthYear'}
                            element={<CollectedWasteStatDetailView />}
                        />
                    </Route>
                </Route>
                <Route
                    path={ROUTE_SEGMENTS.appManagement}
                >
                    <Route
                        path={ROUTE_SEGMENTS.ekokomBonus}
                        element={<EkokomBonusView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.users}
                        element={<UsersView />}
                    />
                    <Route
                        path={ROUTE_SEGMENTS.householdRefresh}
                        element={<HouseholdRefreshView />}
                    />
                </Route>
                <Route
                    path={ROUTE_SEGMENTS.signOut}
                    element={<HomeView />}
                />
            </Route>
        </Route>
    )
)

export default router
