import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Link } from 'react-router-dom'

import { ROUTE_SEGMENTS } from '@/constants/general'
import { UserRole, type MotivationModel } from '@/types'
import PermissionsGate from '../permissions-gate'

export default function EkokomNotification({
    motivationModel,
}: {
    motivationModel: MotivationModel,
}) {

    if (!motivationModel.ekokom_basic_bonus || !motivationModel.ekokom_collection_yard_bonus || !motivationModel.ekokom_other_bonus) {
        return (
            <Box mt={1.5}>
                <Alert
                    severity="error"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(2, 0),
                    })}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        Ve zvoleném období {!motivationModel.ekokom_basic_bonus && !motivationModel.ekokom_collection_yard_bonus && !motivationModel.ekokom_other_bonus ? 'nemáte vyplněné žádné' : 'máte jen částečně vyplněné'} údaje o bonusech od EKOKOMu.
                        Požádejte prosím Vaši svozovou společnost o doplnění ve Správě aplikace.
                        <PermissionsGate userRoles={[ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ]}>
                            <Box display="flex" justifyContent="center" pr={4} pt={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    component={Link}
                                    to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.appManagement}/${ROUTE_SEGMENTS.ekokomBonus}`}
                                >
                                    Vyplnit statistiku
                                </Button>
                            </Box>
                        </PermissionsGate>
                    </Box>
                </Alert>
            </Box>
        )
    }

    return null
}
