import dayjs from "dayjs"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import 'dayjs/locale/cs'
import { useAtomValue } from "jotai"
import { alpha } from "@mui/material"

import {
    WASTE_TYPE_ID_COLOR_MAP,
    WASTE_TYPE_ID_LABEL_MAP,
    WASTE_TYPE_CODE_TO_ID_MAP,
} from '@/constants/general'
import { selectedMunicipalityIdAtom } from "@/state"
import { useMunicipalityCollectionCalendar } from "@/hooks/api/use-municipality"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import type { MunicipalityCollectionCalendarEntry } from "@/types"

type GroupedData = {
    [code: string]: MunicipalityCollectionCalendarEntry[]
}

export default function CollectionTables() {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const { status, data, error } = useMunicipalityCollectionCalendar(
        municipalityId,
        dayjs().year(),
    )

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst svozový kalendář pro obec s ID ${municipalityId}`}
        />
    }

    if (data.length === 0) {
        return <Box>
            {`Pro rok ${dayjs().year()} zatím nebyl publikován svozový kalendář.`}
        </Box>
    }

    const groupedData: GroupedData = data.reduce((acc, item) => {
        if (!acc[item.code]) {
            acc[item.code] = []
        }
        acc[item.code].push(item)
        return acc
    }, {} as GroupedData)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            maxWidth: 1180,
        }}>
            {Object.entries(groupedData).map(([wasteTypeCode, routes]) => (
                <Box key={wasteTypeCode} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    gap: 2,
                    backgroundColor: WASTE_TYPE_ID_COLOR_MAP[WASTE_TYPE_CODE_TO_ID_MAP[wasteTypeCode]].light,
                    borderRadius: 2.5,
                    flexGrow: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5,
                    }}>
                        <Box sx={{
                            width: 14,
                            height: 14,
                            borderRadius: 2,
                            backgroundColor: WASTE_TYPE_ID_COLOR_MAP[WASTE_TYPE_CODE_TO_ID_MAP[wasteTypeCode]].main,
                        }}/>
                        <Typography variant="h4" component="h4">
                            {WASTE_TYPE_ID_LABEL_MAP[WASTE_TYPE_CODE_TO_ID_MAP[wasteTypeCode]].long}
                        </Typography>
                    </Box>
                    {routes.map((route, index) => (
                        <Box key={index}>
                            <Typography variant="h5" component="div" mb={1}>
                                {route.regions.join(', ')}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                rowGap: 0.8,
                                columnGap: 1,
                                mb: 0.5,
                            }}>
                                {route.dates.map(date => (
                                    <Chip
                                        key={date}
                                        label={dayjs(date).locale('cs').format('dd D.M.')}
                                        sx={{
                                            backgroundColor: alpha(
                                                WASTE_TYPE_ID_COLOR_MAP[WASTE_TYPE_CODE_TO_ID_MAP[route.code]].main, 0.25
                                            ),
                                            // '& .MuiChip-label::first-letter': {
                                            //     textTransform: 'capitalize',
                                            // },
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            ))}
        </Box>
    )
}
