import { useForm, Controller, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import { useNavigate } from "react-router-dom"
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, ROUTE_SEGMENTS } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import type { CitizenCategoryFormInput, MotivationModel } from "@/types"
import { useSetMotivationModelCategories } from "@/hooks/api/use-motivation-model"
import { convertFromCents } from "@/helpers"
import HeadingWithIcon from "@/components/heading-with-icon"

const categories = [
    {
        text: 'Kategorie\xa01',
        severity: 1,
        color: 'categories.first',
    },
    {
        text: 'Kategorie\xa02',
        severity: 2,
        color: 'categories.second',
    },
    {
        text: 'Kategorie\xa03',
        severity: 3,
        color: 'categories.third',
    },
    {
        text: 'Kategorie\xa04',
        severity: 4,
        color: 'categories.fourth',
    },
    {
        text: 'Kategorie\xa05',
        severity: 5,
        color: 'categories.fifth',
    },
]

const discountTypes = [
    {
        heading: 'Sleva za hmotnost',
        icon: <ScaleRoundedIcon />,
        inputName: 'Weight',
        limitEndAdornment: 'kg'
    },
    {
        heading: 'Sleva za objem',
        icon: <DeleteTwoToneIcon />,
        inputName: 'Volume',
        limitEndAdornment: 'l'
    },
]

const MotivationModelDiscountForm = ({
    motivationModel,
    closeCallback,
} : {
    motivationModel: MotivationModel,
    closeCallback?: () => void,
}) => {

    const navigate = useNavigate()

    const formDataId = `motivationModelDiscountForm-${motivationModel?.id}-v${APP_VERSION}`

    const mappedWeightDiscounts = motivationModel.mixed_weight_categories.map(category => ({
        discount: convertFromCents(category.discount_amount_cents),
        limit: category.value_lt,
    }))

    const mappedVolumeDiscounts = motivationModel.mixed_volume_categories.map(category => ({
        discount: convertFromCents(category.discount_amount_cents),
        limit: category.value_lt,
    }))

    const initialValues: CitizenCategoryFormInput = {
        discountLevel1Weight: mappedWeightDiscounts[4]?.discount ?? '120',
        discountLevel2Weight: mappedWeightDiscounts[3]?.discount ?? '90',
        discountLevel3Weight: mappedWeightDiscounts[2]?.discount ?? '60',
        discountLevel4Weight: mappedWeightDiscounts[1]?.discount ?? '30',
        discountLevel5Weight: mappedWeightDiscounts[0]?.discount ?? '0',
        discountLevel1Volume: mappedVolumeDiscounts[4]?.discount ?? '80',
        discountLevel2Volume: mappedVolumeDiscounts[3]?.discount ?? '60',
        discountLevel3Volume: mappedVolumeDiscounts[2]?.discount ?? '40',
        discountLevel4Volume: mappedVolumeDiscounts[1]?.discount ?? '20',
        discountLevel5Volume: mappedVolumeDiscounts[0]?.discount ?? '0',
        limitLevel1Weight: mappedWeightDiscounts[4]?.limit ?? '100',
        limitLevel2Weight: mappedWeightDiscounts[3]?.limit ?? '160',
        limitLevel3Weight: mappedWeightDiscounts[2]?.limit ?? '250',
        limitLevel4Weight: mappedWeightDiscounts[1]?.limit ?? '400',
        limitLevel5Weight: null,
        limitLevel1Volume: mappedVolumeDiscounts[4]?.limit ?? '600',
        limitLevel2Volume: mappedVolumeDiscounts[3]?.limit ?? '900',
        limitLevel3Volume: mappedVolumeDiscounts[2]?.limit ?? '1200',
        limitLevel4Volume: mappedVolumeDiscounts[1]?.limit ?? '1500',
        limitLevel5Volume: null,
        limitsExtended: false,
        enableWeight: !motivationModel.mixed_weight_categories_disabled,
        enableVolume: !motivationModel.mixed_volume_categories_disabled,
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useSetMotivationModelCategories(
        motivationModel.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback() {
                navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationProgram}/${motivationModel.id}/${ROUTE_SEGMENTS.step}/3`)
            },
        },
    )

    const onSubmit: SubmitHandler<CitizenCategoryFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    const limitsExtended = watch('limitsExtended')

    const enabledStatus = {
        Weight: watch('enableWeight'),
        Volume: watch('enableVolume'),
    }

    return (
        <Container maxWidth={limitsExtended ? "lg" : "md"} sx={{ px: 0 }}>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                closeCallback={closeCallback}
                isLoadedFromLocalStorage={isLoadedFromLocalStorage}
                isDirty={isDirty}
                resetWithVersion={resetWithVersion}
                requestInProgress={requestInProgress}
                buttonText={mappedWeightDiscounts.length > 0 ||
                    mappedVolumeDiscounts.length > 0 ? "Upravit" : "Uložit a pokračovat"
                }
            >
                <Typography variant="h5" component="h3" textAlign="center" gutterBottom>
                    Pro jednotlivé kategorie občanů zvolte slevu na občana za rok.
                </Typography>
                <Box mx="auto" mb={1}>
                    <FormControlLabel control={
                        <Controller
                            name="limitsExtended"
                            control={control}
                            render={({ field }) => <Switch
                                {...field}
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                            />}
                        />
                    } label="Rozšířené nastavení limitů" />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    columnGap: 3,
                    rowGap: 4,
                }}>
                    {discountTypes.map(discountType => (
                        <Box key={discountType.inputName} sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            opacity: !enabledStatus[discountType.inputName] ? 0.4 : undefined,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 2,
                                pr: 2,
                            }}>
                                <HeadingWithIcon
                                    text={discountType.heading}
                                    icon={discountType.icon}
                                />
                            </Box>
                            <Box mx="auto" mb={1}>
                                <FormControlLabel control={
                                    <Controller
                                        name={`enable${discountType.inputName}` as 'enableWeight' | 'enableVolume'}
                                        control={control}
                                        render={({ field }) => <Switch
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />}
                                    />
                                } label={enabledStatus[discountType.inputName] ? "Zapnuto" : "Vypnuto"} />
                            </Box>
                            <Typography
                                variant="body1"
                                component="div"
                                color="categories.first"
                                mb={1}
                                textAlign="center"
                                pr={3}
                            >
                                Nejlépe třídící
                            </Typography>
                            {categories.map(category => (
                                <Box key={category.severity} sx={{
                                    display: 'flex',
                                    gap: 3,
                                    justifyContent: 'center',
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: category.severity === 1 ? 'space-between' : 'flex-end',
                                        maxWidth: 120,
                                        flex: '0 1 120px',
                                    }}>
                                        {category.severity === 1 &&
                                            <Typography variant="body1" component="div" textAlign="right">
                                                0 {discountType.limitEndAdornment}
                                            </Typography>
                                        }
                                        {category.severity !== 5 &&
                                            <>
                                                {limitsExtended ?
                                                    
                                                        <Box mb={-2.5}>
                                                            <RHFNumberTextfield
                                                                name={`limitLevel${category.severity}${discountType.inputName}` as keyof CitizenCategoryFormInput}
                                                                control={control}
                                                                label="Limit"
                                                                endAdornment={discountType.limitEndAdornment}
                                                                thousandSeparator
                                                                disallowNegative
                                                                size="small"
                                                            />
                                                        </Box>
                                                    :
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        textAlign="right"
                                                        mb={-1.2}
                                                    >
                                                        {
                                                            initialValues[
                                                                `limitLevel${category.severity}${discountType.inputName}` as keyof CitizenCategoryFormInput
                                                            ]
                                                        }{' '}
                                                        {discountType.limitEndAdornment}
                                                    </Typography>
                                                }
                                            </>
                                        }
                                            {category.severity === 5 &&
                                                <Typography variant="body1" component="div" textAlign="right">
                                                    ∞ {discountType.limitEndAdornment}
                                                </Typography>
                                            }
                                    </Box>
                                    <Box sx={{
                                        position: 'relative',
                                        flexBasis: 5,
                                        flexShrink: 0,
                                        backgroundColor: category.color,
                                    }}>
                                        {category.severity === 1 &&
                                            <Box sx={{
                                                width: 9,
                                                height: 9,
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                zIndex: 1,
                                                top: -3,
                                                left: -2,
                                                borderRadius: 3,
                                                border: '1.5px solid black',
                                            }}/>
                                        }
                                        <Box sx={{
                                            width: 9,
                                            height: 9,
                                            position: 'absolute',
                                            backgroundColor: 'white',
                                            zIndex: 1,
                                            bottom: -3,
                                            left: -2,
                                            borderRadius: 3,
                                            border: '1.5px solid black',
                                        }}/>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        py: 2,
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <Chip
                                                label={category.text}
                                                size="small"
                                                sx={{
                                                    backgroundColor: category.color,
                                                    color: 'white',
                                                    fontWeight: 500,
                                                    fontSize: '0.875rem',
                                                }}
                                            />
                                        </Box>
                                        <Box maxWidth={146}>
                                            <RHFNumberTextfield
                                                name={`discountLevel${category.severity}${discountType.inputName}` as keyof CitizenCategoryFormInput}
                                                control={control}
                                                label="Sleva"
                                                endAdornment="Kč"
                                                thousandSeparator
                                                disallowNegative
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            <Typography
                                variant="body1"
                                component="div"
                                color="categories.fifth" 
                                mt={1}
                                textAlign="center"
                                pr={3}
                            >
                                Nejhůře třídící
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Form>
        </Container>
    )
}

export default MotivationModelDiscountForm
