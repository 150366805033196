import dayjs, { type Dayjs } from "dayjs"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/cs'
import { useAtomValue } from "jotai"

import type {
    WasteTypeIds,
} from '@/types'
import {
    WASTE_TYPE_ID_COLOR_MAP,
    WASTE_TYPE_ID_LABEL_MAP,
    WASTE_TYPE_CODE_TO_ID_MAP,
} from '@/constants/general'
// import { useCollectionPointCollectionCalendar } from "@/hooks/api/use-collection-point"
import {
    // selectedCollectionPointIdAtom,
    selectedMunicipalityIdAtom,
} from "@/state"
import { useMunicipalityCollectionCalendar } from "@/hooks/api/use-municipality"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"

type YearMap = {
    [year: number]: {
        [month: number]: {
            day: number
            // plan: {
            //     wasteTypeId: WasteTypeIds
            //     binVisibleCode: string
            //     binId: string
            // }[]
            municipalityPlan: {
                wasteTypeId: WasteTypeIds
                regions: string[]
            }[]
        }[]
    }
}

const generateYearsMap = (years: number[]): YearMap => {
    const yearMap: YearMap = {}

    for (const year of years) {
        // Initialize the year if not already in the map
        if (!yearMap[year]) {
            yearMap[year] = {}
        }
    
        // Iterate over each month (1 to 12)
        for (let month = 1; month <= 12; month++) {
            // Get the number of days in the current month
            const daysInMonth = dayjs(`${year}-${month}`).daysInMonth()
    
            // Create an array of day objects for the month
            yearMap[year][month] = Array.from({ length: daysInMonth }, (_, i) => ({
                day: i + 1,
                plan: [],
                municipalityPlan: [],
            }))
        }
    }

    return yearMap
}

const getYearsBetween = (startDate, endDate): Array<number> => {
    const years = []
    let currentYear = startDate.year()
  
    // Loop until the current year exceeds the end year
    while (currentYear <= endDate.year()) {
        years.push(currentYear)
        currentYear++
    }
  
    return years
}

const isWeekend = (date: string | Dayjs): boolean => {
    const day = dayjs(date).day()
    // Get the day of the week (0 = Sunday, 6 = Saturday)
    return day === 0 || day === 6
  }

export default function CollectionCalendar() {

    const yearMap = generateYearsMap(getYearsBetween(dayjs().startOf('year'), dayjs().endOf('year')))

    // const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)
    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    // const { data: dataPlan } = useCollectionPointCollectionCalendar(
    //     collectionPointId,
    //     dayjs().year(),
    // )

    const { status, data: dataMunicipalityPlan, error } = useMunicipalityCollectionCalendar(
        municipalityId,
        dayjs().year(),
    )

    // if (dataPlan) {
    //     for (const entry of dataPlan) {
    //         const date = dayjs(entry.date)
    //         const year = date.year()
    //         const month = date.month()+1
    //         const day = date.date()-1

    //         yearMap[year][month][day].plan.push({
    //             wasteTypeId: WASTE_TYPE_CODE_TO_ID_MAP[entry.material_code],
    //             binVisibleCode: entry.bin_visible_code,
    //             binId: entry.bin_id
    //         })
    //     }
    // }

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst svozový kalendář pro obec s ID ${municipalityId}`}
        />
    }

    if (dataMunicipalityPlan) {
        for (const entry of dataMunicipalityPlan) {
            for (const calendarDate of entry.dates) {
                const date = dayjs(calendarDate)
                const year = date.year()
                const month = date.month()+1
                const day = date.date()-1

                yearMap[year][month][day].municipalityPlan.push({
                    wasteTypeId: WASTE_TYPE_CODE_TO_ID_MAP[entry.code],
                    regions: entry.regions,
                })
            }
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            mb: 2,
        }}>
            {Object.keys(yearMap).map(year => (
                <Box key={year} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                }}>
                    <Typography variant="h3" component="h5" gutterBottom>Přehled {year}</Typography>
                    {Object.keys(yearMap[Number(year)]).map(month => (
                        <Box key={month} sx={{
                            display: 'flex',
                            gap: 0.5,
                        }}>
                            <Typography variant="body1" component="div" width={74} flexShrink={0}>
                                {dayjs().month(Number(month)-1).locale('cs').format('MMMM')}
                            </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 0.5,
                                    flexWrap: 'wrap',
                                }}>
                                {yearMap[Number(year)][Number(month)].map((dayObj, index) => (
                                    <Box key={index} sx={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#DEDEDE',
                                        borderRadius: 1,
                                        display: 'flex',
                                        marginRight: {
                                            xs: 0,
                                            lg: (index+1)%5 === 0 ? 1 : 0,
                                        },
                                        fontWeight: 600,
                                    }}>
                                        {dayObj.municipalityPlan.length === 0 &&
                                            <Box key={index} sx={(theme) => ({
                                                position: 'relative',
                                                flexGrow: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid transparent',
                                                borderRadius: 1,
                                                color: 'white',
                                                '&:hover': {
                                                    // backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light,
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                },
                                                '&:hover .hover-label': {
                                                    display: 'flex',
                                                },
                                                ...(isWeekend(`${year}-${month}-${dayObj.day}`) ? {
                                                    textDecoration: 'underline white',
                                                    textUnderlineOffset: '4px',
                                                    textDecorationThickness: '2px'
                                                } : {})
                                            })}>
                                                {dayObj.day}
                                                <Box sx={{
                                                    position: 'absolute',
                                                    display: 'none',
                                                    backgroundColor: '#CECECE',
                                                    borderRadius: 1,
                                                    padding: 1,
                                                    zIndex: 2,
                                                    top: 32,
                                                    flexDirection: 'column',
                                                    gap: 0.5,
                                                    minWidth: 116,
                                                    color: 'terciary.main',
                                                }} className="hover-label">
                                                    <Typography variant="body2" component="div">
                                                        {dayjs(`${year}-${month}-${dayObj.day}`).locale('cs').format('dd D.M.YYYY')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {dayObj.municipalityPlan.map((plannedDump, index) => (
                                            <Box key={index} sx={(theme) => ({
                                                position: 'relative',
                                                flexGrow: 1,
                                                backgroundColor: WASTE_TYPE_ID_COLOR_MAP[plannedDump.wasteTypeId].main,
                                                border: `1px solid ${WASTE_TYPE_ID_COLOR_MAP[plannedDump.wasteTypeId].main}`,
                                                borderRadius: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                },
                                                '&:hover .hover-label': {
                                                    display: 'flex',
                                                }
                                            })}>
                                                <Box sx={{
                                                    position: 'absolute',
                                                    display: 'none',
                                                    backgroundColor: WASTE_TYPE_ID_COLOR_MAP[plannedDump.wasteTypeId].light,
                                                    borderRadius: 1,
                                                    padding: 1,
                                                    zIndex: 2,
                                                    top: 32,
                                                    flexDirection: 'column',
                                                    gap: 0.5,
                                                    minWidth: 220,
                                                }} className="hover-label">
                                                    <Typography variant="h6" component="div">{WASTE_TYPE_ID_LABEL_MAP[plannedDump.wasteTypeId].long}</Typography>
                                                    <Typography variant="body2" component="div">Datum: {dayjs(`${year}-${month}-${dayObj.day}`).locale('cs').format('dd D.M.YYYY')}</Typography>
                                                    <Typography variant="body2" component="div">
                                                        Oblasti: {plannedDump.regions.join(', ')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            ))}
        </Box>
    )
}
