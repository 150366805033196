import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import CommentRoundedIcon from '@mui/icons-material/CommentRounded'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useNavigate } from "react-router-dom"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import IllegalDumpsActions from './actions'
import PermissionsGate from '@/components/permissions-gate'
import {
    type IllegalDump,
    type IllegalDumpingReportPublic,
    type IllegalDumpPublic,
    type IllegalDumpPublicList,
    ReportStates,
    UserRole,
} from '@/types'
import StateLabel from '@/components/state-label'
import IllegalDumpReportForm from '@/components/forms/illegal-dump-report'
import IllegalDumpInProgressForm from '@/components/forms/illegal-dump-in-progress'
import IllegalDumpResolvedForm from '@/components/forms/illegal-dump-resolved'
import ArchiveDialog from './archive-dialog'
import { ROUTE_SEGMENTS } from '@/constants/general'
import ReportCard from './report-card'
import ReportArchiveDialog from './report-archive-dialog'

function SingleDumpDetail({
    illegalDump,
    handleClose,
    municipality,
}: {
    illegalDump: IllegalDumpPublic | IllegalDump,
    handleClose?: () => void,
    municipality?: boolean,
}) {

    const navigate = useNavigate()

    const [addReport, setAddReport] = useState(false)

    // Action dialogs
    const [stateInProgress, setStateInProgress] = useState<IllegalDumpPublicList | null>(null)
    const [stateResolved, setStateResolved] = useState<IllegalDumpPublicList | null>(null)
    const [archive, setArchive] = useState<IllegalDumpPublicList | null>(null)
    const [archiveReport, setArchiveReport] = useState<IllegalDumpingReportPublic | null>(null)

    const archivationSuccessCallback = () => {
        setArchive(null)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.illegalDumps}`)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            position: 'relative',
        }}>
            <Box>
                <Typography variant="h3" component="h3">
                    Nahlášená černá skládka
                    <Box component="span" color="customGrey.secondaryText"> ID {illegalDump.id}</Box>
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 4,
                flexWrap: 'wrap',
            }}>
                <Box>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Lokalita
                    </Typography>
                    <Typography variant="body1" component="p">
                        {illegalDump.address}
                    </Typography>
                    <Typography variant="body1" component="p" color="customGrey.secondaryText">
                        {illegalDump.latitude} {illegalDump.longitude}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Stav
                    </Typography>
                    <StateLabel state={illegalDump.state} />
                    <Typography variant="body1" component="div" color="customGrey.secondaryText">
                        {illegalDump.resolved_at ? dayjs(illegalDump.resolved_at).format('D. M. YYYY HH:mm') : null}
                    </Typography>
                </Box>
            </Box>
            {'sent_at' in illegalDump && illegalDump.sent_at &&
                <Box>
                    <Typography variant="h5" component="h3">
                        Odesláno
                    </Typography>
                    <Typography variant="body1" component="p">
                        {dayjs(illegalDump.sent_at as string).format('D. M. YYYY HH:mm')}
                    </Typography>
                </Box>
            }
            {'sender_email' in illegalDump && illegalDump.sender_email &&
                <Box>
                    <Typography variant="h5" component="h3">
                        Email
                    </Typography>
                    <Typography variant="body1" component="p">
                        {illegalDump.sender_email as string}
                    </Typography>
                </Box>
            }
            <Box>
                <Typography variant="h5" component="h3" mb={1.5}>
                    Hlášení ({illegalDump.illegal_dumping_reports.length})
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    {illegalDump.illegal_dumping_reports.map(report => (
                        <ReportCard key={report.id} illegalDumpReport={report} setArchiveReport={setArchiveReport}/>
                    ))}
                </Box>
            </Box>
            {municipality ?
                <PermissionsGate
                    userRoles={[
                        UserRole.admin,
                        UserRole.municipalityOfficial,
                    ]}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 3,
                    }}>
                        {illegalDump.state === ReportStates.inProgress ?
                            <Button
                                variant="contained"
                                color='primary'
                                startIcon={<CheckCircleRoundedIcon />}
                                onClick={() => setStateResolved(illegalDump)}
                            >
                                Vyřešeno
                            </Button>
                            :
                            <Button
                                variant="contained"
                                color='terciary'
                                startIcon={<HourglassBottomRoundedIcon />}
                                onClick={() => setStateInProgress(illegalDump)}
                            >
                                V řešení
                            </Button>
                        }
                    </Box>
                </PermissionsGate>
                :
                <>                
                    {addReport ?
                        <IllegalDumpReportForm illegalDumpId={illegalDump.id} closeCallback={() => setAddReport(false)} />
                        :
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            pb: 3,
                        }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: 'reports.icon',
                                    '&:hover': {
                                        backgroundColor: 'reports.icon',
                                    }
                                }}
                                startIcon={<CommentRoundedIcon />}
                                onClick={() => setAddReport(true)}
                            >
                                Tato skládka mne také trápí
                            </Button>
                        </Box>
                    }
                </>
            }
            {handleClose ? 
                <Box sx={{
                    position: 'absolute',
                    top: -8,
                    right: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '1rem',
                }}>
                    <IconButton
                        onClick={handleClose}
                        color="terciary"
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
                :
                <PermissionsGate
                    userRoles={[
                        UserRole.admin,
                        UserRole.municipalityOfficial,
                    ]}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '1rem',
                    }}>
                        <IllegalDumpsActions
                            illegalDump={illegalDump}
                            setStateInProgress={setStateInProgress}
                            setStateResolved={setStateResolved}
                            setArchive={setArchive}
                        />
                    </Box>
                </PermissionsGate>
            }
            <Dialog
                key={'stateInProgress-'+stateInProgress?.id}
                open={!!stateInProgress}
                onClose={() => setStateInProgress(null)}
            >
                <DialogContent>
                    <IllegalDumpInProgressForm
                        illegalDump={stateInProgress}
                        closeCallback={() => setStateInProgress(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'stateResolved-'+stateResolved?.id}
                open={!!stateResolved}
                onClose={() => setStateResolved(null)}
            >
                <DialogContent>
                    <IllegalDumpResolvedForm
                        illegalDump={stateResolved}
                        closeCallback={() => setStateResolved(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        illegalDumpId={archive?.id}
                        handleClose={() => setArchive(null)}
                        successCallback={() => archivationSuccessCallback()}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archiveReport-'+archiveReport?.id}
                open={!!archiveReport}
                onClose={() => setArchiveReport(null)}
            >
                <DialogContent>
                    <ReportArchiveDialog
                        illegalDumpingReportId={archiveReport?.id}
                        illegalDumpId={illegalDump.id}
                        handleClose={() => setArchiveReport(null)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default SingleDumpDetail
