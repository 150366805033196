import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useAtom } from "jotai"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

import { useArchiveIllegalDumpingReport } from '@/hooks/api/use-illegal-dump'
import { selectedMunicipalityIdAtom } from "@/state"

function ReportArchiveDialog({
    illegalDumpingReportId,
    illegalDumpId,
    handleClose,
}: {
    illegalDumpingReportId: number,
    illegalDumpId: number,
    handleClose: () => void,
}) {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const mutation = useArchiveIllegalDumpingReport(
        illegalDumpingReportId,
        illegalDumpId,
        municipalityId,
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickArchive = () => {
        setRequestInProgress(true)
        mutation.mutate()
    }

    return (
        <Box sx={{
            minWidth: 200,
            minHeight: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {success ?
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    px: 3,
                }}>
                    <DeleteRoundedIcon sx={{
                        color: 'terciary.main',
                        fontSize: '4rem',
                    }}/>
                    <Typography variant="body1" component="div">
                        Hlášení úspěšně smazáno.
                    </Typography>
                    <Button
                        variant="contained"
                        color="terciary"
                        size="small"
                        onClick={() => {
                            setSuccess(false)
                            handleClose()
                        }}
                    >
                        Zavřít
                    </Button>
                </Box>
                :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography variant="h5" component="div" mb={2}>
                        Potvrďte prosím smazání zvolené zprávy (hlášení) ID: {illegalDumpingReportId}
                    </Typography>
                    <Typography variant="body2" component="div" mb={2}>
                        Tato akce hlášení skryje ze všech seznamů a detailů.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}>
                        <Button
                            variant="text"
                            color="terciary"
                            onClick={() => handleClose()}
                        >
                            Zrušit
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <DeleteRoundedIcon />}
                            onClick={() => handleClickArchive()}
                            disabled={requestInProgress}
                        >
                            Smazat
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default ReportArchiveDialog
