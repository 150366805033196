import { Fragment, useState, type ChangeEvent } from 'react'
import dayjs from "dayjs"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/cs'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LineChart,
    Line,
} from 'recharts'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import type { MonthlyMunicipalityStats } from '@/types'
import { WASTE_COLORS } from '@/constants/general'

export default function MonthlyTrendChart({
    data,
    comparisonData,
    comparisonMunicipality,
}: {
    data: Array<MonthlyMunicipalityStats>,
    comparisonData?: Array<MonthlyMunicipalityStats> | null,
    comparisonMunicipality?: string,
}) {

    const [show, setShow] = useState({
        municipal: true,
        bio: true,
        plastic: true,
        paper: true,
        glass: true,
        other: true,
        comparison: true,
    })

    const [graphType, setGraphType] = useState<'bar' | 'line'>('bar')
    const [dataPerspective, setDataPerspective] = useState<'perMunicipality' | 'perCitizen'>('perCitizen')

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShow({
          ...show,
          [event.target.name]: event.target.checked,
        })
    }

    const handleGraphChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGraphType((event.target as HTMLInputElement).value as 'bar' | 'line')
    }

    const handleDataPerspectiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDataPerspective((event.target as HTMLInputElement).value as 'perMunicipality' | 'perCitizen')
    }

    const { municipal, bio, plastic, paper, glass, other, comparison } = show

    const dataWithDate = data.map(entry => {
        const comparisonEntry = comparisonData.find(
            comparisonEntry =>
                comparisonEntry.month === entry.month &&
                comparisonEntry.year === (comparisonMunicipality ? entry.year : entry.year - 1)
        )
        return {
            date: dayjs(new Date(entry.year, entry.month - 1, 1))
                .locale('cs')
                .format('MMM YYYY'),
            ...entry,
            mixed_weight:
                dataPerspective === 'perCitizen'
                    ? entry.mixed_weight / entry.citizens_count
                    : entry.mixed_weight,
            bio_weight:
                dataPerspective === 'perCitizen'
                    ? entry.bio_weight / entry.citizens_count
                    : entry.bio_weight,
            plastic_weight:
                dataPerspective === 'perCitizen'
                    ? entry.plastic_weight / entry.citizens_count
                    : entry.plastic_weight,
            paper_weight:
                dataPerspective === 'perCitizen'
                    ? entry.paper_weight / entry.citizens_count
                    : entry.paper_weight,
            glass_weight:
                dataPerspective === 'perCitizen'
                    ? entry.glass_weight / entry.citizens_count
                    : entry.glass_weight,
            other_weight:
                dataPerspective === 'perCitizen'
                    ? entry.other_recycling_weight / entry.citizens_count
                    : entry.other_recycling_weight,
            comparison_mixed_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.mixed_weight / comparisonEntry.citizens_count
                    : comparisonEntry.mixed_weight
                : null,
            comparison_bio_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.bio_weight / comparisonEntry.citizens_count
                    : comparisonEntry.bio_weight
                : null,
            comparison_plastic_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.plastic_weight / comparisonEntry.citizens_count
                    : comparisonEntry.plastic_weight
                : null,
            comparison_paper_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.paper_weight / comparisonEntry.citizens_count
                    : comparisonEntry.paper_weight
                : null,
            comparison_glass_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.glass_weight / comparisonEntry.citizens_count
                    : comparisonEntry.glass_weight
                : null,
            comparison_other_weight: comparisonEntry
                ? dataPerspective === 'perCitizen'
                    ? comparisonEntry.other_recycling_weight / comparisonEntry.citizens_count
                    : comparisonEntry.other_recycling_weight
                : null,
        }
    })

    const dataGroups = [
        {
            key: 'municipal',
            show: municipal,
            name: 'Směsný (SKO)',
            nameShort: 'SKO',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - SKO',
            dataKey: 'mixed_weight',
            comparisonDataKey: 'comparison_mixed_weight',
            color: WASTE_COLORS.municipal.main,
        },
        {
            key: 'bio',
            show: bio,
            name: 'Bio',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - bio',
            dataKey: 'bio_weight',
            comparisonDataKey: 'comparison_bio_weight',
            color: WASTE_COLORS.bio.main,
        },
        {
            key: 'plastic',
            show: plastic,
            name: 'Plast',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - plast',
            dataKey: 'plastic_weight',
            comparisonDataKey: 'comparison_plastic_weight',
            color: WASTE_COLORS.plastic.main,
        },
        {
            key: 'paper',
            show: paper,
            name: 'Papír',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - papír',
            dataKey: 'paper_weight',
            comparisonDataKey: 'comparison_paper_weight',
            color: WASTE_COLORS.paper.main,
        },
        {
            key: 'glass',
            show: glass,
            name: 'Sklo',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - sklo',
            dataKey: 'glass_weight',
            comparisonDataKey: 'comparison_glass_weight',
            color: WASTE_COLORS.glass.main,
        },
        {
            key: 'other',
            show: other,
            name: 'Ostatní',
            comparisonName: (comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok')+' - ostatní',
            dataKey: 'other_weight',
            comparisonDataKey: 'comparison_other_weight',
            color: WASTE_COLORS.other_recycling.main,
        },
    ]

    return (
        <>
            <Box pt={10} pb={3}>
                <Typography variant="h3" component="h3">
                    Trend - množství odpadu v obci (kg)
                </Typography>
            </Box>
            <Box display="flex" rowGap={1.5} columnGap={3} flexWrap="wrap" mb={2} pl={2}>
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Zobrazit</FormLabel>
                    <FormGroup row>
                        {dataGroups.map(dataGroup => 
                            <FormControlLabel
                                key={dataGroup.key}
                                control={
                                    <Checkbox checked={dataGroup.show} onChange={handleShowChange} name={dataGroup.key} />
                                }
                                label={dataGroup.nameShort ? dataGroup.nameShort : dataGroup.name}
                            />
                        )}
                        <Box sx={(theme) => ({
                            borderLeft: `1px solid ${theme.palette.divider}`,
                            pr: 2,
                        })}/>
                        <FormControlLabel
                            control={
                                <Checkbox checked={comparison} onChange={handleShowChange} name="comparison" />
                            }
                            label="Srovnání"
                        />
                    </FormGroup>
                </FormControl>
                <FormControl>
                    <FormLabel id="graph-type-selector">Typ grafu</FormLabel>
                    <RadioGroup
                        aria-labelledby="graph-type-selector"
                        name="graph-type-selector"
                        value={graphType}
                        onChange={handleGraphChange}
                        row
                    >
                        <FormControlLabel value="bar" control={<Radio />} label="Sloupcový" />
                        <FormControlLabel value="line" control={<Radio />} label="Spojnicový" />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel id="data-calculation-selector">Typ hodnot</FormLabel>
                    <RadioGroup
                        aria-labelledby="data-perspective-selector"
                        name="data-perspective-selector"
                        value={dataPerspective}
                        onChange={handleDataPerspectiveChange}
                        row
                    >
                        <FormControlLabel value="perCitizen" control={<Radio />} label="kg/občan" />
                        <FormControlLabel value="perMunicipality" control={<Radio />} label="kg/obec" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box height={530} width="100%">
                {graphType === 'bar' &&
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={dataWithDate}
                            margin={{
                                top: 20,
                                right: 10,
                                left: 30,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" dy={5}/>
                            <YAxis tickFormatter={(tick) => tick+'\xa0kg'}/>
                            <Tooltip formatter={value => Math.round(value as number)+' kg'}/>
                            {dataGroups.map(dataGroup => 
                                dataGroup.show && (
                                    <Fragment key={dataGroup.key}>
                                        {comparison && (
                                            <Bar
                                                dataKey={dataGroup.comparisonDataKey}
                                                stackId="a"
                                                fill={dataGroup.color}
                                                name={dataGroup.comparisonName}
                                                opacity={0.6}
                                            />
                                        )}
                                        <Bar
                                            dataKey={dataGroup.dataKey}
                                            stackId="b"
                                            fill={dataGroup.color}
                                            name={dataGroup.name}
                                        />
                                    </Fragment>
                                )
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                }
                {graphType === 'line' &&
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={dataWithDate}
                            margin={{
                                top: 20,
                                right: 10,
                                left: 30,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" dy={5}/>
                            <YAxis tickFormatter={(tick) => tick+'\xa0kg'}/>
                            <Tooltip formatter={value => Math.round(value as number)+' kg'}/>
                            {dataGroups.map(dataGroup => dataGroup.show &&
                                <Line
                                    key={dataGroup.dataKey}
                                    type="monotone"
                                    dataKey={dataGroup.dataKey}
                                    fill={dataGroup.color}
                                    stroke={dataGroup.color}
                                    strokeWidth={2}
                                    name={dataGroup.name}
                                />
                            )}
                            {dataGroups.map(dataGroup => dataGroup.show && comparison &&
                                <Line
                                    key={dataGroup.comparisonDataKey}
                                    type="monotone"
                                    dataKey={dataGroup.comparisonDataKey}
                                    fill={dataGroup.color}
                                    stroke={dataGroup.color}
                                    strokeWidth={2}
                                    name={dataGroup.name}
                                    opacity={0.4}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                }
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2} px={2} justifyContent="center">
                {dataGroups.map(dataGroup => dataGroup.show &&
                    <Box display="flex" gap={1} alignItems="center" key={dataGroup.key}>
                        <Box sx={{
                            width: 14,
                            height: 14,
                            backgroundColor: dataGroup.color,
                            borderRadius: 7,
                        }}/>
                        <Typography variant="body2" component="span">
                            {dataGroup.name}
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    )
}
