import Box from '@mui/material/Box'

import {
    CustomTabsWithPadding,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import { EkokomBonusType } from '@/types'
import EkokomBonusList from './list'
import MunicipalitySizesLoader from '@/wrappers/municipality-sizes-loader'
import { YEARS_FROM_START_TO_NEXT } from '@/constants/general'

function EkokomBonusTabs() {

    const { tab, handleTabChange, a11yProps } = useTabs()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabsWithPadding
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="waste fee tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {YEARS_FROM_START_TO_NEXT.map((year, index) => (
                        <CustomTab key={year} label={year} {...a11yProps(index)} />
                    ))}
                </CustomTabsWithPadding>
            </Box>
            {YEARS_FROM_START_TO_NEXT.map((year, index) => (
                <CustomTabPanelSmall key={year} value={tab} index={index}>
                    <MunicipalitySizesLoader>
                        {municipalitySizes => {
                            const filteredMunicipalitySizes = municipalitySizes.filter(size => size.municipality_size_type === 'bonus')
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}>
                                    <EkokomBonusList year={year} bonusType={EkokomBonusType.basic} municipalitySizes={filteredMunicipalitySizes} />
                                    <EkokomBonusList year={year} bonusType={EkokomBonusType.collection_yard} municipalitySizes={filteredMunicipalitySizes} />
                                    <EkokomBonusList year={year} bonusType={EkokomBonusType.other} municipalitySizes={filteredMunicipalitySizes} />
                                </Box>
                            )
                        }}
                    </MunicipalitySizesLoader>
                </CustomTabPanelSmall>
            ))}
        </>
    )
}

export default EkokomBonusTabs
