import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import type {
    MutationOptions,
    FormMutationOptions,
    CollectionNotification,
    CollectionNotificationFormInput,
    CollectionNotificationsResponse,
    Pagination,
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"
import { isErrorResponse } from '@/helpers'

export function useCollectionNotifications(
    municipalityId: number,
    pagination: Pagination,
) {
    return useQuery({
        queryKey: ['collectionNotifications', municipalityId, `limit-${pagination.limit}`, `offset-${pagination.offset}`],
        queryFn: async () => {
            const { data } : { data: CollectionNotificationsResponse} = await axios.get(
                `/api/collection_notification/list?municipality_id=${municipalityId
                    }&limit=${pagination.limit
                    }&offset=${pagination.offset}`
            )
            return data
        },
    })
}

export function usePublicCollectionNotifications(
    municipalityId: number,
    pagination: Pagination,
) {
    return useQuery({
        queryKey: ['publicCollectionNotifications', municipalityId, `limit-${pagination.limit}`, `offset-${pagination.offset}`],
        queryFn: async () => {
            const { data } : { data: CollectionNotificationsResponse} = await axios.get(
                `/api/collection_notification/public_list?municipality_id=${municipalityId
                    }&limit=${pagination.limit
                    }&offset=${pagination.offset}`
            )
            return data
        },
    })
}

export function useCollectionNotification(collectionNotificationId) {
    return useQuery({
        queryKey: ['collectionNotification-' + collectionNotificationId],
        queryFn: async () => {
            const { data } : { data: CollectionNotification } = await axios.get(
                '/api/collection_notification/single?id=' + collectionNotificationId,
            )
            return data
        },
    })
}

export const useCreateCollectionNotification = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectionNotificationFormInput) => {            
            return axios.post(`/api/collection_notification/create?municipality_id=${municipalityId 
                }&title=${data.title   
                }&priority=${data.priority}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['publicCollectionNotifications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateCollectionNotification = (collectionNotificationId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectionNotificationFormInput) => {
            return axios.put(`/api/collection_notification/update?id=${collectionNotificationId
            }&title=${data.title   
            }&priority=${data.priority}`,
            {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['publicCollectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['collectionNotification-' + collectionNotificationId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useArchiveCollectionNotification = collectionNotificationId => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/collection_notification/archive?id=${collectionNotificationId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['publicCollectionNotifications'] })
        },
    })
}

export const usePublishCollectionNotification = (collectionNotificationId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        setRequestInProgress,
        onErrorCallback,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/collection_notification/publish?id=${collectionNotificationId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['publicCollectionNotifications'] })
            queryClient.invalidateQueries({ queryKey: ['collectionNotification-' + collectionNotificationId] })
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}
