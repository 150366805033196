import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CollectionNotificationListPublic from '@/components/collection-notification/list-public'

function CollectionNotificationView() {

    const title = 'Upozornění ke svozu'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Typography variant="h2" component="h1" gutterBottom>
                    {title}
                </Typography>
                <CollectionNotificationListPublic />
            </CustomPaper>
        </>
    )
}

export default CollectionNotificationView
