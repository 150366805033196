import { useEffect, type SyntheticEvent } from "react"
import { useAtom, useSetAtom } from "jotai"
import InputAdornment from '@mui/material/InputAdornment'
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Autocomplete from "@mui/material/Autocomplete"
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import TextField from "@mui/material/TextField"
import { useLocation } from 'react-router-dom'

import {
    selectedMunicipalityIdAtom,
    selectedCollectionPointIdAtom,
} from "@/state"
import { useMunicipalities } from "@/hooks/api/use-municipality"
import { ROUTE_SEGMENTS } from "@/constants/general"

export default function MunicipalitySelector({
    publicList,
}: {
    publicList?: boolean
}) {

    const [municipalityId, setMunicipalityId] = useAtom(selectedMunicipalityIdAtom)
    const setCollectionPointId = useSetAtom(selectedCollectionPointIdAtom)

    const { data, error } = useMunicipalities(publicList)

    /*  biome-ignore lint: useEffect deps
        We want to run this in reaction to municipalityId change
    */
    useEffect(() => {
        // If there is no municipalityId selected in the /app section, pick first one available in the array
        if (!publicList && !municipalityId && data && data.length > 0) {
            setMunicipalityId(data[0].id)
        }
        // If municipalityId is out of the given array, pick the first one available in the array
        if (municipalityId && data && data.length > 0 && !data.find(municipality => municipality.id === municipalityId)) {
            setMunicipalityId(data[0].id)
        }
    }, [data])

    const options = data ? data.sort((a, b) => a.name.localeCompare(b.name, 'cs', { numeric: true })) : []

    const location = useLocation()
    const locationSlugs = location.pathname.slice(1).split('/')
    const disabled = locationSlugs[2] === ROUTE_SEGMENTS.motivationProgram && locationSlugs.length > 3

    return (
        <Autocomplete
            value={
                municipalityId
                    ? options.find((option) => {
                        return municipalityId === option.id
                    }) ?? null
                    : null
            }
            getOptionLabel={(option) => {
                return option.name
            }}
            getOptionKey={(option) => {
                return option.id
            }}
            title={disabled ? 'Při vytváření a editaci motivačního modelu nelze měnit obec.' : null}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue)
                const parts = parse(option.name, matches)

                const text = (parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400,
                        }}
                    >
                        {part.text}
                    </span>
                )))

                return (
                    <li {...props} key={props.key}>
                        <div>
                            {text}
                        </div>
                    </li>
                );
            }}
            onChange={(_event: SyntheticEvent, newValue) => {
                setMunicipalityId(newValue ? newValue.id : null)
                if (newValue) {
                    setCollectionPointId(null)
                }
            }}
            disableClearable
            id="municipality-selector"
            options={options}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    inputProps={{
                        ...params.inputProps,
                        // disable autocomplete and autofill
                        autoComplete: "off",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ paddingLeft: 1}}>
                                {error ?
                                    <ErrorOutlineIcon sx={{ color: 'error.light' }} />
                                    :
                                    <MapsHomeWorkRoundedIcon sx={{ color: 'customGrey.selectorIcon' }} />
                                }
                            </InputAdornment>
                        ),
                        sx: {
                            borderRadius: 5,
                            backgroundColor: 'white',
                        }
                    }}
                    autoComplete="off"
                    sx={{
                        minWidth: 220,
                        borderRadius: 50,
                    }}
                />
            )}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: "white" 
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.light'
                    },
                }
              }}
        />
    )
}
