import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Link, useLocation } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { ROUTE_SEGMENTS, YEARS_FROM_START_TO_NEXT } from '@/constants/general'
import MotivationModelNameForm from '@/components/forms/motivation-model-name'
import { getYearTab } from '@/helpers'

function MotivationModelNewView() {

    const title = 'Vytvořit nový motivační model'

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const yearFromURL = params.get('year')

    const returnTab = yearFromURL ? getYearTab(Number(yearFromURL), YEARS_FROM_START_TO_NEXT) : ''

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box pb={3} display="flex" alignItems="center" gap={1}>
                        <IconButton
                            aria-label="close"
                            size="small"
                            color="terciary"
                            component={Link}
                            to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationProgram}?tab=${returnTab}`}
                        >
                            <Icon>arrow_back</Icon>
                        </IconButton>
                        <Typography variant="h3" component="h1">
                            {title}
                        </Typography>
                    </Box>
                    <Container maxWidth="sm">
                        <MotivationModelNameForm year={yearFromURL} />
                    </Container>
                </Box>
            </CustomPaper>
        </>
    )
}

export default MotivationModelNewView
