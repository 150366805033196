import type { ChangeEvent } from 'react'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded'

const ImageUpload = ({
    files,
    handleAddFiles,
    handleReset,
    handleFileRemove,
}: {
    files: Array<File>
    handleAddFiles: (event: ChangeEvent<HTMLInputElement>) => void
    handleReset: () => void
    handleFileRemove: (index: number) => void
}) => {

    return (
        <>
            <Box mt={1} display="flex" justifyContent="center">
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DescriptionRoundedIcon />}
                    component="label"
                >
                    Vybrat fotky
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        multiple
                        onChange={handleAddFiles}
                    />
                </Button>
            </Box>
            {(files && files.length !== 0) &&
                <Box mt={1} display="flex" justifyContent="center" flexDirection="column" textAlign="center">
                    <Box display="flex">
                        <Typography variant="h6" component="div" flexGrow={1} textAlign="left">
                            {'Vybrané fotky:'}
                        </Typography>
                        <Tooltip title="Odebrat vše">
                            <IconButton
                                size="small"
                                onClick={() => handleReset()}
                                sx={{
                                    backgroundColor: 'customLightYellow.main',
                                }}
                            >
                                <DeleteSweepRoundedIcon sx={{
                                    fontSize: '1.25rem',
                                }}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={2} pt={2}>
                        {Array.from(files).map((file, index) => (
                            <Box key={file.name+file.size} maxWidth={122} position="relative">
                                <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '100%' }}/>
                                <Typography key={file.name} variant="body2" component="div" sx={{
                                    fontSize: '0.75rem',
                                    overflowWrap: 'anywhere',
                                }}>
                                    {file.name} ({(file.size/1024).toFixed(0)} KB)
                                </Typography>
                                <Box sx={{
                                    position: 'absolute',
                                    top: -8,
                                    right: -8,
                                    backgroundColor: 'customLightYellow.main',
                                    borderRadius: '50%',
                                }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleFileRemove(index)}
                                    >
                                        <CloseRoundedIcon sx={{
                                            fontSize: '1.1rem',
                                        }}/>
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            }
        </>
    )
}

export default ImageUpload
