import {
    type Dispatch,
    type SetStateAction,
    useState,
    type MouseEvent,
} from 'react'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import type { CollectionPointCategoryWithSingleExtension } from '@/types'
import { selectedCollectionPointIdAtom } from '@/state'
import { HISTORY_RETURN_PARAM_KEY, ROUTE_SEGMENTS } from '@/constants/general'

function CollectionPointCategoriesActions({
    collectionPoint,
    setEdit,
}: {
    collectionPoint: CollectionPointCategoryWithSingleExtension,
    setEdit: Dispatch<SetStateAction<CollectionPointCategoryWithSingleExtension>>,
}) {

    const navigate = useNavigate()

    const setCollectionPointId = useSetAtom(selectedCollectionPointIdAtom)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setEdit(null)
    }

    const handleClickDetail = (id: number) => {
        setCollectionPointId(id)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.detail}?${HISTORY_RETURN_PARAM_KEY}=true`)
    }

    const handleClickTrend = (id: number) => {
        setCollectionPointId(id)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.disposalTrend}?${HISTORY_RETURN_PARAM_KEY}=true`)
    }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id="actions-button"
                    aria-label="actions"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    color="terciary"
                    onClick={handleClick}
                >
                    <MoreVertRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id={`CP-${collectionPoint.id}-actions-menu`}
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e: MouseEvent) => {
                    e?.stopPropagation()
                    handleClose()
                }}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        handleClickDetail(collectionPoint.id)
                    }}
                >
                    <ListItemIcon>
                        <VisibilityRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Detail
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        handleClickTrend(collectionPoint.id)
                    }}
                >
                    <ListItemIcon>
                        <TrendingUpRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Trend výsypu
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        setEdit(collectionPoint)
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Upravit účast/slevu
                </MenuItem>
            </Menu>
            
        </>
    )
}

export default CollectionPointCategoriesActions
