import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { NumericFormat } from 'react-number-format'

import type { AggregatedWasteData } from '@/types'

export default function WasteCard({
    data,
}: {
    data: AggregatedWasteData,
}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 1.5,
            backgroundColor: data.color.light,
            borderRadius: 2.5,
            flexGrow: 1,
            opacity: data.tons === 0 ? 0.25 : null,
        }}>
            <Box sx={{
                width: 14,
                height: 14,
                borderRadius: 2,
                backgroundColor: data.color.dark,
            }}/>
            <Box display="flex" gap={2} justifyContent="space-between">
                <Typography variant='h4' component="span">
                    {data.name}
                </Typography>
                <Typography variant='h4' component="span">
                    {data.percentage.toFixed(2)}%
                </Typography>
            </Box>
            <Box>
                <Box display="flex" gap={2} justifyContent="space-between">
                    <Typography variant='body1' component="span">
                        Množství odpadu
                    </Typography>
                    <Typography variant='body1' component="span">
                        {data.tons.toFixed(2)} t
                    </Typography>
                </Box>
                <Box display="flex" gap={2} justifyContent="space-between">
                    <Typography variant='body1' component="span">
                        Na občana
                    </Typography>
                    <Typography variant='body1' component="span">
                        {data.perPerson.toFixed(0)} kg
                    </Typography>
                </Box>
                {data.dumpsCount != null &&
                    <Box display="flex" gap={2} justifyContent="space-between">
                        <Typography variant='body1' component="span">
                            Počet výklopů
                        </Typography>
                        <Typography variant='body1' component="span">
                            <NumericFormat
                                displayType="text"
                                value={data.dumpsCount}
                                thousandSeparator={'\xa0'}
                            />
                        </Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}
