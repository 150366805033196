import { useForm, type SubmitHandler } from "react-hook-form"
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import {
    CollectedWasteRecordType,
    type CollectedWasteStat,
    type CollectedWasteStatFormInput,
    type MonthYear,
} from "@/types"
import {
    useCreateCollectedWasteStat,
    useUpdateCollectedWasteStat,
} from "@/hooks/api/use-collected-waste-stat"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import FormRow from "./reusables/form-row"

const CollectedWasteStatForm = ({
    collectedWasteStat,
    closeCallback,
    yearMonth,
    recordType,
} : {
    collectedWasteStat?: CollectedWasteStat,
    closeCallback?: () => void,
    yearMonth?: MonthYear,
    recordType?: CollectedWasteRecordType,
}) => {

    const formDataId = `collectedWasteStatForm-${collectedWasteStat?.id}-v${APP_VERSION}`

    const initialValues = {
        year_month: collectedWasteStat?.year_month ? collectedWasteStat.year_month : yearMonth ? yearMonth : '',
        record_type: collectedWasteStat?.record_type ? collectedWasteStat.record_type : recordType ? recordType : CollectedWasteRecordType.collectionYard,
        mixed_weight: collectedWasteStat?.mixed_weight ?? '',
        bio_weight: collectedWasteStat?.bio_weight ?? '',
        paper_weight: collectedWasteStat?.paper_weight ?? '',
        plastic_weight: collectedWasteStat?.plastic_weight ?? '',
        glass_mixed_weight: collectedWasteStat?.glass_mixed_weight ?? '',
        glass_clear_weight: collectedWasteStat?.glass_clear_weight ?? '',
        liquid_paperboard_single_weight: collectedWasteStat?.liquid_paperboard_single_weight ?? '',
        liquid_paperboard_combined_weight: collectedWasteStat?.liquid_paperboard_combined_weight ?? '',
        metal_single_weight: collectedWasteStat?.metal_single_weight ?? '',
        metal_combined_weight: collectedWasteStat?.metal_combined_weight ?? '',
        wood_weight: collectedWasteStat?.wood_weight ?? '',
        construction_waste_weight: collectedWasteStat?.construction_waste_weight ?? '',
        hazardous_waste_weight: collectedWasteStat?.hazardous_waste_weight ?? '',
        large_volume_waste_weight: collectedWasteStat?.large_volume_waste_weight ?? '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
        setValue,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const setEmptyFieldsToZero = () => {
        const watchedValues = watch()
        for (const [key, value] of Object.entries(watchedValues)) {
            if (value === '') {
                setValue(key as keyof CollectedWasteStatFormInput, '0')
            }
        }
    }

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateCollectedWasteStat({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
    })

    const mutationUpdate = useUpdateCollectedWasteStat(
        collectedWasteStat?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<CollectedWasteStatFormInput> = data => {
        setRequestInProgress(true)
        if (collectedWasteStat?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    const TYPE_MAP = {
        [CollectedWasteRecordType.collectionYard]: 'sběrné dvory',
        [CollectedWasteRecordType.other]: 'ostatní sběr',
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={collectedWasteStat ?
                'Upravit hodnoty pro '+TYPE_MAP[collectedWasteStat?.record_type] :
                    recordType ? 'Vyplnit hodnoty pro '+TYPE_MAP[recordType] : 'Vyplnit hodnoty'
            }
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={collectedWasteStat ? 'Upravit' : 'Uložit'}
        >
            <RHFNumberTextfield
                name="mixed_weight"
                control={control}
                label="SKO"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="bio_weight"
                control={control}
                label="Bioodpad"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="plastic_weight"
                control={control}
                label="Plast"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="paper_weight"
                control={control}
                label="Papír"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <FormRow>
                <RHFNumberTextfield
                    name="glass_mixed_weight"
                    control={control}
                    label="Směsné sklo"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
                <RHFNumberTextfield
                    name="glass_clear_weight"
                    control={control}
                    label="Čiré sklo"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
            </FormRow>
            <FormRow>
                <RHFNumberTextfield
                    name="liquid_paperboard_single_weight"
                    control={control}
                    label="Nápojové kartony (samostatné)"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
                <RHFNumberTextfield
                    name="liquid_paperboard_combined_weight"
                    control={control}
                    label="Nápojové kartony (směs)"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
            </FormRow>
            <FormRow>
                <RHFNumberTextfield
                    name="metal_single_weight"
                    control={control}
                    label="Kov (samostatné)"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
                <RHFNumberTextfield
                    name="metal_combined_weight"
                    control={control}
                    label="Kov (směs)"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="kg"
                />
            </FormRow>
            <RHFNumberTextfield
                name="wood_weight"
                control={control}
                label="Dřevo"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="construction_waste_weight"
                control={control}
                label="Stavební suť"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="hazardous_waste_weight"
                control={control}
                label="Nebezpečný odpad"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <RHFNumberTextfield
                name="large_volume_waste_weight"
                control={control}
                label="Objemný odpad"
                thousandSeparator
                disallowNegative
                size="small"
                endAdornment="kg"
            />
            <Box display="flex" justifyContent="center">
                <Button
                    variant="outlined"
                    color="terciary"
                    startIcon={<EditNoteRoundedIcon />}
                    onClick={() => setEmptyFieldsToZero()}
                >
                    Vyplnit vše nevyplněné nulou
                </Button>
            </Box>
        </Form>
    )
}

export default CollectedWasteStatForm
