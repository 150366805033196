import Box from '@mui/material/Box'

import {
    CustomTabsWithPadding,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import CollectionPointCategoriesList from './list'
import { YEARS_FROM_START_TO_NEXT } from '@/constants/general'
import MotivationModelsLoader from '@/wrappers/motivation-models-loader'

function CollectionPointCategoriesTabs() {
    
    const { tab, handleTabChange, a11yProps } = useTabs()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabsWithPadding
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="waste fee tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {YEARS_FROM_START_TO_NEXT.map((year, index) => (
                        <CustomTab key={year} label={year} {...a11yProps(index)} />
                    ))}
                </CustomTabsWithPadding>
            </Box>
            {YEARS_FROM_START_TO_NEXT.map((year, index) => (
                <CustomTabPanelSmall key={year} value={tab} index={index}>
                    <MotivationModelsLoader year={year}>
                        {motivationModels => (
                            <CollectionPointCategoriesList year={year} motivationModels={motivationModels}/>
                        )}
                    </MotivationModelsLoader>
                </CustomTabPanelSmall>
            ))}
        </>
    )
}

export default CollectionPointCategoriesTabs
