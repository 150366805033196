
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import dayjs from "dayjs"

import {
    WasteTypeCodes,
    type CollectionPointDetail,
    type CollectionPointMixedVolumeCategory,
} from '@/types'
import {
    useCollectionPointCollectionCalendar,
    useCollectionPointMixedVolumeCategory,
} from '@/hooks/api/use-collection-point'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import type { AxiosError } from 'axios'

const getCategoryColor = (category: number): 'first' | 'second' | 'third' | 'fourth' | 'fifth' => {
    if (category <= 1) return 'first'
    if (category <= 2) return 'second'
    if (category <= 3) return 'third'
    if (category <= 4) return 'fourth'
    return 'fifth'
}

const compareCountWithCategory = (
    data: CollectionPointMixedVolumeCategory,
    number_of_members: number,
    count: number,
    categoryLevel: number,
): boolean => {
    return (number_of_members*(data.all_categories.find(
        category => category.level === categoryLevel)?.value_lt
    )) >= count*data.largest_sko_bin_volume
}

const getCountColor = (
    data: CollectionPointMixedVolumeCategory,
    number_of_members: number,
    count: number,
): 'first' | 'second' | 'third' | 'fourth' | 'fifth' => {
    if (compareCountWithCategory(data, number_of_members, count, 1)) return 'first'
    if (compareCountWithCategory(data, number_of_members, count, 2)) return 'second'
    if (compareCountWithCategory(data, number_of_members, count, 3)) return 'third'
    if (compareCountWithCategory(data, number_of_members, count, 4)) return 'fourth'
    return 'fifth'
}

export default function MixedVolumeDiscountTarget({
    collectionPointDetail,
}: {
    collectionPointDetail: CollectionPointDetail,
}) {

    const { status, data, error } = useCollectionPointMixedVolumeCategory(collectionPointDetail.id)

    const { data: dataPlan } = useCollectionPointCollectionCalendar(
        collectionPointDetail.id,
        dayjs().year(),
    )

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error && ((error as AxiosError).response?.data as { error : string })?.error) {
        return (
            <Alert
                severity="error"
            >
                Slevový cíl týkající se úspory počtu výklopů není k dispozici: {((error as AxiosError).response?.data as { error : string })?.error}
            </Alert>
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst slevu za úsporu v počtu výklopů svozového místa s ID ${collectionPointDetail.id}`}
        />
    }

    const mixedDumpCount = dataPlan ?
        dataPlan.reduce((sum, plan) => plan.material_code === WasteTypeCodes.mixed ? sum+1 : sum, 0) :
        null
    const number_of_members = data.number_of_members ?? 1
    const maxUses = mixedDumpCount ?
        mixedDumpCount :
        data.all_categories.find(category => category.level === 5)?.value_gte*number_of_members/data.largest_sko_bin_volume <= 26 ?
            26 :
            52
    const usesCount = Math.ceil(data.current_dumps*number_of_members)

    return (
        <Box sx={theme => ({
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        })}>
            <Typography variant="h5" component="h5">
                Sleva za úsporu v počtu výklopů SKO
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories[getCategoryColor(data.current_level)],
                })}>
                    Kategorie {data.current_level}
                </Typography>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories[getCategoryColor(data.current_level)],
                })}>
                    {data.current_discount_cents/100} Kč
                </Typography>
            </Box>
            {data.largest_sko_bin_volume != null ?
                <Typography variant="body2" component="p">
                    V tomto roce jste již využili {Math.ceil(data.current_dumps*number_of_members)} výklopů. 
                    
                    {data.current_level === 5 ? 
                        ' Pro postup do vyšší kategorie přistavujte nádobu méně často.'
                    :
                        ` Po využití dalších ${Math.ceil(data.dumps_difference*number_of_members)}
                        (a více) výklopů budete v nižší kategorii (${data.worse_category_level}) se 
                        slevou ${data.worse_category_discount_cents/100} Kč.`
                    }
                </Typography>
                :
                <Typography variant="body2" component="p">
                    Nemáte nádobu na SKO.
                </Typography>
            }
            <Box sx={{
                pt: 1,
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                {data.all_categories.length > 0 && Array.from({ length: maxUses }, (_, index) => index + 1).map(iconCount => (
                    <Box key={iconCount} sx={{
                        position: 'relative',
                    }}>
                        <DeleteRoundedIcon sx={theme => ({
                            color: theme.palette.categories[getCountColor(data, number_of_members, iconCount)],
                        })}/>
                        {usesCount >= iconCount &&
                            <CloseRoundedIcon sx={{
                                position: 'absolute',
                                right: -2,
                                top: -2,
                                color: 'terciary.light',
                                opacity: 0.8,
                                fontSize: '1.75rem',
                            }}/>
                        }
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
