import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import CheckIcon from '@mui/icons-material/Check'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Alert from '@mui/material/Alert'

import type { User } from '@/types'
import { useToggleUserActive } from '@/hooks/api/use-users'

function ToggleUserActive({
    user,
}: {
    user: User,
}) {

    const [confirmation, setConfirmation] = useState(false)
    const [requestInProgress, setRequestInProgress] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)

    const [previousActive, setPreviousActive] = useState(user.active)

    const toggleActiveMutation = useToggleUserActive(user.id, {
        onSuccessCallback(data) {
            if(data) {
                setSuccess(true)
            }
            setConfirmation(false)
        },
        onErrorCallback(errorMessage) {
            setError(errorMessage)
        },
        setRequestInProgress,
    })

    const handleToggleActive = () => {
        setPreviousActive(user.active)
        toggleActiveMutation.mutate(!user.active)
    }

    return (
        <Box mt={1}>
            {success &&
                <Alert
                    severity="success"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(1, 0),
                    })}
                    onClose={() => setSuccess(false)}
                >
                    {previousActive ? 'Uživatel úspěšně deaktivován.' : 'Uživatel úspěšně znovu aktivován.'}
                </Alert>
            }
            {error &&
                <Alert
                    severity="error"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(1, 0),
                    })}
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            }
            {!success && !error &&
                <>
                    {confirmation ?
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                        }}>
                            <Button
                                startIcon={<CloseRoundedIcon />}
                                onClick={() => setConfirmation(false)}
                                color='terciary'
                                variant="text"
                            >
                                Zrušit
                            </Button>
                            <Button
                                startIcon={user.active ? <DoNotDisturbIcon /> : <CheckIcon />}
                                onClick={() => handleToggleActive()}
                                color={user.active ? 'error' : 'success'}
                                variant="outlined"
                                disabled={requestInProgress}
                            >
                                Potvrdit
                            </Button>
                        </Box>
                        :
                        <Button
                            startIcon={user.active ? <DoNotDisturbIcon /> : <CheckIcon />}
                            onClick={() => setConfirmation(true)}
                            color='terciary'
                            variant="outlined"
                        >
                            {user.active ? 'Deaktivovat' : 'Znovu aktivovat'}
                        </Button>
                    }
                </>
            }
        </Box>
    )
}

export default ToggleUserActive
