import { useState, type MouseEvent } from 'react'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MessageRoundedIcon from '@mui/icons-material/MessageRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import type { CollectionNotification } from '@/types'
import RichText from '../rich-text'
import CollectionNotificationForm from '../forms/collection-notification'
import CollectionNotificationPublishAlert from './publish-alert'
import { useArchiveCollectionNotification } from '@/hooks/api/use-collection-notification'

export default function CollectionNotificationCard({
    collectionNotification,
    editable,
}: {
    collectionNotification: CollectionNotification,
    editable: boolean,
}) {

    const [edit, setEdit] = useState<boolean>(false)
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDeleteConfirmation(false)
    }

    const mutation = useArchiveCollectionNotification(collectionNotification.id)

    const handleArchive = () => {
        mutation.mutate()
    }
    
    const priorityIcon = {
        low: <MessageRoundedIcon sx={{color: 'success.light', opacity: 0.7 }} />,
        medium: <MessageRoundedIcon sx={{color: 'warning.light', opacity: 0.7 }} />,
        high: <AnnouncementRoundedIcon sx={{color: 'error.light', opacity: 0.7 }} />,
    }

    return edit ?
        <CollectionNotificationForm
            collectionNotification={collectionNotification}
            closeCallback={() => setEdit(false)}
        />
        :
        <Box sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 2.5,
            border: `1px solid ${theme.palette.divider}`,
            p: 1.5,
            pb: 2.5,
            backgroundColor: 'white',
        })}>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                pr: editable ? 6 : 0,
                position: 'relative',
            }}>
                {!collectionNotification.published_at && editable &&
                    <CollectionNotificationPublishAlert
                        collectionNotificationId={collectionNotification.id}
                    />
                }
                {editable &&
                    <Box sx={{
                        position: 'absolute',
                        top: !collectionNotification.published_at ? 4 : -4,
                        right: -4,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '1rem',
                    }}>
                        <Tooltip title="Více možností">
                            <IconButton
                                id="actions-button"
                                aria-label="actions"
                                aria-controls={open ? 'actions-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                color="terciary"
                                onClick={handleClick}
                                sx={{ backgroundColor: 'editableOrange.main' }}
                            >
                                <MoreVertRoundedIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="actions-menu"
                            MenuListProps={{
                                'aria-labelledby': 'actions-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            slotProps={{
                                paper: {
                                    style: {
                                        minWidth: '15ch',
                                    }
                                }
                            }}
                        >
                            <MenuItem onClick={() => {
                                setEdit(true)
                                handleClose()
                            }}>
                                <ListItemIcon>
                                    <EditRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                Upravit
                            </MenuItem>
                            {deleteConfirmation ?
                                <MenuItem
                                    onClick={() => {
                                        handleArchive()
                                        handleClose()
                                    }}
                                    sx={{
                                        color: 'error.main',
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteRoundedIcon fontSize="small" color="error" />
                                    </ListItemIcon>
                                    Potvrdit
                                </MenuItem>
                                :
                                <MenuItem onClick={() => setDeleteConfirmation(true)}>
                                    <ListItemIcon>
                                        <DeleteRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Archivovat
                                </MenuItem>
                            }
                        </Menu>
                    </Box>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 2,
            }}>
                {priorityIcon[collectionNotification.priority]}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    flexGrow: 1,
                }}>
                    <Box>
                        <Typography variant="h4" component="h2">
                            {collectionNotification.title}
                        </Typography>
                        {collectionNotification.published_at &&
                            <Typography variant="body2" component="span" sx={{ color: 'customGrey.secondaryText'}}>
                                Publikováno: {dayjs(collectionNotification.published_at).format('D. M. YYYY HH:mm')}
                                {collectionNotification.published_by?.email &&
                                    <span>
                                        {` (${collectionNotification.published_by.email})`}
                                    </span>
                                }
                            </Typography>
                        }
                    </Box>
                    <RichText content={collectionNotification.description} />
                </Box>
            </Box>
        </Box>
    
}
