import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'

import { useCalculateMotivationModel } from '@/hooks/api/use-motivation-model'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import type {
    MotivationModel,
    MonthYear,
    ScenarioParameters,
    MunicipalityFinanceYearSum,
} from '@/types'
import IncomeStatement from './income-statement'
import { useMunicipalityFinances } from '@/hooks/api/use-municipality-finances'
import { selectedMunicipalityIdAtom } from '@/state'


export default function IncomeStatementWrapper({
    motivationModel,
    previousYearMotivationModel,
    twoYearsAgoMotivationModel,
    parameters,
}: {
    motivationModel: MotivationModel,
    previousYearMotivationModel?: MotivationModel,
    twoYearsAgoMotivationModel?: MotivationModel,
    parameters: ScenarioParameters,
}) {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const currentYear = dayjs().year()
    const motivationModelYear = motivationModel.year
    const motivationModelPreviousYear = motivationModel.year-1

    let startDate: MonthYear
    let endDate: MonthYear
    let previousStartDate: MonthYear
    let previousEndDate: MonthYear

    if (motivationModelYear >= currentYear) {
        startDate = dayjs().subtract(1, 'year').format('YYYY-MM') as MonthYear
        endDate = dayjs().subtract(1, 'month').format('YYYY-MM') as MonthYear
    } else {
        startDate = dayjs(motivationModelYear+'-01-01').format('YYYY-MM') as MonthYear
        endDate = dayjs(motivationModelYear+'-12-31').format('YYYY-MM') as MonthYear
    }
    if (motivationModelPreviousYear >= currentYear) {
        previousStartDate = dayjs().subtract(1, 'year').format('YYYY-MM') as MonthYear
        previousEndDate = dayjs().subtract(1, 'month').format('YYYY-MM') as MonthYear
    } else {
        previousStartDate = dayjs(motivationModelPreviousYear+'-01-01').format('YYYY-MM') as MonthYear
        previousEndDate = dayjs(motivationModelPreviousYear+'-12-31').format('YYYY-MM') as MonthYear
    }

    const twoYearsAgoStartDate = dayjs(motivationModelYear-2+'-01-01').format('YYYY-MM') as MonthYear
    const twoYearsAgoEndDate = dayjs(motivationModelYear-2+'-12-31').format('YYYY-MM') as MonthYear

    const { status, data, error, isPlaceholderData, isRefetching } =
        useCalculateMotivationModel(
            motivationModel.id,
            motivationModelYear,
            startDate,
            endDate,
            parameters.scenario,
            parameters.annualSurcharge !== null && parameters.lastChange === 'annualSurcharge'
                ? Number(parameters.annualSurcharge)
                : null,
            parameters.citizenFee !== null && parameters.lastChange === 'citizenFee'
                ? Number(parameters.citizenFee)
                : parameters.lastChange === null
                  ? Number(parameters.citizenFee)
                  : null
        )
    
    const { data: dataPreviousYear } =
        useCalculateMotivationModel(
            previousYearMotivationModel?.id,
            motivationModelPreviousYear,
            previousStartDate,
            previousEndDate,
            previousYearMotivationModel?.selected_scenario,
            null,
            Number(previousYearMotivationModel?.citizen_fee_cents)/100,
        )
    
    const { data: dataTwoYearsAgo } =
        useCalculateMotivationModel(
            twoYearsAgoMotivationModel?.id,
            motivationModelYear-2,
            twoYearsAgoStartDate,
            twoYearsAgoEndDate,
            twoYearsAgoMotivationModel?.selected_scenario,
            null,
            Number(twoYearsAgoMotivationModel?.citizen_fee_cents)/100,
        )
    
    const { data: realDataTwoYearsAgo } = useMunicipalityFinances(
            municipalityId,
            (motivationModelYear-2)+'-01' as MonthYear,
            (motivationModelYear-2)+'-12' as MonthYear,
        )
    
    const sumsTwoYearsAgo:MunicipalityFinanceYearSum = realDataTwoYearsAgo?.length > 0 ? 
        realDataTwoYearsAgo?.reduce((acc, entry) => {
            acc.bin_collection_expenses += entry.bin_collection_expenses
            acc.collection_yard_expenses += entry.collection_yard_expenses
            acc.other_expenses += entry.other_expenses
            acc.ekokom_income += entry.ekokom_income
            acc.citizen_fee_income += entry.citizen_fee_income
            acc.collection_yard_income += entry.collection_yard_income
            acc.other_income += entry.other_income
            return acc
        }, {
            bin_collection_expenses: 0,
            collection_yard_expenses: 0,
            other_expenses: 0,
            ekokom_income: 0,
            citizen_fee_income: 0,
            collection_yard_income: 0,
            other_income: 0,
        })
        :
        null

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se vypočítat scénář pro model ID ${motivationModel.id}`}
        />
    }
    
    return (
        <IncomeStatement
            scenario={data}
            previousYearScenario={dataPreviousYear}
            twoYearsAgoScenario={dataTwoYearsAgo}
            sumsTwoYearsAgo={sumsTwoYearsAgo}
            isLoading={isPlaceholderData || isRefetching}
            modelYear={motivationModelYear}
        />
    )
}
