import { useState } from "react"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import dayjs, {type Dayjs} from 'dayjs'

import InfoBar from '@/components/info-bar'
import TimeInfo from '@/components/collection-point/detail/detail-time-info'
import type { CollectionPointDetail } from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP } from '@/constants/general'
import CollectionPointDetailMap from './map'
import { useVerifyCollectionPointByUser } from "@/hooks/api/use-collection-point"

const isOlderThanSixMonths = (date: Dayjs): boolean => {
    const sixMonthsAgo = dayjs().subtract(6, "months")
    return date.isBefore(sixMonthsAgo)
}

export default function DetailHeader({
    collectionPointDetail,
}: {
    collectionPointDetail: CollectionPointDetail,
}) {

    const [error, setError] = useState<string | null>(null)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

    const mutation = useVerifyCollectionPointByUser(
        collectionPointDetail.id,
        {
            setRequestInProgress,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const handlePublish = () => {
        setRequestInProgress(true)
        setError(null)
        mutation.mutate()
    }
    
    const address = collectionPointDetail.address

    const lastVerifiedAtThisYear = collectionPointDetail.exts.find(ext => ext.year === dayjs().year())?.verified_by_user_at
    const lastVerifiedAtLastYear = collectionPointDetail.exts.find(ext => ext.year === dayjs().year()-1)?.verified_by_user_at

    const lastVerifiedAt = lastVerifiedAtThisYear ?? lastVerifiedAtLastYear

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
                columnGap: 6,
                rowGap: 3,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    flex: 1,
                }}>
                    <Box>
                        <Typography variant="h4" component="div" mb={0.5}>
                            {address.street} {address.street_number},
                        </Typography>
                        <Typography variant="h4" component="div" mb={0.5}>
                            {address.zip_code} {address.town}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            color="customGrey.secondaryText"
                        >
                            Typ: {COLLECTION_POINT_TYPE_LABEL_MAP[collectionPointDetail.type]}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: 1.5,
                        rowGap: 0.5,
                    }}>
                        {/* <TimeInfo text="Datum zavedení změn svozovou firmou:" time="TBD" /> */}
                        <TimeInfo text="Moje kontrola dne:" time={lastVerifiedAt ? dayjs(lastVerifiedAt).format('D.M.YYYY HH:mm') : '-'} />
                    </Box>
                </Box>
                {collectionPointDetail.address.latitude && collectionPointDetail.address.longitude &&
                    <Box sx={{
                        flex: 1,
                        borderRadius: 2.5,
                        overflow: 'hidden',
                    }}>
                        <CollectionPointDetailMap
                            latitude={Number.parseFloat(collectionPointDetail.address.latitude)}
                            longitude={Number.parseFloat(collectionPointDetail.address.longitude)}
                        />
                    </Box>
                }
            </Box>
            {(!lastVerifiedAt || (lastVerifiedAt && isOlderThanSixMonths(dayjs(lastVerifiedAt)))) &&
                <InfoBar
                    icon={<InfoIcon sx={{ color: 'primary.light' }} />}
                    mainButton={{
                        onClick: () => handlePublish(),
                        text: 'Vše v pořádku',
                        disabled: requestInProgress,
                    }}
                    secondButton={{
                        onClick: () => alert('ok!'),
                        text: 'Nahlásit změnu',
                    }}
                    text={
                        error ? error : 'Nedošlo ke změnám od data poslední kontroly? Zkontrolujte prosím údaje níže.'
                    }
                />
            }
        </>
    )
}
