import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import { useEffect, useState, useRef, useCallback } from "react"
import { type Editor, type EditorOptions, useEditor } from "@tiptap/react"
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    MenuSelectTextAlign,
    MenuButtonSuperscript,
    MenuButtonSubscript,
    MenuButtonTextColor,
    MenuButtonEditLink,
    MenuButtonOrderedList,
    MenuButtonBulletedList,
    MenuButtonAddTable,
    MenuButtonImageUpload,
    MenuButtonRemoveFormatting,
    LinkBubbleMenu,
    RichTextEditorProvider,
    RichTextField,
    TableBubbleMenu,
    insertImages,
} from "mui-tiptap"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"

import useTipTapExtensions from "@/hooks/use-tip-tap-extensions"

type TEditorProps = {
    value: string,
    onChange(body: string): void,
    multipleRows?: boolean,
}

function fileListToImageFiles(fileList: FileList): File[] {
    // Possibly use a package such as (https://www.npmjs.com/package/attr-accept)
    // to restrict to certain file types.
    return Array.from(fileList).filter(file => {
        const mimeType = (file.type || '').toLowerCase()
        return mimeType.startsWith('image/')
    })
}

const TipTap = ({
    onChange,
    value,
    multipleRows,
}: TEditorProps) => {

    const extensions = useTipTapExtensions()

    const rteRef: React.MutableRefObject<Editor | null> = useRef(null)

    const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
        if (!rteRef.current) return

        // Convert each file to base64 and then use it as the src
        const readFileAsBase64 = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => resolve(reader.result as string)
                reader.onerror = error => reject(error)
                reader.readAsDataURL(file)
            })
        }

        // Read each file as base64 and then insert into the editor
        Promise.all(files.map(readFileAsBase64))
            .then(base64Images => {
                const attributesForImageFiles = base64Images.map((base64, index) => ({
                    src: base64,
                    alt: files[index].name,
                }))

                insertImages({
                    images: attributesForImageFiles,
                    editor: rteRef.current,
                    position: insertPosition,
                })
            })
            .catch(error => console.error('Error reading files as Base64:', error))
    }, [])
    
    // Allow for dropping images into the editor
    const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
        (view, event, _slice, _moved) => {
            console.log(event)
            if (!(event instanceof DragEvent) || !event.dataTransfer) {
                return false
            }

            const imageFiles = fileListToImageFiles(event.dataTransfer.files)
            if (imageFiles.length > 0) {
                const insertPosition = view.posAtCoords({
                    left: event.clientX,
                    top: event.clientY,
                })?.pos

                handleNewImageFiles(imageFiles, insertPosition)

                // Return true to treat the event as handled. We call preventDefault
                // ourselves for good measure.
                event.preventDefault()
                return true
            }

            return false
        },
        [handleNewImageFiles]
    )
    
    // Allow for pasting images
    const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
        (_view, event, _slice) => {
            if (!event.clipboardData) {
                return false
            }

            const pastedImageFiles = fileListToImageFiles(event.clipboardData.files)
            if (pastedImageFiles.length > 0) {
                handleNewImageFiles(pastedImageFiles)
                // Return true to mark the paste event as handled. This can for
                // instance prevent redundant copies of the same image showing up,
                // like if you right-click and copy an image from within the editor
                // (in which case it will be added to the clipboard both as a file and
                // as HTML, which Tiptap would otherwise separately parse.)
                return true
            }

            // We return false here to allow the standard paste-handler to run.
            return false
        },
        [handleNewImageFiles]
    )
    
    const editor = useEditor({
        extensions,
        content: value,
        onUpdate({ editor }) {
            onChange(editor.getHTML())
        },
        editorProps: {
            handleDrop: handleDrop,
            handlePaste: handlePaste,
        },
    })

    // Set rteRef required for image pasting and drag&drop
    if (editor) {
        rteRef.current = editor
    }

    useEffect(() => {
        if (!editor) return
        // Wrapping in a setTimeout to avoid flushSync errors
        // (https://github.com/ueberdosis/tiptap/issues/3764)
        setTimeout(() => {
            const { from, to } = editor.state.selection
            editor.commands.setContent(value,
                false, {
                preserveWhitespace: "full"
            })
            editor.commands.setTextSelection({ from, to })
        })
    }, [value, editor])

    return (
        <RichTextEditorProvider editor={editor}>
            <LinkBubbleMenu />
            <TableBubbleMenu />
            <Box sx={{
                // '& p': {
                //     fontSize: 16,
                // },
                ...(multipleRows ? {
                    '& .ProseMirror': {
                        minHeight: 250,
                    }
                } : {}),
            }}>
                <RichTextField
                    controls={
                        <MenuControlsContainer>
                            <MenuSelectHeading />
                            <MenuDivider />
                            <MenuButtonBold />
                            <MenuButtonItalic />
                            <MenuButtonSubscript />
                            <MenuButtonSuperscript />
                            <MenuDivider />
                            <MenuButtonTextColor
                                defaultTextColor={'#000000de'}
                                swatchColors={[
                                    { value: "#000000", label: "Black" },
                                    { value: "#ffffff", label: "White" },
                                    { value: "#888888", label: "Grey" },
                                    { value: "#ff0000", label: "Red" },
                                    { value: "#ff9900", label: "Orange" },
                                    { value: "#ffff00", label: "Yellow" },
                                    { value: "#00d000", label: "Green" },
                                    { value: "#0000ff", label: "Blue" },
                                ]}
                            />
                            <MenuDivider />
                            <MenuSelectTextAlign />
                            <MenuDivider />
                            <MenuButtonEditLink />
                            <MenuDivider />
                            <MenuButtonOrderedList />
                            <MenuButtonBulletedList />
                            <MenuButtonAddTable />
                            <MenuDivider />
                            <MenuButtonImageUpload
                                onUploadFiles={(files: File[]) => {
                                    const convertToBase64 = (file: File): Promise<{ src: string; alt: string }> =>
                                        new Promise((resolve, reject) => {
                                            const reader = new FileReader()
                                            reader.onloadend = () => {
                                                if (reader.result) {
                                                    resolve({ src: reader.result as string, alt: file.name })
                                                } else {
                                                    reject(new Error("Failed to convert file to Base64"))
                                                }
                                            };
                                            reader.onerror = reject
                                            reader.readAsDataURL(file)
                                        });

                                    // Convert all files to Base64
                                    return Promise.all(files.map((file) => convertToBase64(file)))
                                }}
                            />
                            {/* <MenuButtonImageUpload
                                onUploadFiles={() => ([{
                                    src: 'https://picsum.photos/200',
                                    alt: 'Test',
                                }])
                                }
                            /> */}
                            <MenuDivider />
                            <MenuButtonRemoveFormatting />
                        </MenuControlsContainer>
                    }
                />
            </Box>
        </RichTextEditorProvider>
    )
}

interface RHFAutocompleteProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    multipleRows?: boolean,
}

const RHFRichText = <
    TField extends FieldValues,
>(
    props: RHFAutocompleteProps<TField>
) => {

    const {
        control,
        name,
        label,
        multipleRows,
    } = props

    const [editHtml, setEditHtml] = useState<boolean>(false)

    return (
        <Box sx={{
            '& .MuiTiptap-MenuBar-root': {
                backgroundColor: 'unset',
            },
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box display="flex" alignItems="center" mb={0.5}>
                <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
                    {label}
                </Typography>
                <IconButton
                    size="small"
                    onClick={() => setEditHtml(!editHtml)}
                    color={editHtml ? 'success' : 'secondary'}
                >
                    <Icon sx={{ fontSize: '18px' }}>code</Icon>
                </IconButton>
            </Box>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (editHtml ?
                        <TextField
                            id="rhf-textarea"
                            size="small"
                            autoComplete="off"
                            multiline
                            minRows={multipleRows ? 4 : undefined}
                            {...field}
                        />
                        :
                        <TipTap value={field.value} onChange={field.onChange} multipleRows={multipleRows} />
                    )
                }}
            />
        </Box>
    )
}

export default RHFRichText
