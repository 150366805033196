import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { Link } from 'react-router-dom'

import { convertFromCents, getYearTab, printPrice, roundPrice } from '@/helpers'
import type { MotivationModelScenario, MunicipalityFinanceYearSum } from '@/types'
import { ROUTE_SEGMENTS, START_YEAR, YEARS_FROM_START_TO_NEXT } from '@/constants/general'
import dayjs from 'dayjs'

interface PercentageObject {
    percentage: string | null,
    comparison: 'gt' | 'lt' | 'same',
    prediction: number | null,
}

const getPercentage = (reality: number, estimateCents: number, monthsComplete: number): PercentageObject => {
    const empty: PercentageObject = {
        percentage: null,
        comparison: 'same',
        prediction: null,
    }
    if (!reality || !estimateCents) {
        return empty
    }
    if (Number.isNaN(estimateCents) || estimateCents === 0) {
        return empty
    }
    // *100 added because the value is in cents
    const percentage = (reality/(estimateCents/100))*100
    const comparisonPercentage = 100/12*monthsComplete
    const prediction = Math.ceil(reality/monthsComplete*12)

    return {
        percentage: percentage.toFixed(2),
        comparison: percentage > comparisonPercentage ?
            'gt'
            : percentage < comparisonPercentage ?
                'lt'
                : 'same',
        prediction: Math.ceil(prediction),
    }
}

export default function MunicipalityFinancesIncomeStatement({
    year,
    scenario,
    financeYearSum,
}: {
    year: number,
    scenario: MotivationModelScenario,
    financeYearSum: MunicipalityFinanceYearSum,
}) {

    const monthsComplete = dayjs().year() === year ?
        dayjs().month() > 0 ?
            dayjs().month() : 12
        : 12

    const financeYearExpenses = financeYearSum.bin_collection_expenses
        +financeYearSum.collection_yard_expenses
        +financeYearSum.other_expenses

    const scenarioCollectionExpenses = scenario?.expenses.bin_dump +
        scenario?.expenses.weight +
        scenario?.expenses.penalty

    const expenses = [
        {
            name: 'Náklady',
            realityValue: financeYearExpenses,
            estimateValue: scenario?.expenses.total ?? '-',
            percentage: getPercentage(financeYearExpenses, scenario?.expenses.total, monthsComplete),
            header: true,
        },
        {
            name: 'Nádobový sběr',
            realityValue: financeYearSum.bin_collection_expenses,
            estimateValue: Number.isNaN(scenarioCollectionExpenses)
                ? '-'
                : scenarioCollectionExpenses,
            percentage: getPercentage(financeYearSum.bin_collection_expenses, scenarioCollectionExpenses, monthsComplete),
        },
        {
            name: 'Výklopy',
            realityValue: '-',
            estimateValue: scenario?.expenses.bin_dump ?? '-',
            secondary: true,
        },
        {
            name: 'Odstranění odpadu',
            realityValue: '-',
            estimateValue: scenario?.expenses.weight ?? '-',
            secondary: true,
        },
        {
            name: 'Fond živ. prostředí',
            realityValue: '-',
            estimateValue: scenario?.expenses.penalty ?? '-',
            secondary: true,
        },
        {
            name: 'Provoz sběrných dvorů',
            realityValue: financeYearSum.collection_yard_expenses,
            estimateValue: scenario?.expenses.collection_yard_operation_cents ?? '-',
            percentage: getPercentage(financeYearSum.collection_yard_expenses, scenario?.expenses.collection_yard_operation_cents, monthsComplete),
        },
        {
            name: 'Ostatní náklady',
            realityValue: financeYearSum.other_expenses,
            estimateValue: scenario?.expenses.waste_management_cents ?? '-',
            percentage: getPercentage(financeYearSum.other_expenses, scenario?.expenses.waste_management_cents, monthsComplete),
        },
    ]

    const estimateEkokomIncomes = scenario?.incomes.ekokom

    const financeYearIncomes = financeYearSum.citizen_fee_income+financeYearSum.collection_yard_income
        +financeYearSum.ekokom_income+financeYearSum.other_income

    const incomes = [
        {
            name: 'Příjmy',
            realityValue: financeYearIncomes,
            estimateValue: scenario?.incomes.total ?? '-',
            percentage: getPercentage(financeYearIncomes, scenario?.incomes.total, monthsComplete),
            header: true,
        },
        {
            name: 'EKOKOM',
            realityValue: financeYearSum.ekokom_income,
            estimateValue: estimateEkokomIncomes?.ekokom_total ?? '-',
            percentage: getPercentage(financeYearSum.ekokom_income, estimateEkokomIncomes?.ekokom_total, monthsComplete),
        },
        {
            name: 'Papír',
            realityValue: '-',
            estimateValue: estimateEkokomIncomes?.paper ?? '-',
            secondary: true,
        },
        {
            name: 'Plast',
            realityValue: '-',
            estimateValue: estimateEkokomIncomes?.plastic ?? '-',
            secondary: true,
        },
        {
            name: 'Sklo',
            realityValue: '-',
            estimateValue: Number.isNaN(
                estimateEkokomIncomes?.glass_clear + estimateEkokomIncomes?.glass_mixed
            )
                ? '-'
                : estimateEkokomIncomes?.glass_clear + estimateEkokomIncomes?.glass_mixed,
            secondary: true,
        },
        {
            name: 'Kov',
            realityValue: '-',
            estimateValue: Number.isNaN(
                estimateEkokomIncomes?.metal_combined + estimateEkokomIncomes?.metal_single
            )
                ? '-'
                : estimateEkokomIncomes?.metal_combined + estimateEkokomIncomes?.metal_single,
            secondary: true,
        },
        {
            name: 'Nápojové kartony',
            realityValue: '-',
            estimateValue: Number.isNaN(
                estimateEkokomIncomes?.liquid_paperboard_combined +
                    estimateEkokomIncomes?.liquid_paperboard_single
            )
                ? '-'
                : estimateEkokomIncomes?.liquid_paperboard_combined +
                    estimateEkokomIncomes?.liquid_paperboard_single,
            secondary: true,
        },
        {
            name: 'Dřevo',
            realityValue: '-',
            estimateValue: estimateEkokomIncomes?.wood ?? '-',
            secondary: true,
        },
        {
            name: 'Příjmy ze sběr. dvorů',
            realityValue: financeYearSum.collection_yard_income,
            estimateValue: scenario?.incomes.collection_yard_income ?? '-',
            percentage: getPercentage(financeYearSum.collection_yard_income, scenario?.incomes.collection_yard_income, monthsComplete),
        },
        {
            name: 'Ostatní příjmy',
            realityValue: financeYearSum.other_income,
            estimateValue: scenario?.incomes.other_income ?? '-',
            percentage: getPercentage(financeYearSum.other_income, scenario?.incomes.other_income, monthsComplete),
        },
        {
            name: 'Poplatek občanů',
            realityValue: financeYearSum.citizen_fee_income,
            estimateValue: scenario?.incomes.citizens_income ?? '-',
            percentage: getPercentage(financeYearSum.citizen_fee_income, scenario?.incomes.citizens_income, monthsComplete),
        },
        {
            name: 'Poplatek bez slevy',
            realityValue: '-',
            estimateValue: Number.isNaN(
                scenario?.incomes.citizens_income_without_discount - scenario?.incomes.non_paying_citizens_expanse
                )
                    ? '-'
                    : scenario?.incomes.citizens_income_without_discount - scenario?.incomes.non_paying_citizens_expanse,
            secondary: true,
        },
        {
            name: 'Sleva z min. roku',
            realityValue: '-',
            estimateValue: scenario?.incomes.this_year_discount != null ? scenario.incomes.this_year_discount*(-1) : '-',
            secondary: true,
        },
    ]

    const balance = [
        {
            name: 'Doplatek obce',
            realityValue: financeYearExpenses-financeYearIncomes,
            estimateValue: scenario?.annual_surcharge_cents ?? '-',
            percentage: getPercentage(financeYearExpenses-financeYearIncomes, scenario?.annual_surcharge_cents, monthsComplete),
            header: true,
        },
        {
            name: 'Sleva příští rok',
            realityValue: '-',
            estimateValue: scenario?.incomes?.next_year_discount != null ? scenario.incomes.next_year_discount : '-',
        },
    ]

    const thStyles = {
        paddingLeft: '1.5rem',
        paddingBottom: '0.3rem',
        borderBottom: '1px solid #E8E8E8',
    }
    const headerStyles = {
        paddingBottom: '0.3rem',
        borderBottom: '1px solid #E8E8E8',
        paddingTop: '0.3rem',
    }

    const footerStyles = {
        paddingBottom: '0.1rem',
        paddingTop: '0.3rem',
        borderTop: '1px solid #E8E8E8',
    }
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 1,
            flexGrow: 1,
            overflowX: 'auto',
        }}>
            <Typography variant="h3" component="h2" mb={2}>
                Ekonomická rozvaha
            </Typography>
            <Box sx={(theme) => ({
                borderRadius: 2.5,
                border: `1px solid ${theme.palette.divider}`,
                px: 3,
                py: 1.5,
                // color: theme.palette.customGrey.tableText,
                overflowX: 'auto',
                zIndex: 2,
                mb: 2,
            })}>
                <table style={{ width: '100%', minWidth: 500 }}>
                    <tbody>
                        <tr>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25 }}>
                                    Realita ({year})
                                </Typography>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25 }}>
                                    Plán podle modelu
                                </Typography>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25 }}>
                                    Současné plnění plánu
                                </Typography>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25 }}>
                                    Predikce konce roku
                                </Typography>
                            </th>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '0.2rem' }}/>
                        </tr>
                        {expenses.map(expenseItem => (
                            <tr key={expenseItem.name}>
                                <td style={{
                                    paddingLeft: expenseItem.secondary ? '1.5rem' : 'unset',
                                    ...expenseItem.header ? headerStyles : null
                                }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...expenseItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {expenseItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...expenseItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {typeof expenseItem.realityValue === 'number' ?
                                            printPrice(expenseItem.realityValue, false)
                                            :
                                            expenseItem.realityValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...expenseItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof expenseItem.estimateValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(expenseItem.estimateValue)), false)
                                            :
                                            expenseItem.estimateValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...expenseItem.secondary ? { color: 'customGrey.muteText'} : null,
                                            ...expenseItem.percentage?.comparison === 'gt' ? { color: 'error.main'} : null,
                                            ...expenseItem.percentage?.comparison === 'lt' ? { color: 'success.main'} : null,
                                        }}
                                    >
                                        
                                        {expenseItem.percentage?.percentage ? 
                                            `${expenseItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${expenseItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${expenseItem.percentage?.percentage}%`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...expenseItem.secondary ? { color: 'customGrey.muteText'} : null,
                                            ...expenseItem.percentage?.comparison === 'gt' ? { color: 'error.main'} : null,
                                            ...expenseItem.percentage?.comparison === 'lt' ? { color: 'success.main'} : null,
                                        }}
                                    >
                                        {expenseItem.percentage?.prediction ? 
                                            `${expenseItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${expenseItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${printPrice(expenseItem.percentage.prediction, false)}`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ paddingTop: '1rem' }}/>
                        </tr>
                        {incomes.map(incomeItem => (
                            <tr key={incomeItem.name}>
                                <td style={{
                                    paddingLeft: incomeItem.secondary ? '1.5rem' : 'unset',
                                    ...incomeItem.header ? headerStyles : null
                                }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...incomeItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {incomeItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...incomeItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {typeof incomeItem.realityValue === 'number' ?
                                            printPrice(incomeItem.realityValue, false)
                                            :
                                            incomeItem.realityValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...incomeItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof incomeItem.estimateValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(incomeItem.estimateValue)), false)
                                            :
                                            incomeItem.estimateValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...incomeItem.secondary ? { color: 'customGrey.muteText'} : null,
                                            ...incomeItem.percentage?.comparison === 'gt' ? { color: 'success.main'} : null,
                                            ...incomeItem.percentage?.comparison === 'lt' ? { color: 'error.main'} : null,
                                        }}
                                    >
                                        
                                        {incomeItem.percentage?.percentage ? 
                                            `${incomeItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${incomeItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${incomeItem.percentage?.percentage}%`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...incomeItem.secondary ? { color: 'customGrey.muteText'} : null,
                                            ...incomeItem.percentage?.comparison === 'gt' ? { color: 'success.main'} : null,
                                            ...incomeItem.percentage?.comparison === 'lt' ? { color: 'error.main'} : null,
                                        }}
                                    >
                                        {incomeItem.percentage?.prediction ? 
                                            `${incomeItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${incomeItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${printPrice(incomeItem.percentage.prediction, false)}`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ paddingTop: '1rem' }}/>
                            <td style={{ paddingTop: '1rem' }}/>
                        </tr>
                        {balance.map(balanceItem => (
                            <tr key={balanceItem.name}>
                                <td style={balanceItem.header ? footerStyles : null }>
                                    <Typography
                                        variant={balanceItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25 }}
                                    >
                                        {balanceItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography variant={balanceItem.header ? 'h5' : 'body1'} component="span" sx={{ lineHeight: 1.25 }}>
                                        {typeof balanceItem.realityValue === 'number' ?
                                            printPrice(balanceItem.realityValue, false)
                                            :
                                            balanceItem.realityValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography variant={balanceItem.header ? 'h5' : 'body1'} component="span" sx={{ lineHeight: 1.25 }}>
                                        {typeof balanceItem.estimateValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(balanceItem.estimateValue)), false)
                                            :
                                            !scenario && (year >= START_YEAR) ?
                                                <ErrorOutlineRoundedIcon color="error" />
                                                : 
                                                balanceItem.estimateValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography
                                        variant={balanceItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...balanceItem.percentage?.comparison === 'gt' ? { color: 'error.main'} : null,
                                            ...balanceItem.percentage?.comparison === 'lt' ? { color: 'success.main'} : null,
                                        }}
                                    >
                                        
                                        {balanceItem.percentage?.percentage ? 
                                            `${balanceItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${balanceItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${balanceItem.percentage?.percentage}%`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography
                                        variant={balanceItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{
                                            lineHeight: 1.25,
                                            ...balanceItem.percentage?.comparison === 'gt' ? { color: 'error.main'} : null,
                                            ...balanceItem.percentage?.comparison === 'lt' ? { color: 'success.main'} : null,
                                        }}
                                    >
                                        {balanceItem.percentage?.prediction ? 
                                            `${balanceItem.percentage?.comparison === 'gt' ? '⇡\xa0' : ''}${balanceItem.percentage?.comparison === 'lt' ? '⇣\xa0' : ''}${printPrice(balanceItem.percentage.prediction, false)}`
                                            : '-'
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
            {!scenario && (year >= START_YEAR) &&
                <Box>
                    <Alert
                        severity="error"
                        sx={(theme) => ({
                            flexGrow: 1,
                            margin: theme.spacing(1, 0),
                        })}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            Odhad pro {year} je prázdný, protože nemáte aktivní žádný model pro {year}. Model můžete doplnit v seznamu motivačních modelů nebo kliknutím na tlačítko níže.
                            <Box display="flex" justifyContent="center" pr={4} pt={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    component={Link}
                                    to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationProgram}?tab=${getYearTab(year, YEARS_FROM_START_TO_NEXT)}`}
                                >
                                    Zvolit model pro {year}
                                </Button>
                            </Box>
                        </Box>
                    </Alert>
                </Box>
            }
        </Box>
    )
}
