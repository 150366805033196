import { useState, useEffect } from 'react'
import { atom, useAtom, useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import HouseRoundedIcon from '@mui/icons-material/HouseRounded'
import CabinRoundedIcon from '@mui/icons-material/CabinRounded'
import RecyclingRoundedIcon from '@mui/icons-material/RecyclingRounded'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BlockIcon from '@mui/icons-material/Block'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'

import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { useCollectionPointCategoryList, useRefreshHouseholdCategories } from '@/hooks/api/use-municipality'
// import useDateInterval from '@/hooks/use-date-interval'
import {
    CollectionPointTypes,
    type MotivationModelListItem,
    type Categories,
    type Pagination,
    type CollectionPointCategoryWithSingleExtension,
    MMParticipation,
} from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP } from '@/constants/general'
import CategorySelector from './category-selector'
import PaginationControls from '@/components/pagination-controls'
import { Typography } from '@mui/material'
import MotivationModelSelector from './motivation-model-selector'
import TypeSelector from './type-selector'
import NumberTextfield from '@/components/form-fields/number-textfield'
import { convertFromCents, printPrice, roundPrice } from '@/helpers'
import CollectionPointCategoriesExport from './export'
import CollectionPointCategoriesActions from './actions'
import CollectionPointParticipationForm from '../forms/collection-point-participation'
import useMounted from '@/hooks/use-mounted'

const COLLECTION_POINT_TYPE_ICON_MAP = {
    [CollectionPointTypes.apartmentBuilding]: <ApartmentIcon/>,
    [CollectionPointTypes.holidayHome]: <CabinRoundedIcon/>,
    [CollectionPointTypes.familyHouse]: <HouseRoundedIcon/>,
    [CollectionPointTypes.publicWastepoint]: <RecyclingRoundedIcon />,
    [CollectionPointTypes.unknown]: <BlockIcon/>,
}

const CATEGORIES_COLORS_MAP = {
    '1': 'categories.first',
    '2': 'categories.second',
    '3': 'categories.third',
    '4': 'categories.fourth',
    '5': 'categories.fifth',
}

const mixedWeightCategoriesAtom = atom<Categories[]>([])
const mixedVolumeCategoriesAtom = atom<Categories[]>([])
const typesAtom = atom<CollectionPointTypes[]>([])
const numberOfMembersAtom = atom<string>('')
const inMotivationProgramAtom = atom<MMParticipation>(MMParticipation.all)

const paginationAtom = atom<Pagination>({
    limit: 20,
    offset: 0,
})

function CollectionPointCategoriesList({
    year,
    motivationModels,
}: {
    year: number,
    motivationModels: Array<MotivationModelListItem>,
}) {

    const activeMotivationModel = motivationModels.find(model => model.active)
    const inComparisonMotivationModel = motivationModels.find(model => model.in_comparison)
    const initialModelId = activeMotivationModel?.id ?? inComparisonMotivationModel?.id ?? motivationModels[0]?.id ?? null

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [motivationModelId, setMotivationModelId] = useState<number | null>(initialModelId)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const [edit, setEdit] = useState<CollectionPointCategoryWithSingleExtension | null>(null)
    
    const [pagination, setPagination] = useAtom(paginationAtom)

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    const [mixedWeightCategories, setMixedWeightCategories] = useAtom(mixedWeightCategoriesAtom)
    const [mixedVolumeCategories, setMixedVolumeCategories] = useAtom(mixedVolumeCategoriesAtom)
    const [types, setTypes] = useAtom(typesAtom)
    const [numberOfMembers, setNumberOfMembers] = useAtom(numberOfMembersAtom)
    const [inMotivationProgram, setInMotivationProgram] = useAtom(inMotivationProgramAtom)

    const handleResetFilters = () => {
        setMixedWeightCategories([])
        setMixedVolumeCategories([])
        setTypes([])
        setNumberOfMembers('')
        setInMotivationProgram(MMParticipation.all)
    }

    const handleNumberOfMembersChange = ({
        target: {
            value,
        },
    }) => {
        setNumberOfMembers(value)
    }

    const isMounted = useMounted()

    /*  biome-ignore lint: useEffect deps
        We want to run this in reaction to municipalityId change
    */
    useEffect(() => {
        if(isMounted) {
            handleResetPagination()
            setMotivationModelId(initialModelId)
        }
    }, [municipalityId])

    const { status, data, error, isPlaceholderData } = useCollectionPointCategoryList(
        municipalityId,
        motivationModelId,
        pagination,
        year,
        {
            mixedWeightCategories,
            mixedVolumeCategories,
            types,
            numberOfMembers,
            inMotivationProgram,
        }
    )

    const mutation = useRefreshHouseholdCategories(
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickRefresh = () => {
        setRequestInProgress(true)
        mutation.mutate({ motivationModelId, municipalityId, year })
    }

    if (requestInProgress) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress />
                    <Typography variant='h6' component="div" mt={2}>
                        Přepočítávám kategorie
                    </Typography>
                    <Typography variant='body2' component="div">
                        Pro velké obce může tato operace trvat i několik minut.
                    </Typography>
                </Box>
            </Box>
        )
    }

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst svozová místa a jejich kategorie pro obec s ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto',
            pb: 2,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                },
                flexWrap: 'wrap'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    py: 1,
                    flexWrap: 'wrap',
                    px: 3,
                    alignItems: 'center',
                    order: 1,
                    '@media (min-width: 2162px)': {
                        order: 2,
                    },
                }}>
                    <Typography variant="h6" component="h6">Kategorie</Typography>
                    <Box minWidth={220}>
                        <MotivationModelSelector
                            motivationModelId={motivationModelId}
                            setMotivationModelId={setMotivationModelId}
                            year={year}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                            onClick={() => handleClickRefresh()}
                            disabled={requestInProgress || !motivationModelId || motivationModelId === initialModelId}
                        >
                            Přepočítat
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    py: 1,
                    flexWrap: 'wrap',
                    px: 3,
                    alignItems: 'center',
                    order: 2,
                    '@media (min-width: 2162px)': {
                        order: 1,
                    },
                }}>
                    <Typography variant="h6" component="h6">Filtrovat</Typography>
                    <Box display="flex">
                        <CategorySelector
                            label="Kategorie hmotnost"
                            categories={mixedWeightCategories}
                            setCategories={setMixedWeightCategories}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex">
                        <CategorySelector
                            label="Kategorie objem"
                            categories={mixedVolumeCategories}
                            setCategories={setMixedVolumeCategories}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex" maxWidth={190}>
                        <TypeSelector
                            label="Typy"
                            types={types}
                            setTypes={setTypes}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex">
                        <NumberTextfield
                            label="Počet členů"
                            onChange={(e) => handleNumberOfMembersChange(e)}
                            name='numberOfMembers'
                            value={numberOfMembers}
                            size='small'
                            thousandSeparator
                        />
                    </Box>
                    <Box sx={{ minWidth: 190 }}>
                        <FormControl fullWidth>
                            <InputLabel id="in-motivation-program-select-label" size="small">Účast v motivačním programu</InputLabel>
                            <Select
                                labelId="in-motivation-program-select-label"
                                id="in-motivation-program-select"
                                value={inMotivationProgram}
                                name="inMotivationProgram"
                                label="Účast v motivačním programu"
                                onChange={(e) => setInMotivationProgram(e.target.value as MMParticipation)}
                                size="small"
                            >
                                <MenuItem value={MMParticipation.all}>Všichni</MenuItem>
                                <MenuItem value={MMParticipation.yes}>Účastní se</MenuItem>
                                <MenuItem value={MMParticipation.no}>Neúčastní se</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Tooltip title="Resetovat filtry">
                        <IconButton
                            aria-label="Resetovat filtry"
                            onClick={() => handleResetFilters()}
                            size="small"
                        >
                            <DoNotDisturbAltRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    <CollectionPointCategoriesExport
                        year={year}
                        motivationModelId={motivationModelId}
                        filters={{
                            mixedWeightCategories,
                            mixedVolumeCategories,
                            types,
                            numberOfMembers,
                            inMotivationProgram,
                        }}
                    />
                </Box>
            </Box>
            {/* <Divider /> */}
            {success &&
                <Box px={3}>
                    <Alert
                        severity="success"
                        sx={(theme) => ({
                            flexGrow: 1,
                            margin: theme.spacing(1, 0),
                        })}
                        action={
                            <Button
                                color="success"
                                size="small"
                                onClick={() => setSuccess(false)}
                            >
                                Zavřít
                            </Button>
                        }
                    >
                        Kategorie úspěšně přepočítány.
                    </Alert>
                </Box>
            }
            <Box>
                <Table
                    aria-label="Roční statistika odpadu ve sběrných dvorech"
                    sx={{
                        mt: 1,
                        minWidth: 600,
                    }}
                    size='small'
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: 3 }}>Účast v MP</TableCell>
                            <TableCell>Kód</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Adresa</TableCell>
                            <TableCell>Správce</TableCell>
                            <TableCell>Počet členů</TableCell>
                            <TableCell>Kat. hmotnost</TableCell>
                            <TableCell>Kat. objem</TableCell>
                            <TableCell>Sleva celkem</TableCell>
                            <TableCell>Poplatek/os.</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.eoko_household.map(row => {
                            const ext = row.exts.find(ext => ext.year === year)
                            const mixedWeightCategory =
                                motivationModelId === activeMotivationModel?.id ?
                                    ext.active_mixed_weight_category :
                                    motivationModelId === inComparisonMotivationModel?.id ?
                                        ext.mixed_weight_category :
                                        null
                            const mixedVolumeCategory =
                                motivationModelId === activeMotivationModel?.id ?
                                    ext.active_mixed_volume_category :
                                    motivationModelId === inComparisonMotivationModel?.id ?
                                        ext.mixed_volume_category :
                                        null
                            const discount_cents = ext.in_motivation_program ?
                                mixedWeightCategory?.discount_amount_cents+mixedVolumeCategory?.discount_amount_cents :
                                ext.force_discount_cents

                            const base_fee_cents = mixedWeightCategory?.motivation_model?.citizen_fee_cents || mixedVolumeCategory?.motivation_model?.citizen_fee_cents

                            const fee_after_discount = base_fee_cents != null ?
                                discount_cents != null ?
                                    base_fee_cents > discount_cents ?
                                        base_fee_cents-discount_cents
                                        :
                                        0
                                    :
                                    base_fee_cents
                                :
                                null
                            
                            return (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                >
                                    <TableCell component="th" sx={{ paddingLeft: 3 }}>
                                        {ext?.in_motivation_program != null ?
                                            ext.in_motivation_program ?
                                                null :
                                                <CancelRoundedIcon color="error" />
                                            : '-'
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        {row.code}
                                    </TableCell>
                                    <TableCell sx={{color: 'customGrey.tableText'}}>
                                        <Tooltip title={COLLECTION_POINT_TYPE_LABEL_MAP[row.type]}>
                                            {COLLECTION_POINT_TYPE_ICON_MAP[row.type]}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {row.address?.street ?? '-'} {row.address?.street_number ?? '-'}
                                    </TableCell>
                                    <TableCell>
                                        {row.admin?.first_name ?? '-'} {row.admin?.last_name ?? '-'}
                                    </TableCell>
                                    <TableCell>
                                        {row.number_of_members ?? '-'}
                                    </TableCell>
                                    <TableCell>
                                        {mixedWeightCategory?.level &&
                                            <Box sx={{
                                                backgroundColor: CATEGORIES_COLORS_MAP[mixedWeightCategory.level],
                                                width: 22,
                                                height: 22,
                                                borderRadius: 10,
                                                color: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                {mixedWeightCategory.level}
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {mixedVolumeCategory?.level &&
                                            <Box sx={{
                                                backgroundColor: CATEGORIES_COLORS_MAP[mixedVolumeCategory.level],
                                                width: 22,
                                                height: 22,
                                                borderRadius: 10,
                                                color: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                {mixedVolumeCategory.level}
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {discount_cents != null ? printPrice(roundPrice(convertFromCents(discount_cents))) : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {fee_after_discount != null ? printPrice(roundPrice(convertFromCents(fee_after_discount))) : '-'}
                                    </TableCell>
                                    <TableCell align="right">
                                        <CollectionPointCategoriesActions
                                            collectionPoint={{
                                                ...row,
                                                ext,
                                            }}
                                            setEdit={setEdit}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Box minHeight={4}>
                    {isPlaceholderData &&
                        <LinearProgress />
                    }
                </Box>
                <PaginationControls
                    pagination={pagination}
                    setPagination={setPagination}
                    currentArrayLength={data.eoko_household.length}
                    disabled={isPlaceholderData}
                    recordsCount={data.records_count}
                />
                <Dialog
                    key={'edit-'+edit?.id}
                    open={!!edit}
                    onClose={() => setEdit(null)}
                >
                    <DialogContent>
                        <CollectionPointParticipationForm
                            collectionPoint={edit}
                            year={year}
                            closeCallback={() => setEdit(null)}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
            {data.records_count === 0 &&
                <Box px={2.5} pt={2}>
                    V tomto roce obec nemá žádná data o svozových místech a jejich kategoriích. K doplnění dat dochází každou noc. Zkuste to prosím zítra.
                </Box>
            }
        </Box>
    )
}

export default CollectionPointCategoriesList
