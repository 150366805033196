import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs, { type TabsProps } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
    noPadding?: boolean,
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, noPadding, ...other } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: value === index ? 1 : 0 }}
            {...other}
        >
            {value === index && (
                <Box sx={{
                    p: noPadding ? 0 : 2.5,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}>
                    {children}
                </Box>
            )}
        </Box>
    )
}

export function CustomTabPanelSmall(props: TabPanelProps) {
    const { children, value, index, noPadding, ...other } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: value === index ? 1 : 0 }}
            {...other}
        >
            {value === index && (
                <Box sx={{
                    py: noPadding ? 0 : 2.5,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}>
                    {children}
                </Box>
            )}
        </Box>
    )
}

// interface CustomTabsProps {
//     children?: React.ReactNode
//     value: number
//     onChange: (event: React.SyntheticEvent, newValue: number) => void
// }

export const CustomTabs = styled((props: TabsProps) => (
    <Tabs
        {...props}
    />
))({
    minHeight: 0,
    paddingBottom: '0.5rem',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
        gap: '0.5rem',
    },
})

export const CustomTabsWithPadding = styled((props: TabsProps) => (
    <Tabs
        {...props}
    />
))({
    paddingLeft: '1.2rem',
    minHeight: 0,
    paddingBottom: '0.5rem',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
        gap: '0.5rem',
    },
})

export const CustomTabsSmall = styled((props: TabsProps) => (
    <Tabs
        {...props}
    />
))({
    minHeight: 0,
    paddingBottom: '0.3rem',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
        gap: '0.2rem',
    },
})

interface CustomTabProps {
    label: string | number
}

export const CustomTab = styled((props: CustomTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5, 2),
    minHeight: 34,
    minWidth: 70,
    borderRadius: 100,
    transition: theme.transitions.create(['background-color', 'color'], {
        duration: 150,
    }),
    '&:hover': {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[100],
    },
    '&.Mui-selected': {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.customLightGreen.dark,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}))

export const CustomButtonTab = styled((props: CustomTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    padding: theme.spacing(0.5, 2),
    minHeight: 34,
    minWidth: 70,
    borderRadius: 100,
    backgroundColor: theme.palette.terciary.main,
    transition: theme.transitions.create(['background-color', 'color'], {
        duration: 150,
    }),
    '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}))

export const CustomTabSmall = styled((props: CustomTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.grey[500],
    padding: theme.spacing(0.3, 2),
    minHeight: 30,
    minWidth: 0,
    borderRadius: 100,
    transition: theme.transitions.create(['background-color', 'color'], {
        duration: 150,
    }),
    '&:hover': {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[100],
    },
    '&.Mui-selected': {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.customLightGreen.dark,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}))
