import { CollectionPointTypes, ScenarioTypes, UserRole, WasteTypeCodes, WasteTypeIds } from '@/types'
import packageJson from './../../../package.json'
import type { LatLngBoundsExpression } from 'leaflet'
import dayjs from 'dayjs'

export const DOM_NODE_ID = 'react-app-root'

const domNode = document.getElementById(DOM_NODE_ID)
const appVersion = domNode?.getAttribute('version')

export const APP_VERSION = appVersion ?? packageJson.version

/** route segments */
export const ROUTE_SEGMENTS = {
    // General
    base: 'app',
    public: 'public',
    home: 'home',
    edit: 'edit',
    step: 'krok',
    new: 'novy',
    signOut: 'log_out',

    // Municipality
    municipality: 'obec',
    collectionCalendar: 'svozovy-kalendar',
    collectingYards: 'sberne-dvory',
    collectionNotification: 'upozorneni-ke-svozu',
    wasteFee: 'poplatek-za-svoz',
    howToPayLess: 'jak-platit-mene',
    largeContainerMap: 'mapa-velkych-kontejneru',
    collectionPointList: 'seznam-svozovych-mist',
    reportIllegalDump: 'hlaseni-cerne-skladky',
    municipalitySortingTrend: 'trend-separace-v-obci',
    // contactsMunicipality: 'kontakty-obec',
    // contactsCollectionCompany: 'kontakty-svozovka',
    contacts: 'kontakty',
    

    // Collection point
    collectionPoint: 'svozove-misto',
    detail: 'detail',
    disposalTrend: 'trend-vysypu',
    collectionClaim: 'reklamace-svozu',
    extraCollection: 'objednani-extra-svozu',
    largeContainer: 'objednani-kontejneru',

    // Municipality management
    municipalityManagement: 'sprava-obce',
    motivationProgram: 'motivacni-program',
    collectionPointCategories: 'seznam-svozovych-mist-s-kategorii',
    finances: 'hospodareni-obce',
    illegalDumps: 'cerne-skladky',
    collectionNotificationManagement: 'sprava-upozorneni-ke-svozu',
    collectedWasteStats: 'produkce-odpadu',

    // App management
    appManagement: 'sprava-aplikace',
    ekokomBonus: 'ekokom-bonus',
    users: 'sprava-uzivatelu',

    // Admin only
    householdRefresh: 'prepocitani-statistik',
}

export const ALLOW_ALL = 'allow_all'

export const MIN_CITIZEN_FEE_CENTS = 0
export const MAX_CITIZEN_FEE_CENTS = 120000

export const WASTE_COLORS = {
    municipal: {
        light: '#ced6d6',
        main: '#383E42',
        dark: '#383E42',
    },
    glass: {
        light: '#ddedca',
        main: '#99C158',
        dark: '#99C158',
    },
    bio: {
        light: '#f0e6e2',
        main: '#795548',
        dark: '#795548',
    },
    plastic: {
        light: '#fffad0',
        main: '#EAD600',
        dark: '#EDD135',
    },
    paper: {
        light: '#D6E9FB',
        main: '#00A9F4',
        dark: '#5EA6EF',
    },
    other_recycling: {
        light: '#f5e5ef',
        main: '#bc81a6',
        dark: '#986083',
    },
    recycling: {
        light: '#dcf2dd',
        main: '#368C36',
        dark: '#368C36',
    },
}

export const COMPARISON_COLORS = {
    general: {
        light: '#378fff',
        main: '#1378f8',
        dark: '#0059cb',
    }
}

export const WASTE_TYPE_ID_COLOR_MAP = {
    [WasteTypeIds.bio]: WASTE_COLORS.bio,
    [WasteTypeIds.paper]: WASTE_COLORS.paper,
    [WasteTypeIds.plastic]: WASTE_COLORS.plastic,
    [WasteTypeIds.mixed]: WASTE_COLORS.municipal,
    [WasteTypeIds.glass]: WASTE_COLORS.glass,
    [WasteTypeIds.clearGlass]: WASTE_COLORS.glass,
}

export const WASTE_TYPE_CODE_TO_ID_MAP = {
    [WasteTypeCodes.bio]: WasteTypeIds.bio,
    [WasteTypeCodes.paper]: WasteTypeIds.paper,
    [WasteTypeCodes.plastic]: WasteTypeIds.plastic,
    [WasteTypeCodes.mixed]: WasteTypeIds.mixed,
    [WasteTypeCodes.glass]: WasteTypeIds.glass,
    [WasteTypeCodes.clearGlass]: WasteTypeIds.clearGlass,
}

export const WASTE_TYPE_ID_LABEL_MAP = {
    [WasteTypeIds.bio]: {
        short: 'Bio',
        long: 'Bio',
    },
    [WasteTypeIds.paper]: {
        short: 'Papír',
        long: 'Papír',
    },
    [WasteTypeIds.plastic]: {
        short: 'Plast',
        long: 'Plast',
    },
    [WasteTypeIds.mixed]: {
        short: 'SKO',
        long: 'Směsný (SKO)',
    },
    [WasteTypeIds.glass]: {
        short: 'Sklo',
        long: 'Sklo',
    },
    [WasteTypeIds.clearGlass]: {
        short: 'Čiré sklo',
        long: 'Čiré sklo',
    },
}

export const WASTE_TYPE_ID_DENSITY_MAP = {
    // Values from AI
    // [WasteTypeIds.bio]: 350,
    // [WasteTypeIds.paper]: 85,
    // [WasteTypeIds.plastic]: 25,
    // [WasteTypeIds.mixed]: 200,
    // [WasteTypeIds.glass]: 700,
    // [WasteTypeIds.clearGlass]: 700,

    // Values from STKO
    [WasteTypeIds.bio]: 181,
    [WasteTypeIds.paper]: 64,
    [WasteTypeIds.plastic]: 50,
    [WasteTypeIds.mixed]: 131,
    [WasteTypeIds.glass]: 700,
    [WasteTypeIds.clearGlass]: 700,
}

export const SCENARIO_LABEL_MAP = {
    [ScenarioTypes.pesimistic]: 'Bez změny',
    [ScenarioTypes.realistic]: 'Realistický',
    [ScenarioTypes.optimistic]: 'Optimistický',
}

export const CZECH_REGIONS = {
    PRAGUE: { id: 1, name: "Hlavní město Praha" },
    SOUTH_BOHEMIA: { id: 2, name: "Jihočeský kraj" },
    SOUTH_MORAVIA: { id: 3, name: "Jihomoravský kraj" },
    KARLOVY_VARY: { id: 4, name: "Karlovarský kraj" },
    HRADEC_KRALOVE: { id: 5, name: "Královéhradecký kraj" },
    LIBEREC: { id: 6, name: "Liberecký kraj" },
    MORAVIAN_SILESIAN: { id: 7, name: "Moravskoslezský kraj" },
    OLOMOUC: { id: 8, name: "Olomoucký kraj" },
    PARDUBICE: { id: 9, name: "Pardubický kraj" },
    PLZEN: { id: 10, name: "Plzeňský kraj" },
    CENTRAL_BOHEMIA: { id: 11, name: "Středočeský kraj" },
    USTI_NAD_LABEM: { id: 12, name: "Ústecký kraj" },
    VYSOCINA: { id: 13, name: "Vysočina" },
    ZLIN: { id: 14, name: "Zlínský kraj" }
}

export const COLLECTION_POINT_TYPE_LABEL_MAP = {
    [CollectionPointTypes.apartmentBuilding]: 'Bytový dům',
    [CollectionPointTypes.holidayHome]: 'Rekreační objekt',
    [CollectionPointTypes.familyHouse]: 'Rodinný dům',
    [CollectionPointTypes.publicWastepoint]: 'Veřejné stanoviště',
    [CollectionPointTypes.unknown]: 'Neuvedeno',
}

export const CZECH_REGIONS_ARRAY =
    Object.values(CZECH_REGIONS)

export const START_DATE_KEY = 'start-date'
export const END_DATE_KEY = 'end-date'

export const DEFAULT_BOUNDS: LatLngBoundsExpression = [
    [49.011540, 16.307285],
    [48.744062, 16.995785],
]

const thisYear = dayjs().year()
const nextYear = dayjs().year()+1
export const START_YEAR = 2023

export const YEARS_FROM_START_TO_THIS: Array<number> = Array.from({ length: thisYear - START_YEAR + 1 }, (_, i) => thisYear - i)
export const YEARS_FROM_START_TO_NEXT: Array<number> = Array.from({ length: nextYear - START_YEAR + 1 }, (_, i) => nextYear - i)

export const HISTORY_RETURN_PARAM_KEY = 'history-return'

export const USER_ROLE_LABEL_MAP = {
    [UserRole.basic]: 'Veřejnost',
    [UserRole.collectionCompany]: 'Svozová společnost',
    [UserRole.municipalityOfficial]: 'Správce obce',
    [UserRole.collectionPointAdmin]: 'Správce svoz. místa',
    [UserRole.resident]: 'Občan',
    [UserRole.eokoDataAdmin]: 'EOKO Admin',
    [UserRole.admin]: 'Admin',
}
