import { atom, type PrimitiveAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { getUserMunicipalityId } from './helpers'

const initialMunicipalityId = getUserMunicipalityId()

export const selectedMunicipalityIdAtom = atomWithStorage<number | null>('municipalityId', initialMunicipalityId)

export const selectedCollectionPointIdAtom = atomWithStorage<number | null>('collectionPointId', null)

export const exportIdAtom = atom<number | null>(null)  as PrimitiveAtom<number | null>
