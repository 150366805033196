import dayjs from 'dayjs'

import { useCalculateMotivationModel } from '@/hooks/api/use-motivation-model'
import type {
    MotivationModel,
    MonthYear,
    MunicipalityFinanceYearSum,
} from '@/types'
import MunicipalityFinancesIncomeStatement from './income-statement'

export default function MunicipalityFinancesIncomeStatementWrapper({
    year,
    activeMotivationModel,
    financeYearSum,
}: {
    year: number,
    activeMotivationModel?: MotivationModel,
    financeYearSum: MunicipalityFinanceYearSum,
}) {

    const motivationModelYear = activeMotivationModel?.year

    const startDate = dayjs(motivationModelYear+'-01-01').format('YYYY-MM') as MonthYear
    const endDate = dayjs(motivationModelYear+'-12-31').format('YYYY-MM') as MonthYear

    const { data } =
        useCalculateMotivationModel(
            activeMotivationModel?.id,
            motivationModelYear,
            startDate,
            endDate,
            activeMotivationModel?.selected_scenario,
            null,
            Number(activeMotivationModel?.citizen_fee_cents)/100,
        )
    
    return (
        <MunicipalityFinancesIncomeStatement
            scenario={data}
            financeYearSum={financeYearSum}
            year={year}
        />
    )
}
