import { useForm, Controller, type SubmitHandler } from "react-hook-form"
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import { USER_ROLE_LABEL_MAP } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import { UserRole, type UserFormInput } from "@/types"
import { useCreateUser } from "@/hooks/api/use-users"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import MunicipalitiesLoader from "@/wrappers/municipalities-loader"
import RHFPassword from "@/components/form-fields/rhf-password"

const UserForm = ({
    closeCallback,
} : {
    closeCallback?: () => void,
}) => {

    const initialValues = {
        email: '',
        password: '',
        role: UserRole.basic,
        eoko_contact_id: '',
        municipality_id: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(() => reset(initialValues))

    const mutationCreate = useCreateUser(
        {
            setRequestInProgress,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        }
    )

    const onSubmit: SubmitHandler<UserFormInput> = data => {
        setRequestInProgress(true)
        mutationCreate.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Vytvořit nového uživatele'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={false}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            error={error}
            setError={setError}
        >
            <RHFTextfield
                name="email"
                control={control}
                label="Email"
                required
                type="email"
            />
            <RHFPassword
                name="password"
                control={control}
                label="Heslo"
                required
            />
            <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Rok">
                            {Object.values(UserRole).map(role => (
                                <MenuItem key={role} value={role}>{USER_ROLE_LABEL_MAP[role]}</MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
            <MunicipalitiesLoader>
                {municipalities => (
                    <FormControl fullWidth>
                        <InputLabel id="municipality-select-label">Obec</InputLabel>
                        <Controller
                            name="municipality_id"
                            control={control}
                            render={({ field }) => (
                                <Select {...field} label="Rok">
                                    <MenuItem key="none" value="">-</MenuItem>
                                    {municipalities.map(municipality => (
                                        <MenuItem key={municipality.id} value={municipality.id}>{municipality.name}</MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                )}
            </MunicipalitiesLoader>
            <RHFNumberTextfield
                name="eoko_contact_id"
                control={control}
                label="ID kontaktu v EOKU"
            />
        </Form>
    )
}

export default UserForm
