import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import type {
    FormMutationOptions,
    MunicipalityFinanceMonthFormInput,
    MonthYear,
    MunicipalityFinanceMonth
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"

export function useMunicipalityFinances(
    municipalityId: number,
    startDate: MonthYear,
    endDate: MonthYear,
) {
    return useQuery({
        queryKey: [
            'municipalityFinances',
            municipalityId,
            'startDate-'+startDate,
            'endDate-'+endDate,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('begins_month', startDate)
            queryParams.append('ends_month', endDate)
            
            const { data } : { data: Array<MunicipalityFinanceMonth>} = await axios.get(
                `/api/municipality_management/list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export function useMunicipalityFinanceMonth(financeMonthId) {
    return useQuery({
        queryKey: ['municipalityFinanceMonth', financeMonthId],
        queryFn: async () => {
            const { data } : { data: MunicipalityFinanceMonth } = await axios.get(
                `/api/municipality_management/single?id=${financeMonthId}`
            )
            return data
        },
    })
}

export const useCreateMunicipalityFinanceMonth = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MunicipalityFinanceMonthFormInput) => {            
            return axios.post(`/api/municipality_management/create?municipality_id=${municipalityId
                }&year_month=${data.year_month+'-15'
                }&bin_collection_expenses=${data.bin_collection_expenses
                }&collection_yard_expenses=${data.collection_yard_expenses
                }&other_expenses=${data.other_expenses
                }&ekokom_income=${data.ekokom_income
                }&citizen_fee_income=${data.citizen_fee_income
                }&collection_yard_income=${data.collection_yard_income
                }&other_income=${data.other_income}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['municipalityFinances'] })
            queryClient.invalidateQueries({ queryKey: ['municipalityFinanceMonth'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateMunicipalityFinanceMonth = (financeMonthId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MunicipalityFinanceMonthFormInput) => {
            return axios.put(`/api/municipality_management/update?id=${financeMonthId 
                }&bin_collection_expenses=${data.bin_collection_expenses
                }&collection_yard_expenses=${data.collection_yard_expenses
                }&other_expenses=${data.other_expenses
                }&ekokom_income=${data.ekokom_income
                }&citizen_fee_income=${data.citizen_fee_income
                }&collection_yard_income=${data.collection_yard_income
                }&other_income=${data.other_income}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['municipalityFinances'] })
            queryClient.invalidateQueries({ queryKey: ['municipalityFinanceMonth'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeleteMunicipalityFinanceMonth = (financeMonthId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/municipality_management/delete?id=${financeMonthId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['municipalityFinances'] })
        },
    })
}
