import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'

export function useVersionCheck() {
    return useQuery({
        queryKey: ['versionCheck'],
        queryFn: async () => {
            const { data } : { data: string } = await axios.get(
                '/api/citizen_portal/version'
            )
            return data
        },
        staleTime: 1000*60,
        refetchInterval: 1000*60,
    })
}
