import type { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'

export default function InfoBar({
    icon,
    mainButton,
    secondButton,
    text,
}: {
    icon?: ReactNode
    mainButton?: {
        onClick: () => void
        text: string
        disabled: boolean
    }
    secondButton?: {
        onClick: () => void
        text: string
    }
    text: string
}) {
    // There is special layout for columnar with button centering
    const COLUMNAR = 'xs'
    const ROW = 'lg'

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    [COLUMNAR]: 'column',
                    [ROW]: 'row',
                },
                justifyContent: 'space-between',
                padding: {
                    [COLUMNAR]: '1rem 1rem 1rem',
                    [ROW]: '.5rem 1rem .5rem .75rem',
                },
                borderRadius: '10px',
                backgroundColor: '#E1F1DE',
                columnGap: '1.25rem',
                rowGap: '.75rem',
                flexWrap: 'wrap',
                mt: 3,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: {
                        [COLUMNAR]: '1rem',
                        [ROW]: '.75rem',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '1.25rem',
                        aspectRatio: 1,
                    }}
                >
                    {icon}
                </Box>
                <Typography
                    sx={{
                        color: '#2E2E2E',
                        fontSize: '1rem',
                        lineHeight: 1.3,
                    }}
                >
                    {text}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.75rem',
                    flexWrap: 'wrap',
                    justifyContent: {
                        [COLUMNAR]: 'center',
                        [ROW]: '',
                    },
                }}
            >
                {mainButton &&
                    <Button
                        variant='contained'
                        onClick={mainButton.onClick}
                        disabled={mainButton.disabled}
                    >
                        {mainButton.text}
                    </Button>
                }
                {secondButton &&
                    <Button
                        color="terciary"
                        variant='contained'
                        onClick={secondButton.onClick}
                        disabled
                    >
                        {secondButton.text}
                    </Button>
                }
            </Box>
        </Box>
    )
}
