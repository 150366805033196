import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import type {
    FormMutationOptions,
    CollectedWasteStatFormInput,
    CollectedWasteStat,
    MonthYear,
    CollectedWasteRecordType
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"

export function useCollectedWasteStats(
    municipalityId: number,
    startDate: MonthYear,
    endDate: MonthYear,
    recordType?: CollectedWasteRecordType
) {
    return useQuery({
        queryKey: [
            'collectedWasteStats',
            municipalityId,
            'startDate-'+startDate,
            'endDate-'+endDate,
            'recordType-'+recordType,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('begins_month', startDate)
            queryParams.append('ends_month', endDate)
            if (recordType) queryParams.append('record_type', recordType)
            
            const { data } : { data: Array<CollectedWasteStat>} = await axios.get(
                `/api/collected_waste_stat/list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export function useCollectedWasteStat(collectedWasteStatId) {
    return useQuery({
        queryKey: ['collectedWasteStat', collectedWasteStatId],
        queryFn: async () => {
            const { data } : { data: Array<CollectedWasteStat> } = await axios.get(
                `/api/collected_waste_stat/single?id=${collectedWasteStatId}`
            )
            return data
        },
    })
}

export const useCreateCollectedWasteStat = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectedWasteStatFormInput) => {            
            return axios.post(`/api/collected_waste_stat/create?municipality_id=${municipalityId
                }&year_month=${data.year_month+'-15'
                }&record_type=${data.record_type
                }&mixed_weight=${data.mixed_weight
                }&bio_weight=${data.bio_weight
                }&paper_weight=${data.paper_weight
                }&plastic_weight=${data.plastic_weight
                }&glass_mixed_weight=${data.glass_mixed_weight
                }&glass_clear_weight=${data.glass_clear_weight
                }&liquid_paperboard_single_weight=${data.liquid_paperboard_single_weight
                }&liquid_paperboard_combined_weight=${data.liquid_paperboard_combined_weight
                }&metal_single_weight=${data.metal_single_weight
                }&metal_combined_weight=${data.metal_combined_weight
                }&wood_weight=${data.wood_weight
                }&construction_waste_weight=${data.construction_waste_weight
                }&hazardous_waste_weight=${data.hazardous_waste_weight
                }&large_volume_waste_weight=${data.large_volume_waste_weight
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectedWasteStats'] })
            queryClient.invalidateQueries({ queryKey: ['collectedWasteStat'] })
            queryClient.invalidateQueries({ queryKey: ['calculateMotivationModel'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateCollectedWasteStat = (collectedWasteStatId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectedWasteStatFormInput) => {
            return axios.put(`/api/collected_waste_stat/update?id=${collectedWasteStatId 
                }&mixed_weight=${data.mixed_weight
                }&bio_weight=${data.bio_weight
                }&paper_weight=${data.paper_weight
                }&plastic_weight=${data.plastic_weight
                }&plastic_weight=${data.plastic_weight
                }&glass_mixed_weight=${data.glass_mixed_weight
                }&glass_clear_weight=${data.glass_clear_weight
                }&liquid_paperboard_single_weight=${data.liquid_paperboard_single_weight
                }&liquid_paperboard_combined_weight=${data.liquid_paperboard_combined_weight
                }&metal_single_weight=${data.metal_single_weight
                }&metal_combined_weight=${data.metal_combined_weight
                }&wood_weight=${data.wood_weight
                }&construction_waste_weight=${data.construction_waste_weight
                }&hazardous_waste_weight=${data.hazardous_waste_weight
                }&large_volume_waste_weight=${data.large_volume_waste_weight
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectedWasteStats'] })
            queryClient.invalidateQueries({ queryKey: ['collectedWasteStat'] })
            queryClient.invalidateQueries({ queryKey: ['calculateMotivationModel'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeleteCollectedWasteStat = (collectedWasteStatId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/collected_waste_stat/delete?id=${collectedWasteStatId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collectedWasteStats'] })
        },
    })
}
