import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import IconButton from '@mui/material/IconButton'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { HISTORY_RETURN_PARAM_KEY, ROUTE_SEGMENTS } from '@/constants/general'
import SingleDumpWrapper from '@/components/illegal-dumps/single-dump-wrapper'

function IllegalDumpDetailView() {

    const title = 'Detail hlášení černé skládky'

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const historyReturn = params.get(HISTORY_RETURN_PARAM_KEY)

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box pb={3} display="flex" alignItems="center" gap={1}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        color="terciary"
                        onClick={historyReturn === 'true' ?
                            () => navigate(-1) :
                            () => navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.illegalDumps}`)}
                    >
                        <ArrowBackRoundedIcon />
                    </IconButton>
                    <Typography variant="h3" component="h1">
                        {title}
                    </Typography>
                </Box>
                <SingleDumpWrapper />
            </CustomPaper>
        </>
    )
}

export default IllegalDumpDetailView
