import Box from '@mui/material/Box'

import { useMotivationModels } from '@/hooks/api/use-motivation-model'
import type { MotivationModelListItem } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useAtomValue } from 'jotai'
import { selectedMunicipalityIdAtom } from '@/state'

interface MotivationModelsLoaderProps {
    year: number,
    children: (data: Array<MotivationModelListItem>) => React.ReactNode,
}

const MotivationModelsLoader: React.FC<MotivationModelsLoaderProps> = ({
    year,
    children,
}) => {
    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useMotivationModels(municipalityId, year)

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst motivační modely pro obec ID ${municipalityId} a rok ${year}`}
        />
    }

    if (data.length === 0) {
        return <Box px={2.5} pt={2}>
            {`Pro rok ${year} nejsou vytvořené žádné motivační modely. Pro zobrazení seznamu svozových míst vytvořte alespoň jeden motivační model.`}
        </Box>
    }

    if (data) {
        return <>{children(data)}</>
    }

    return <>{children(null)}</>
}

export default MotivationModelsLoader
