import {
    type Dispatch,
    type SetStateAction,
    useState,
    type MouseEvent,
} from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'

import { type IllegalDumpPublic, ReportStates, type IllegalDump, type IllegalDumpPublicList } from '@/types'
import { ROUTE_SEGMENTS } from '@/constants/general'

function IllegalDumpsActions({
    illegalDump,
    setStateInProgress,
    setStateResolved,
    setArchive,
    allowDetail,
}: {
    illegalDump: IllegalDump | IllegalDumpPublic,
    setStateInProgress: Dispatch<SetStateAction<IllegalDumpPublicList>>,
    setStateResolved: Dispatch<SetStateAction<IllegalDumpPublicList>>,
    setArchive: Dispatch<SetStateAction<IllegalDumpPublicList>>,
    allowDetail?: boolean,
}) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setStateInProgress(null)
        setStateResolved(null)
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.origin}/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.illegalDumps}/${illegalDump.id}`)
    }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id="actions-button"
                    aria-label="actions"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    color="terciary"
                    onClick={handleClick}
                    sx={{ backgroundColor: 'editableOrange.main' }}
                >
                    <MoreVertRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="actions-menu"
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e: MouseEvent) => {
                    e?.stopPropagation()
                    handleClose()
                }}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                {allowDetail &&
                    <MenuItem>
                        <ListItemIcon>
                            <VisibilityRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Detail
                    </MenuItem>
                }
                <MenuItem
                     onClick={e => {
                        e.stopPropagation()
                        handleCopyToClipboard()
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <LinkRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Zkopírovat odkaz
                </MenuItem>
                {illegalDump.state !== ReportStates.inProgress &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            setStateInProgress(illegalDump)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <HourglassBottomRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        V řešení
                    </MenuItem>
                }
                {illegalDump.state === ReportStates.inProgress &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            setStateResolved(illegalDump)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <CheckCircleRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Vyřešeno
                    </MenuItem>
                }
                {illegalDump.state === ReportStates.resolved &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            setArchive(illegalDump)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <ArchiveRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Archivovat
                    </MenuItem>
                }
            </Menu>
        </>
    )
}

export default IllegalDumpsActions
