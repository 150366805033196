import { useState } from 'react'
import { atom, useAtom } from 'jotai'
import Box from '@mui/material/Box'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Button from '@mui/material/Button'

import { useCollectionNotifications } from '@/hooks/api/use-collection-notification'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { selectedMunicipalityIdAtom } from "@/state"
import CollectionNotificationCard from '@/components/collection-notification/card'
import CollectionNotificationForm from '@/components/forms/collection-notification'
import type { Pagination } from '@/types'
import PaginationControls from '@/components/pagination-controls'

const paginationAtom = atom<Pagination>({
    limit: 10,
    offset: 0,
})

function CollectionNotificationList({
    editable,
}: {
    editable: boolean,
}) {

    const [create, setCreate] = useState<boolean>(false)
    const [pagination, setPagination] = useAtom(paginationAtom)
    
    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useCollectionNotifications(municipalityId, pagination)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst upozornění ke svozu pro obec ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            pt: 2,
            maxWidth: 960,
        }}>
            {editable &&
                <Box pb={1}>
                    {create ?
                        <CollectionNotificationForm closeCallback={() => setCreate(false)} />
                        :
                        <Button
                            variant="contained"
                            color="terciary"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => setCreate(true)}
                        >
                            Nové upozornění
                        </Button>
                    }
                </Box>
            }
            {data.records_count === 0 ?
                <Box>
                    Zatím nebyla vydána žádná upozornění
                </Box>
            :
                <>
                    {data.collection_notification.map(notification => (
                        <CollectionNotificationCard
                            key={notification.id}
                            collectionNotification={notification}
                            editable={editable}
                        />
                    ))}
                    <PaginationControls
                        pagination={pagination}
                        setPagination={setPagination}
                        currentArrayLength={data.collection_notification.length}
                        recordsCount={data.records_count}
                    />
                </>

            }
        </Box>
    )
}

export default CollectionNotificationList
