import { useState, type ChangeEvent } from 'react'
import dayjs from "dayjs"
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/cs'
import {
    ComposedChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Line,
    ReferenceLine,
} from 'recharts'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import type { MonthlyMunicipalityStats } from '@/types'
import { COMPARISON_COLORS, WASTE_COLORS } from '@/constants/general'

export default function MonthlyShareChart({
    data,
    comparisonData,
    noHeading,
    comparisonMunicipality,
}: {
    data: Array<MonthlyMunicipalityStats>,
    comparisonData: Array<MonthlyMunicipalityStats> | null,
    noHeading?: boolean,
    comparisonMunicipality?: string,
}) {

    const [show, setShow] = useState({
        target: true,
        comparison: true,
    })

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShow({
          ...show,
          [event.target.name]: event.target.checked,
        })
    }

    const { target, comparison } = show

    const dataWithDate = data.map(entry => {
        const comparisonEntry = comparisonData.find(
            comparisonEntry =>
                comparisonEntry.month === entry.month &&
                comparisonEntry.year === (comparisonMunicipality ? entry.year : entry.year - 1)
        )
        return {
            date: dayjs(new Date(entry.year, entry.month - 1, 1))
                .locale('cs')
                .format('MMM YYYY'),
            percentageMixed: entry.mixed_weight / (entry.all_weight / 100),
            percentageRecycling: (entry.all_weight - entry.mixed_weight) / (entry.all_weight / 100),
            comparisonPercentage: comparisonEntry
                ? comparisonEntry.mixed_weight / (comparisonEntry.all_weight / 100)
                : null,
            ...entry,
        }
    })

    return (
        <>
            {!noHeading &&
                <Box pt={10} pb={3}>
                    <Typography variant="h3" component="h3">
                        Trend - poměr odpadu v obci
                    </Typography>
                </Box>
            }
            <Box display="flex" rowGap={1.5} columnGap={3} flexWrap="wrap" mb={2} pl={2}>
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Zobrazit</FormLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox checked={target} onChange={handleShowChange} name="target" />
                            }
                            label="Cíl"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={comparison} onChange={handleShowChange} name="comparison" />
                            }
                            label="Srovnání"
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <Box height={530} width="100%">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={500}
                        height={300}
                        data={dataWithDate}
                        margin={{
                            top: 20,
                            right: 10,
                            left: 10,
                            bottom: 15,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" dy={10}/>
                        <YAxis tickFormatter={(tick) => tick+'%'}/>
                        <Tooltip formatter={value => (value as number).toFixed(2)+' %'}/>
                        <Area
                            type="monotone"
                            dataKey="percentageMixed"
                            stackId="a"
                            stroke={WASTE_COLORS.municipal.dark}
                            fill={WASTE_COLORS.municipal.main}
                            name="Směsný (SKO)"
                        />
                        <Area
                            type="monotone"
                            dataKey="percentageRecycling"
                            stackId="a"
                            stroke={WASTE_COLORS.recycling.dark}
                            fill={WASTE_COLORS.recycling.main}
                            name="Tříděný"
                        />
                        {comparison &&
                            <Line
                                type="monotone"
                                dataKey="comparisonPercentage"
                                fill={COMPARISON_COLORS.general.dark}
                                stroke={COMPARISON_COLORS.general.main}
                                strokeWidth={2}
                                name={comparisonMunicipality ? comparisonMunicipality : 'Předchozí rok'}
                                dot={false}
                            />
                        }
                        {target &&
                            <ReferenceLine y={40} stroke="red" strokeDasharray="5, 5" strokeWidth={2}/>
                        }
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2} px={2} justifyContent="center">
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.municipal.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Směsný (SKO)
                    </Typography>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.recycling.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                    Tříděný
                    </Typography>
                </Box>
                {target &&
                    <Box display="flex" gap={1} alignItems="center">
                        <Box sx={{
                            width: 14,
                            height: 14,
                            backgroundColor: '#ff0000',
                            borderRadius: 7,
                        }}/>
                        <Typography variant="body2" component="span">
                            Cíl 40%
                        </Typography>
                    </Box>
                }
                {comparison &&
                    <Box display="flex" gap={1} alignItems="center">
                        <Box sx={{
                            width: 14,
                            height: 14,
                            backgroundColor: COMPARISON_COLORS.general.main,
                            borderRadius: 7,
                        }}/>
                        <Typography variant="body2" component="span">
                            Srovnání s {comparisonMunicipality ? comparisonMunicipality : 'předchozím rokem.'}
                        </Typography>
                    </Box>
                }
            </Box>
        </>
    )
}
