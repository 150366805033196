import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import {
    type EkokomBonus,
    type EkokomBonusOtherFormInput,
    type EkokomBonusUpsertFormInput,
    EkokomBonusType,
    type FormMutationOptions,
    type MunicipalitySize,
} from '@/types'
import { convertToCents, isErrorResponse } from '@/helpers'

export function useMunicipalitySizes() {
    return useQuery({
        queryKey: ['municipalitySizes'],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalitySize> } = await axios.get(
                '/api/municipality_size/list',
            )
            return data
        },
    })
}

export function useEkokomBonuses(
    bonus_type: EkokomBonusType,
    year: number,
) {
    return useQuery({
        queryKey: [
            'ekokomBonuses',
            bonus_type,
            year,
        ],
        queryFn: async () => {
            const { data } : { data: Array<EkokomBonus>} = await axios.get(
                `/api/ekokom_bonus/list?bonus_type=${bonus_type}&year=${year}`
            )
            return data
        },
    })
}

export function useAllEkokomBonuses() {
    return useQuery({
        queryKey: [
            'allEkokomBonuses',
        ],
        queryFn: async () => {
            const { data } : { data: Array<EkokomBonus>} = await axios.get(
                '/api/ekokom_bonus/list'
            )
            return data
        },
    })
}

export const useUpsertEkokomBonus = (municipalitySizes: Array<MunicipalitySize>, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: EkokomBonusUpsertFormInput) => {
            const params = municipalitySizes.map(size => ({
                "municipality_size_id": size.id,
                "bonus_type": data.bonusType,
                "year": data.year,
                "paper_cents": convertToCents(data[`paper${size.id}`]).toString(),
                "plastic_cents": convertToCents(data[`plastic${size.id}`]).toString(),
                "glass_mixed_cents": convertToCents(data[`glass_mixed${size.id}`]).toString(),
                "glass_clear_cents": convertToCents(data[`glass_clear${size.id}`]).toString(),
                "liquid_paperboard_single_cents": convertToCents(data[`liquid_paperboard_single${size.id}`]).toString(),
                "liquid_paperboard_combined_cents": convertToCents(data[`liquid_paperboard_combined${size.id}`]).toString(),
                "metal_single_cents": convertToCents(data[`metal_single${size.id}`]).toString(),
                "metal_combined_cents": convertToCents(data[`metal_combined${size.id}`]).toString(),
                "wood_cents": convertToCents(data[`wood${size.id}`]).toString(),
            }))

            const queryParam = encodeURIComponent(JSON.stringify(params))
            
            return axios.post(`/api/ekokom_bonus/upsert?bonuses=${queryParam}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['ekokomBonuses'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpsertEkokomBonusOther = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: EkokomBonusOtherFormInput) => {
            const params = [{
                "municipality_size_id": null,
                "bonus_type": data.bonusType,
                "year": data.year,
                "paper_cents": convertToCents(data.paper),
                "plastic_cents": convertToCents(data.plastic),
                "glass_mixed_cents": convertToCents(data.glass_mixed),
                "glass_clear_cents": convertToCents(data.glass_clear),
                "liquid_paperboard_single_cents": convertToCents(data.liquid_paperboard_single),
                "liquid_paperboard_combined_cents": convertToCents(data.liquid_paperboard_combined),
                "metal_single_cents": convertToCents(data.metal_single),
                "metal_combined_cents": convertToCents(data.metal_combined),
                "wood_cents": convertToCents(data.wood),
            }]

            const queryParam = encodeURIComponent(JSON.stringify(params))
            
            return axios.post(`/api/ekokom_bonus/upsert?bonuses=${queryParam}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['ekokomBonuses', EkokomBonusType.other] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}
