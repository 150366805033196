import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import type {
    FormMutationOptions,
    WasteFeeFormInput,
    WasteFee,
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"
import { isErrorResponse } from '@/helpers'

export function useWasteFees(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['wasteFees', municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<WasteFee>} = await axios.get(
                `/api/waste_fee/list?municipality_id=${municipalityId}`
            )
            return data
        },
    })
}

export function useWasteFee(wasteFeeId) {
    return useQuery({
        queryKey: ['wasteFee', wasteFeeId],
        queryFn: async () => {
            const { data } : { data: WasteFee } = await axios.get(
                '/api/waste_fee/single?id=' + wasteFeeId,
            )
            return data
        },
    })
}

export const useCreateWasteFee = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: WasteFeeFormInput) => {            
            return axios.post(`/api/waste_fee/create?municipality_id=${municipalityId
                }&year=${data.year}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['wasteFees'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpdateWasteFee = (wasteFeeId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: WasteFeeFormInput) => {
            return axios.put(`/api/waste_fee/update?id=${wasteFeeId 
                }&year=${data.year}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['wasteFees'] })
            queryClient.invalidateQueries({ queryKey: ['wasteFee-' + wasteFeeId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useDeleteWasteFee = (wasteFeeId, municipalityId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/waste_fee/delete?id=${wasteFeeId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wasteFees', municipalityId] })
        },
    })
}

