
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function ForcedDiscountTarget({
    amountCents,
}: {
    amountCents: number,
}) {

    return (
        <Box sx={theme => ({
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        })}>
            <Typography variant="h5" component="h5">
                Sleva za třídění
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories.first,
                })}>
                    Sleva
                </Typography>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories.first,
                })}>
                    {amountCents/100} Kč
                </Typography>
            </Box>
            <Typography variant="body2" component="p">
                {`Z rozhodnutí obce je Vám přiznána sleva ve výši ${amountCents/100} Kč.`}
            </Typography>
        </Box>
    )
}
