import { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import {
    EkokomBonusType,
    type MunicipalitySize,
} from '@/types'
import { convertFromCents, printPrice } from '@/helpers'
import { useEkokomBonuses } from '@/hooks/api/use-ekokom-bonus'
import EkokomBonusForm from '../forms/ekokom-bonus'
import EkokomBonusOtherForm from '../forms/ekokom-bonus-other'

const BONUS_LABEL_MAP = {
    [EkokomBonusType.basic]: 'Základní',
    [EkokomBonusType.collection_yard]: 'Sběrné dvory',
    [EkokomBonusType.other]: 'Ostatní',
}

function EkokomBonusList({
    year,
    bonusType,
    municipalitySizes,
}: {
    year: number,
    bonusType: EkokomBonusType,
    municipalitySizes: Array<MunicipalitySize>,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const { status, data, error, isPlaceholderData } = useEkokomBonuses(bonusType, year)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst EKOKOM bonusy typu ${bonusType} pro rok ${year}`}
        />
    }

    const sortedData = data.sort((a, b) => a.id - b.id)

    return (
        <Box px={2.5}>
            {edit ?
                bonusType === EkokomBonusType.other ?
                    <EkokomBonusOtherForm
                        year={year}
                        bonuses={sortedData}
                        closeCallback={() => setEdit(false)}
                    />
                    :
                    <EkokomBonusForm
                        year={year}
                        bonusType={bonusType}
                        bonuses={sortedData}
                        municipalitySizes={municipalitySizes}
                        closeCallback={() => setEdit(false)}
                    />
                :
                <>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        mb: 1,
                    }}>
                        <Typography variant="h4" component="h2" flexGrow={1}>
                            {BONUS_LABEL_MAP[bonusType]}
                        </Typography>
                        <Box>
                            <Tooltip title="Upravit">
                                <IconButton
                                    color='terciary'
                                    onClick={() => setEdit(true)}
                                >
                                    <EditRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    {sortedData.length === 0 ?
                        <Box px={2.5}>
                            Zatím nejsou vyplněné žádné EKOKOM bonusy.
                        </Box>
                    :
                        <Box sx={(theme) => ({
                            borderRadius: 2.5,
                            border: `1px solid ${theme.palette.divider}`,
                            overflowX: 'auto',
                        })}>
                            <Table
                                aria-label="Ekokom bonusy"
                                sx={{
                                    minWidth: 600,
                                    '& td, & th': {
                                        paddingY: 1.5,
                                    },
                                }}
                                size='small'
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: 3 }}>Velikost obce</TableCell>
                                        <TableCell>Papír</TableCell>
                                        <TableCell>Plast</TableCell>
                                        <TableCell>Směsné sklo</TableCell>
                                        <TableCell>Čiré sklo</TableCell>
                                        <TableCell>Nápoj. kartony (samostatné)</TableCell>
                                        <TableCell>Nápoj. kartony (směs)</TableCell>
                                        <TableCell>Kov (samostatné)</TableCell>
                                        <TableCell>Kov (směs)</TableCell>
                                        <TableCell>Dřevo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData.map(row => {
                                        const municipalitySize = municipalitySizes.find(size => size.id === Number(row.municipality_size_id))
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                                    {municipalitySize ? `${municipalitySize?.citizens_count_gte} - ${municipalitySize?.citizens_count_lt}` : "Velikost neurčena"}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.paper_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.plastic_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.glass_mixed_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.glass_clear_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.liquid_paperboard_single_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.liquid_paperboard_combined_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.metal_single_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.metal_combined_cents))}
                                                </TableCell>
                                                <TableCell>
                                                    {printPrice(convertFromCents(row.wood_cents))}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    }
                </>
            }
            <Box minHeight={4}>
                {isPlaceholderData &&
                    <LinearProgress />
                }
            </Box>
        </Box>
    )
}

export default EkokomBonusList
