import { useForm, Controller, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, YEARS_FROM_START_TO_NEXT } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFRichText from "@/components/form-fields/rhf-rich-text"
import type {
    WasteFee,
    WasteFeeFormInput,
} from "@/types"
import {
    useCreateWasteFee,
    useUpdateWasteFee,
} from "@/hooks/api/use-waste-fee"

const WasteFeeForm = ({
    wasteFee,
    closeCallback,
} : {
    wasteFee?: WasteFee,
    closeCallback?: () => void,
}) => {

    const formDataId = `wasteFeeForm-${wasteFee?.id}-v${APP_VERSION}`

    const initialValues = {
        year: wasteFee ? wasteFee.year : '',
        description: wasteFee ? wasteFee.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateWasteFee({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
        onErrorCallback(errorMessage) {
            setError(errorMessage)
        }
    })

    const mutationUpdate = useUpdateWasteFee(
        wasteFee?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<WasteFeeFormInput> = data => {
        setRequestInProgress(true)
        if (wasteFee?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    const description = watch('description')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={wasteFee ? 'Upravit obsah' : 'Vytvořit nové období'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={wasteFee ? 'Upravit' : 'Vytvořit období'}
            error={error}
            setError={setError}
            disableSave={!description}
        >
            <FormControl fullWidth>
                <InputLabel id="year-select-label">Rok</InputLabel>
                <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Rok">
                            {YEARS_FROM_START_TO_NEXT.map(year => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
            <RHFRichText
                name="description"
                control={control}
                label="Obsah *"
                multipleRows
            />
            {!wasteFee &&
                <Typography variant="body1" component="p" gutterBottom>
                    Soubory ke stažení budete moct přidat po vytvoření.
                </Typography>
            }
        </Form>
    )
}

export default WasteFeeForm
