import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type {
    FormMutationOptions,
    IllegalDumpReportFormInputWithFiles,
    IllegalDumpsResponse,
    IllegalDump,
    Pagination,
    IllegalDumpPublicList,
    IllegalDumpPublic,
    IllegalDumpFormInputWithFiles,
    IllegalDumpListFilters,
    IllegalDumpStateChangeForm,
    MutationOptions,
} from '@/types'
import { isErrorResponse } from '@/helpers'

export function useIllegalDumps(
    municipalityId: number,
    pagination: Pagination,
    filters: IllegalDumpListFilters,
) {
    return useQuery({
        queryKey: [
            'illegalDumps',
            municipalityId,
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            `sort_by-${filters.sort_by}`,
            `sort_direction-${filters.sort_direction}`,
            `state-${filters.state}`,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            queryParams.append('sort_by', filters.sort_by)
            queryParams.append('sort_direction', filters.sort_direction)
            if (filters.state) queryParams.append('state', filters.state)
            
            const { data } : { data: IllegalDumpsResponse } = await axios.get(
                `/api/illegal_dump/list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export function usePublicIllegalDumps(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['publicIllegalDumps', municipalityId,],
        queryFn: async () => {
            const { data } : { data: Array<IllegalDumpPublicList> } = await axios.get(
                `/api/illegal_dump/public_list?municipality_id=${municipalityId}`
            )
            return data
        },
        enabled: !!municipalityId,
    })
}

export function useIllegalDump(illegalDumpId: number) {
    return useQuery({
        queryKey: ['illegalDump', illegalDumpId],
        queryFn: async () => {
            const { data } : { data: IllegalDump } = await axios.get(
                '/api/illegal_dump/single?id=' + illegalDumpId,
            )
            return data
        },
    })
}

export function usePublicIllegalDump(illegalDumpId: number) {
    return useQuery({
        queryKey: ['publicIllegalDump', illegalDumpId],
        queryFn: async () => {
            const { data } : { data: IllegalDumpPublic } = await axios.get(
                '/api/illegal_dump/public_single?id=' + illegalDumpId,
            )
            return data
        },
    })
}

export const useSendIllegalDump = (municipalityId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: IllegalDumpFormInputWithFiles) => {            
            return axios.post(`/api/illegal_dump/send_report?municipality_id=${municipalityId
                }&address=${data.address
                }&latitude=${data.position?.lat
                }&longitude=${data.position?.lng
                }&description=${data.description
                }&sender_email=${data.sender_email
                }`,
                data.files
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDumps', municipalityId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useSendIllegalDumpReport = (illegalDumpId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: IllegalDumpReportFormInputWithFiles) => {            
            return axios.post(`/api/illegal_dumping_report/send_report?illegal_dump_id=${illegalDumpId
                }&description=${data.description
                }&sender_email=${data.sender_email
                }`,
                data.files
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDump', illegalDumpId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDump', illegalDumpId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

// export const useDeleteIllegalDumpReport = (illegalDumpReportId, municipalityId) => {
//     const queryClient = useQueryClient()

//     return useMutation({
//         mutationFn: () => {
//             return axios.delete(`/api/illegal_dumping_report/delete?id=${illegalDumpReportId}`)
//         },
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
//         },
//     })
// }

export const useInProgressIllegalDump = (
    illegalDumpId: number,
    municipalityId: number,
    options: FormMutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: IllegalDumpStateChangeForm) => {
            const queryParams = new URLSearchParams()
            queryParams.append('id', illegalDumpId.toString())
            if (data.description) queryParams.append('description', data.description)

            return axios.post(`/api/illegal_dump/in_progress?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['illegalDump', illegalDumpId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDump', illegalDumpId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
    })
}

export const useResolvedIllegalDump = (
    illegalDumpId: number,
    municipalityId: number,
    options: FormMutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: IllegalDumpStateChangeForm) => {
            const queryParams = new URLSearchParams()
            queryParams.append('id', illegalDumpId.toString())
            if (data.description) queryParams.append('description', data.description)
            
            return axios.post(`/api/illegal_dump/resolved?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['illegalDump', illegalDumpId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDump', illegalDumpId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
    })
}

export const useArchiveIllegalDump = (
    illegalDumpId: number,
    municipalityId: number,
    options: MutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/illegal_dump/to_archive?id=${illegalDumpId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['illegalDump', illegalDumpId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDump', illegalDumpId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useArchiveIllegalDumpingReport = (
    illegalDumpingReportId: number,
    illegalDumpId: number,
    municipalityId: number,
    options: MutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/illegal_dumping_report/archive?id=${illegalDumpingReportId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['illegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDumps', municipalityId] })
            queryClient.invalidateQueries({ queryKey: ['illegalDump', illegalDumpId] })
            queryClient.invalidateQueries({ queryKey: ['publicIllegalDump', illegalDumpId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
