import { createTheme, type SimplePaletteColorOptions } from '@mui/material/styles'
import { csCZ as pickersCsCZ } from '@mui/x-date-pickers/locales'

interface CategoryColors {
    first: string
    second: string
    third: string
    fourth: string
    fifth: string
    unknown: string
}

interface GreyColors {
    tableText: string
    secondaryText: string
    muteText: string
    cardBorder: string
    selectorIcon: string
    boxBackground: string
    boxBackgroundLight: string
    divider: string
}

interface StateColors {
    reported: string
    inProgress: string
    resolved: string
}

interface MessageColors {
    user: string
    authority: string
}

interface ReportColors {
    background: string
    icon: string
}

interface ExtendedPaletteColor extends SimplePaletteColorOptions {
    darker: string
}

declare module '@mui/material/styles' {
    interface Palette {
        terciary: Palette['primary']
        customLightGreen: Palette['primary']
        customLightYellow: Palette['primary']
        errorRedLight: Palette['primary']
        errorBlueLight: Palette['primary']
        errorOrangeLight: Palette['primary']
        categories: CategoryColors
        customGrey: GreyColors
        editableOrange: Palette['primary']
        state: StateColors
        messages: MessageColors
        reports: ReportColors
    }

    interface PaletteOptions {
        terciary?: PaletteOptions['primary']
        customLightGreen?: ExtendedPaletteColor
        customLightYellow?: PaletteOptions['primary']
        errorRedLight?: PaletteOptions['primary']
        errorBlueLight?: PaletteOptions['primary']
        errorOrangeLight?: PaletteOptions['primary']
        categories: CategoryColors
        customGrey: GreyColors
        editableOrange?: PaletteOptions['primary']
        state: StateColors
        messages: MessageColors
        reports: ReportColors
    }
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#63B64D',
            main: '#368C36',
        },
        secondary: {
            main: '#393E42',
        },
        terciary: {
            light: '#212121',
            main: '#212121',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        customLightGreen: {
            light: '#E1F1DE',
            main: '#E0EFD6',
            dark: '#C4E5BE',
            darker: '#B5DDAE',
        },
        customLightYellow: {
            light: '#FFFBE6',
            main: '#FFE38D',
            dark: '#FFCA28',
        },
        errorRedLight: {
            light: '#ffe5e7',
            main: '#ffd8dc',
            dark: '#ffcacf',
        },
        errorBlueLight: {
            light: '#daf5ff',
            main: '#d2f3ff',
            dark: '#c6efff',
        },
        errorOrangeLight: {
            light: '#ffefd8',
            main: '#ffeacd',
            dark: '#ffe5c1',
        },
        categories: {
            first: '#22ba40',
            second: '#b5cc00',
            third: '#ffb900',
            fourth: '#ec7216',
            fifth: '#c80000',
            unknown: '#5f5f5f',
        },
        customGrey: {
            tableText: '#5F5F5F',
            secondaryText: '#818181',
            muteText: '#A1A1A1',
            cardBorder: '#DEDEDE',
            selectorIcon: '#BCBCBC',
            boxBackground: '#E8E8E8',
            boxBackgroundLight: '#F8F8F8',
            divider: '#E8E8E8',
        },
        editableOrange: {
            main: '#FCF7EF',
        },
        state: {
            reported: '#EF9A9A',
            inProgress: '#EBBE56',
            resolved: '#A0CC41',
        },
        messages: {
            user: '#eefaee',
            authority: '#e5efff',
        },
        reports: {
            background: '#FCEFE8',
            icon: '#714F47',
        }
        // customGrey: {
        //     main: '#F0F0F0',
        //     light: '#F5F5F5',
        //     dark: '#D0D0D0',
        // },
        // customGreen: {
        //     main: '#DFF2E1',
        //     light: '#F5FDF6',
        //     dark: '#7FC387',
        // },
        // customBeige: {
        //     main: '#FFECDB',
        //     light: '#FFF6EF',
        //     dark: '#F7D1B0',
        // },
        // customBlue: {
        //     main: '#E2F7FF',
        //     light: '#F2FBFF',
        //     dark: '#BBE0EF',
        // },
        // customYellow: {
        //     main: '#FFEC88',
        //     light: '#FFF7CC',
        //     dark: '#FFD700',
        // }
    },
    typography: {
        h1: {
            fontSize: 28,
            fontWeight: 500,
        },
        h2: {
            fontSize: 24,
            fontWeight: 500,
        },
        h3: {
            fontSize: 20,
            fontWeight: 500,
        },
        h4: {
            fontSize: 18,
            fontWeight: 500,
        },
        h5: {
            fontSize: 16,
            fontWeight: 500,
        },
        h6: {
            fontSize: 15,
            fontWeight: 500,
        },
        // subtitle1: {
        //     fontSize: 15,
        // },
        // subtitle2: {
        //     fontSize: 13,
        // },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 15,
        },
        // button: {
        //     fontSize: 14,
        // },
        // caption: {
        //     fontSize: 12,
        // },
        // overline: {
        //     fontSize: 10,
        // },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    textTransform: 'none',
                    ...(ownerState.variant === 'contained' && {
                        boxShadow: 'none',
                        borderRadius: '3rem',
                        paddingLeft: '1.2rem',
                        paddingRight: '1.2rem',
                    }),
                    ...(ownerState.variant === 'text' && {
                        borderRadius: '3rem',
                        paddingLeft: '1.2rem',
                        paddingRight: '1.2rem',
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        borderRadius: '3rem',
                        paddingLeft: '1.2rem',
                        paddingRight: '1.2rem',
                    }),
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    flexGrow: 1,
                },
            },
        },
        MuiIcon: {
            defaultProps: {
                // Replace the `material-icons` default value
                baseClassName: 'material-icons-round',
            },
        },
    },
}, pickersCsCZ)

export default theme
