import type { ReactNode } from 'react'
import { type ElementType, type KeyboardEvent, forwardRef } from "react"
import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import TextField, { type BaseTextFieldProps } from "@mui/material/TextField"
import InputAdornment from '@mui/material/InputAdornment'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'
import type { InputBaseComponentProps } from "@mui/material"

interface RHFAutocompleteProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    size?: BaseTextFieldProps["size"],
    required?: BaseTextFieldProps["required"],
    disabled?: BaseTextFieldProps["disabled"],
    decimalScale?: number,
    thousandSeparator?: boolean,
    disallowNegative?: boolean,
    endAdornment?: ReactNode,
    alignRight?: boolean,
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    decimalScale?: number,
    thousandSeparator?: boolean,
    disallowNegative?: boolean,
  }

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, decimalScale = 0, thousandSeparator, disallowNegative, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator={thousandSeparator ? ' ' : false}
          valueIsNumericString
          allowedDecimalSeparators={['.', ',']}
          decimalScale={decimalScale}
          allowNegative={!disallowNegative}
        />
      );
    },
  );

const RHFNumberTextfield = <
    TField extends FieldValues,
>(
    props: RHFAutocompleteProps<TField>
) => {

    const {
        control,
        name,
        label,
        size,
        required,
        disabled,
        thousandSeparator,
        decimalScale,
        disallowNegative,
        endAdornment,
        alignRight,
    } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <TextField
                        id="rhf-textfield"
                        label={label}
                        size={size}
                        required={required}
                        disabled={disabled}
                        autoComplete="off"
                        onKeyDown={
                            (e:KeyboardEvent<HTMLInputElement>) => {
                                if (!(e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }
                        }
                        InputProps={{
                            inputComponent: NumericFormatCustom as unknown as ElementType<InputBaseComponentProps>,
                            endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
                        }}
                        inputProps={{
                            thousandSeparator,
                            decimalScale,
                            disallowNegative,
                        }}
                        {...field}
                        sx={alignRight ? { input: { textAlign: 'right' } } : null}
                    />
                )
            }}
        />
    )
}

export default RHFNumberTextfield
