
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import type { CollectionPointDetail } from '@/types'
import { useCollectionPointMixedWeightCategory } from '@/hooks/api/use-collection-point'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import type { AxiosError } from 'axios'

const getPercentageColor = (percentage: number): 'first' | 'second' | 'third' | 'fourth' | 'fifth' => {
    if (percentage <= 20) return 'first'
    if (percentage <= 40) return 'second'
    if (percentage <= 60) return 'third'
    if (percentage <= 80) return 'fourth'
    return 'fifth'
}

const getCategoryColor = (category: number): 'first' | 'second' | 'third' | 'fourth' | 'fifth' => {
    if (category <= 1) return 'first'
    if (category <= 2) return 'second'
    if (category <= 3) return 'third'
    if (category <= 4) return 'fourth'
    return 'fifth'
}

const getPercentage = (category: number, value: number, lt: number, gte: number): number => {
    const percentage = (category*20)-20+(((value - gte) / (lt - gte)) * 100/5)
    if (percentage < 0) return 0
    if (percentage > 100) return 100
    return percentage
}

export default function MixedWeightDiscountTarget({
    collectionPointDetail,
}: {
    collectionPointDetail: CollectionPointDetail,
}) {

    const { status, data, error } = useCollectionPointMixedWeightCategory(collectionPointDetail.id)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error && ((error as AxiosError).response?.data as { error : string })?.error) {
        return (
            <Alert
                severity="error"
            >
                Slevový cíl týkající se třídění není k dispozici: {((error as AxiosError).response?.data as { error : string })?.error}
            </Alert>
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst slevu za třídění svozového místa s ID ${collectionPointDetail.id}`}
        />
    }

    const sortingPercentage = getPercentage(
        data.current_level,
        data.current_mixed_weight,
        data.current_level === 5 && data.current_category_lt > data.current_category_gte+200 ? data.current_category_gte+200 : data.current_category_lt,
        data.current_category_gte < 0 ? 0 : data.current_category_gte,
    )

    return (
        <Box sx={theme => ({
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        })}>
            <Typography variant="h5" component="h5">
                Sleva za třídění
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories[getCategoryColor(data.current_level)],
                })}>
                    Kategorie {data.current_level}
                </Typography>
                <Typography variant="h3" component="div" sx={theme => ({
                    color: theme.palette.categories[getCategoryColor(data.current_level)],
                })}>
                    {data.current_discount_cents/100} Kč
                </Typography>
            </Box>
            <Typography variant="body2" component="p">
                {data.current_level === 1 ? 
                    'Gratulujeme! Jste v nejvyšší kategorii.'
                :
                    `Pro postup do vyšší kategorie (${data.better_category_level}) 
                    se slevou ${data.better_category_discount_cents/100} Kč vytřiďte 
                    o ${Math.ceil(data.weight_difference_monthly*data.number_of_members)} kg 
                    odpadu měsíčně více.`
                }
            </Typography>
            <Box sx={{
                position: 'relative',
                pt: 3.5,
                pb: 2,
            }}>
                <Box sx={theme => ({
                    width: '100%',
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'primary.main',
                    background: `linear-gradient(90deg, ${
                        theme.palette.categories.first} 20%, ${
                        theme.palette.categories.second} 20% 40%, ${
                        theme.palette.categories.third} 40% 60%, ${
                        theme.palette.categories.fourth} 60% 80%, ${
                        theme.palette.categories.fifth} 80%)`,
                })}/>
                <Box sx={{
                    width: 35,
                    position: 'absolute',
                    top: 1,
                    left: `calc(${sortingPercentage}% - 17.5px)`,
                }}>
                    <ArrowDropDownRoundedIcon fontSize="large" sx={theme => ({
                        color: theme.palette.categories[getPercentageColor(sortingPercentage)],
                    })}/>
                    <Box sx={{
                        width: 3,
                        height: 12,
                        borderRadius: 4,
                        backgroundColor: 'terciary.light',
                        opacity: 0.8,
                        mx: 'auto',
                        mt: -2,
                    }}/>
                </Box>
            </Box>
        </Box>
    )
}
