import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
    keepPreviousData,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import {
    type FormMutationOptions,
    type MotivationModel,
    type MotivationModelListItem,
    type CitizenCategoryFormInput,
    type MotivationModelCalculate,
    type MonthYear,
    type ScenarioTypes,
    ScenarioImprovementRatios,
    type MotivationModelCollectionFormInput,
    type MotivationModelNameFormInput,
    type MotivationModelAccountingFormInput,
    type MotivationModelDuplicateFormInput,
    type MutationOptions,
} from '@/types'
import { convertToCents } from '@/helpers'
import { selectedMunicipalityIdAtom } from "@/state"

export function useMotivationModels(municipalityId, year) {
    return useQuery({
        queryKey: ['motivationModels', municipalityId, year],
        queryFn: async () => {
            const { data } : { data: Array<MotivationModelListItem> } = await axios.get(
                `/api/motivation_model/list?municipality_id=${municipalityId
                    }&year=${year
                    }&archived=false`,
            )
            return data
        },
    })
}

export function useMotivationModel(motivationModelId) {
    return useQuery({
        queryKey: ['motivationModel-' + motivationModelId],
        queryFn: async () => {
            const { data } : { data: MotivationModel } = await axios.get(
                '/api/motivation_model/single?id=' + motivationModelId,
            )
            return data
        },
    })
}

export function useActiveMotivationModel(year) {
    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    return useQuery({
        queryKey: ['activeMotivationModel', year, municipalityId],
        queryFn: async () => {
            const { data } : { data: MotivationModel } = await axios.get(
                `/api/motivation_model/active_for_year?municipality_id=${municipalityId
                    }&year=${year}`,
            )
            return data
        },
    })
}

export const useCreateMotivationModel = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelNameFormInput) => {            
            return axios.post(`/api/motivation_model/create?name=${data.name
                }&year=${data.year
                }&municipality_id=${municipalityId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateMotivationModelName = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelNameFormInput) => {
            return axios.put(`/api/motivation_model/update?id=${motivationModelId
                }&year=${data.year
                }&name=${data.name}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            queryClient.removeQueries({ queryKey: ['calculateMotivationModel', motivationModelId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateMotivationModelCollection = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelCollectionFormInput) => {
            return axios.put(`/api/motivation_model/update?id=${motivationModelId
                }&mixed_amount_cents=${convertToCents(data.mixed_amount)
                }&environment_payment_cents=${convertToCents(data.environment_payment)
                }&bio_amount_cents=${convertToCents(data.bio_amount)
                }&paper_amount_cents=${convertToCents(data.paper_amount)
                }&plastic_amount_cents=${convertToCents(data.plastic_amount)
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            queryClient.removeQueries({ queryKey: ['calculateMotivationModel', motivationModelId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateMotivationModelAccounting = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelAccountingFormInput) => {
            return axios.put(`/api/motivation_model/update?id=${motivationModelId
                }&collection_yard_operation_cents=${convertToCents(data.collection_yard_operation)
                }&waste_management_cents=${convertToCents(data.waste_management)
                }&collection_yard_income_cents=${convertToCents(data.collection_yard_income)
                }&other_income_cents=${convertToCents(data.other_income)
                }&nonpaying_citizens_count=${data.nonpaying_citizens_count
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            queryClient.removeQueries({ queryKey: ['calculateMotivationModel', motivationModelId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDuplicateMotivationModel = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelDuplicateFormInput) => {
            return axios.post(`/api/motivation_model/duplicate?motivation_model_id=${motivationModelId
                }&name=${data.name
                }&year=${data.year
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useActivateMotivationModel = (motivationModelId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.put(`/api/motivation_model/activate?id=${motivationModelId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.invalidateQueries({ queryKey: ['activeMotivationModel'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export function useCalculateMotivationModel(
    motivationModelId: number | string,
    year: number,
    startDate: MonthYear,
    endDate: MonthYear,
    scenario: ScenarioTypes,
    annual_surcharge?: number | null,
    citizen_fee?: number | null,
) {
    return useQuery({
        queryKey: [
            'calculateMotivationModel',
            motivationModelId,
            scenario,
            'annualSurcharge-'+annual_surcharge,
            'citizenFee-'+citizen_fee,
        ],
        queryFn: async () => {
            const { data } : { data: MotivationModelCalculate } = await axios.get(
                `/api/motivation_model/calculate?id=${motivationModelId
                    }&year=${year
                    }${annual_surcharge != null ? '&annual_surcharge_cents='+convertToCents(annual_surcharge) : ''
                    }${citizen_fee != null ? '&citizen_fee_cents='+convertToCents(citizen_fee) : ''
                    }&improvement_ratio=${ScenarioImprovementRatios[scenario]
                    }&selected_scenario=${scenario
                    }&begins_date=${startDate
                    }&ends_date=${endDate}`
            )
            return data
        },
        placeholderData: keepPreviousData,
        enabled: !!motivationModelId,
    })
}

export const useSetMotivationModelCategories = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CitizenCategoryFormInput) => {

            const params = [
                {
                    "level": 1,
                    "type": "CitizenCategory::MixedWeight",
                    "value_lt": data.limitLevel1Weight,
                    "discount_amount_cents": convertToCents(data.enableWeight ? data.discountLevel1Weight : 0)
                },
                {
                    "level": 2,
                    "type": "CitizenCategory::MixedWeight",
                    "value_gte": data.limitLevel1Weight,
                    "value_lt": data.limitLevel2Weight,
                    "discount_amount_cents": convertToCents(data.enableWeight ? data.discountLevel2Weight : 0)
                },
                {
                    "level": 3,
                    "type": "CitizenCategory::MixedWeight",
                    "value_gte": data.limitLevel2Weight,
                    "value_lt": data.limitLevel3Weight,
                    "discount_amount_cents": convertToCents(data.enableWeight ? data.discountLevel3Weight : 0)
                },
                {
                    "level": 4,
                    "type": "CitizenCategory::MixedWeight",
                    "value_gte": data.limitLevel3Weight,
                    "value_lt": data.limitLevel4Weight,
                    "discount_amount_cents": convertToCents(data.enableWeight ? data.discountLevel4Weight : 0)
                },
                {
                    "level": 5,
                    "type": "CitizenCategory::MixedWeight",
                    "value_gte": data.limitLevel4Weight,
                    "discount_amount_cents": convertToCents(data.enableWeight ? data.discountLevel5Weight : 0)
                },
                {
                    "level": 1,
                    "type": "CitizenCategory::MixedVolume",
                    "value_lt": data.limitLevel1Volume,
                    "discount_amount_cents": convertToCents(data.enableVolume ? data.discountLevel1Volume : 0)
                },
                {
                    "level": 2,
                    "type": "CitizenCategory::MixedVolume",
                    "value_gte": data.limitLevel1Volume,
                    "value_lt": data.limitLevel2Volume,
                    "discount_amount_cents": convertToCents(data.enableVolume ? data.discountLevel2Volume : 0)
                },
                {
                    "level": 3,
                    "type": "CitizenCategory::MixedVolume",
                    "value_gte": data.limitLevel2Volume,
                    "value_lt": data.limitLevel3Volume,
                    "discount_amount_cents": convertToCents(data.enableVolume ? data.discountLevel3Volume : 0)
                },
                {
                    "level": 4,
                    "type": "CitizenCategory::MixedVolume",
                    "value_gte": data.limitLevel3Volume,
                    "value_lt": data.limitLevel4Volume,
                    "discount_amount_cents": convertToCents(data.enableVolume ? data.discountLevel4Volume : 0)
                },
                {
                    "level": 5,
                    "type": "CitizenCategory::MixedVolume",
                    "value_gte": data.limitLevel4Volume,
                    "discount_amount_cents": convertToCents(data.enableVolume ? data.discountLevel5Volume : 0)
                },
            ]

            const queryParam = encodeURIComponent(JSON.stringify(params))
            
            return axios.post(`/api/motivation_model/set_categories?motivation_model_id=${motivationModelId
                }&mixed_weight_categories_disabled=${!data.enableWeight
                }&mixed_volume_categories_disabled=${!data.enableVolume
                }&categories=${queryParam}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            queryClient.removeQueries({ queryKey: ['calculateMotivationModel', motivationModelId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpsertBinDumpCosts = (motivationModelId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MotivationModelCollectionFormInput) => {

            const params = [
                {
                    "volume": 110,
                    "amount_cents": convertToCents(data.smallExtended ? data.small110 : data.smallAll),
                    "eoko_waste_type_id": null
                },
                {
                    "volume": 120,
                    "amount_cents": convertToCents(data.smallExtended ? data.small120 : data.smallAll),
                    "eoko_waste_type_id": null
                },
                {
                    "volume": 240,
                    "amount_cents": convertToCents(data.smallExtended ? data.small240 : data.smallAll),
                    "eoko_waste_type_id": null
                },
                {
                    "volume": 1100,
                    "amount_cents": convertToCents(data.big1100),
                    "eoko_waste_type_id": null
                },
                {
                    "volume": 1100,
                    "amount_cents": convertToCents(data.big1100Glass),
                    "eoko_waste_type_id": 6
                },
                {
                    "volume": 1100,
                    "amount_cents": convertToCents(data.big1100Glass),
                    "eoko_waste_type_id": 7
                },
                {
                    "volume": 1500,
                    "amount_cents": convertToCents(data.big1500GlassAndMetal),
                    "eoko_waste_type_id": 6
                },
                {
                    "volume": 1500,
                    "amount_cents": convertToCents(data.big1500GlassAndMetal),
                    "eoko_waste_type_id": 7
                },
                {
                    "volume": 1500,
                    "amount_cents": convertToCents(data.big1500GlassAndMetal),
                    "eoko_waste_type_id": 11
                }
            ]

            const queryParam = encodeURIComponent(JSON.stringify(params))

            return axios.post(`/api/bin_dump_costs/upsert?motivation_model_id=${motivationModelId
                }&bin_dump_costs=${queryParam}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            queryClient.removeQueries({ queryKey: ['calculateMotivationModel', motivationModelId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useArchiveMotivationModel = (motivationModelId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/motivation_model/archive?motivation_model_id=${motivationModelId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.invalidateQueries({ queryKey: ['activeMotivationModel'] })
            queryClient.removeQueries({ queryKey: ['motivationModel-' + motivationModelId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
