import type { MunicipalitySize } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useMunicipalitySizes } from '@/hooks/api/use-ekokom-bonus'

interface MunicipalitySizesLoaderProps {
    children: (data: Array<MunicipalitySize> ) => React.ReactNode
}

const MunicipalitySizesLoader: React.FC<MunicipalitySizesLoaderProps> = ({
    children,
}) => {
    const { status, data, error } = useMunicipalitySizes()

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst obce'}
        />
    }


    if (data.length > 0) {
        return <>{children(data)}</>
    }

    return null
}

export default MunicipalitySizesLoader
