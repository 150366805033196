import { useForm, type SubmitHandler } from "react-hook-form"

import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFPassword from "@/components/form-fields/rhf-password"
import type { User, UserPasswordFormInput } from "@/types"
import { useUpdateUserPassword } from "@/hooks/api/use-users"

const UserPasswordForm = ({
    user,
    closeCallback,
} : {
    user: User,
    closeCallback?: () => void,
}) => {

    const initialValues = {
        password: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(() => reset(initialValues))

    const mutationCreate = useUpdateUserPassword(
        user.id,
        {
            setRequestInProgress,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        }
    )

    const onSubmit: SubmitHandler<UserPasswordFormInput> = data => {
        setRequestInProgress(true)
        mutationCreate.mutate(data)
    }

    const filledPassword = watch('password')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Změnit heslo'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={false}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            error={error}
            setError={setError}
            disableSave={!filledPassword}
        >
            <RHFPassword
                name="password"
                control={control}
                label="Heslo"
                required
            />
        </Form>
    )
}

export default UserPasswordForm
