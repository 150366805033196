import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'
import type { MunicipalityScore } from '@/types'

export function useMunicipalityScores() {
    return useQuery({
        queryKey: [
            'municipalityScores',
        ],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityScore> } = await axios.get(
                '/api/municipality_score/list',
            )
            return data
        },
    })
}
