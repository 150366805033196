import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type { Dayjs } from "dayjs"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'

import type { AggregatedWasteData } from '@/types'
import { useEkokomStatsByRegion } from '@/hooks/api/use-ekokom-stat'
import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { getMidpointDate } from '@/helpers'
import {
    CZECH_REGIONS,
    CZECH_REGIONS_ARRAY,
    WASTE_COLORS,
} from '@/constants/general'

export default function RegionComparison({
    startDate,
    endDate,
    citizens_count,
    mixedWasteMunicipality,
    recyclingWasteMunicipality,
    municipalityName,
}: {
    startDate: Dayjs,
    endDate: Dayjs,
    citizens_count: number,
    mixedWasteMunicipality: AggregatedWasteData,
    recyclingWasteMunicipality: AggregatedWasteData,
    municipalityName: string | null,
}) {

    const [regionId, setRegionId] = useState<number>(CZECH_REGIONS.SOUTH_MORAVIA.id)

    const selectedRegion = CZECH_REGIONS_ARRAY.find(region => region.id === regionId)

    const handleChange = (event: SelectChangeEvent) => {
        setRegionId(Number.parseInt(event.target.value))
      }

    const { midpointDate, totalMonths } = getMidpointDate(startDate, endDate)

    // cap to last year
    const year = midpointDate.year() >= dayjs().year() ? dayjs().subtract(1, 'year').format('YYYY') : midpointDate.format('YYYY')
    
    const { status, data, error, isPlaceholderData } = useEkokomStatsByRegion(
        year,
        citizens_count,
        regionId,
    )

    const Heading = (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2,
            mb: 3,
        }}>
            <Box maxWidth={80}>
                <img
                    src="/cz-regions-faded-map.svg?v1"
                    loading="lazy"
                    alt="Mapa kraje CZ"
                    style={{
                        maxWidth: '100%',
                        width: '100%',
                    }}
                />
            </Box>
            <Typography variant="h3" component="h3">
                Srovnání s kraji
            </Typography>
            <Box display="flex" gap={2} alignItems="center">
                <FormControl sx={{minWidth: 250 }}>
                    <InputLabel id="region-select-label">Kraj</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        value={regionId.toString()}
                        label="Kraj"
                        onChange={handleChange}
                    >
                        {CZECH_REGIONS_ARRAY.map(region => (
                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {isPlaceholderData && <CircularProgress color="inherit" /> }
            </Box>
        </Box>
    )

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst Ekokom statistiky pro rok ${year}, ${selectedRegion.name}`}
        />
    }

    if (data.length === 0) {
        return <Box px={2.5} py={2}>
            {Heading}
            {`Statistika pro rok ${year} není k dispozici.`}
        </Box>
    }

    const selectedYear = data[0]

    const mixedWasteData: AggregatedWasteData = {
        name: 'Směsný (SKO)',
        tons: Number.parseFloat(selectedYear.mixed_weight_total) / 1000,
        perPerson: Number.parseFloat(selectedYear.mixed_weight_per_citizen),
        percentage:
            Number.parseFloat(selectedYear.mixed_weight_total) /
            ((Number.parseFloat(selectedYear.mixed_weight_total) +
                Number.parseFloat(selectedYear.sorted_weigth_total)) /
                100),
        color: WASTE_COLORS.municipal,
    }

    const recyclingWasteData: AggregatedWasteData = {
        name: 'Tříděný',
        tons: Number.parseFloat(selectedYear.sorted_weigth_total) / 1000,
        perPerson: Number.parseFloat(selectedYear.sorted_weigth_per_citizen),
        percentage:
            Number.parseFloat(selectedYear.sorted_weigth_total) /
            ((Number.parseFloat(selectedYear.mixed_weight_total) +
                Number.parseFloat(selectedYear.sorted_weigth_total)) /
                100),
        color: WASTE_COLORS.recycling,
    }

    const findMaxBoundary = (numbers: number[]): number => {
        const maxNumber = Math.max(...numbers)
      
        // Add 15 to ensure the boundary is at least 15 points higher
        const adjustedMax = maxNumber + 15
      
        // Calculate the nearest higher number divisible by 50
        const boundary = Math.ceil(adjustedMax / 50) * 50
      
        return boundary
    }

    const boundary = findMaxBoundary([
        mixedWasteData.perPerson,
        recyclingWasteData.perPerson,
        mixedWasteMunicipality.perPerson,
        recyclingWasteMunicipality.perPerson,
    ])

    return (
        <Box pt={4}>
            {Heading}
            <Typography variant="h4" component="h3" gutterBottom textAlign="center">
                Poměr odpadu
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2} px={2} pb={3} justifyContent="center">
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.municipal.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Směsný (SKO)
                    </Typography>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.recycling.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Tříděný
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                mb: 8,
                pl: {
                    xs: 0,
                    md: 2,
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: {
                        xs: 1,
                        sm: 2,
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    }
                }}>
                    <Box sx={{
                        width: {
                            xs: '100%',
                            sm: 90,
                            md: 120,
                        },
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h5" component="div">
                            {municipalityName ?? 'Vaše obec'}
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        opacity: 0.9,
                    }}>
                        <Box sx={{
                            width: mixedWasteMunicipality.percentage.toFixed(2)+'%',
                            height: 50,
                            backgroundColor: WASTE_COLORS.municipal.main,
                            position: 'relative',
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 14,
                                left: 'calc(50% - 26px)',
                            }}>
                                {mixedWasteMunicipality.percentage.toFixed(2)+'%'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            width: (100-Number(mixedWasteMunicipality.percentage.toFixed(2))).toFixed(2)+'%',
                            height: 50,
                            backgroundColor: WASTE_COLORS.recycling.main,
                            position: 'relative',
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 14,
                                left: 'calc(50% - 26px)',
                            }}>
                                {(100-Number(mixedWasteMunicipality.percentage.toFixed(2))).toFixed(2)+'%'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: {
                        xs: 1,
                        sm: 2,
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    }
                }}>
                    <Box sx={{
                        width: {
                            xs: '100%',
                            sm: 90,
                            md: 120,
                        },
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h5" component="div">
                            {selectedRegion.name}
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        opacity: 0.6,
                    }}>
                        <Box sx={{
                            width: mixedWasteData.percentage.toFixed(2)+'%',
                            height: 50,
                            backgroundColor: WASTE_COLORS.municipal.main,
                            position: 'relative',
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 14,
                                left: 'calc(50% - 26px)',
                            }}>
                                {mixedWasteData.percentage.toFixed(2)+'%'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            width: (100-Number(mixedWasteData.percentage.toFixed(2))).toFixed(2)+'%',
                            height: 50,
                            backgroundColor: WASTE_COLORS.recycling.main,
                            position: 'relative',
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 14,
                                left: 'calc(50% - 26px)',
                            }}>
                                {(100-Number(mixedWasteData.percentage.toFixed(2))).toFixed(2)+'%'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>


            <Typography variant="h4" component="h3" gutterBottom textAlign="center">
                Množství odpadu na občana
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2} px={2} pb={3} justifyContent="center">
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.municipal.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Směsný (SKO)
                    </Typography>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: WASTE_COLORS.recycling.main,
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Tříděný
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                mb: 4,
                pl: {
                    xs: 0,
                    md: 2,
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: {
                        xs: 1,
                        sm: 2,
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    }
                }}>
                    <Box sx={{
                        width: {
                            xs: '100%',
                            sm: 90,
                            md: 120,
                        },
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h5" component="div">
                            {municipalityName ?? 'Vaše obec'}
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        opacity: 0.9,
                    }}>
                        <Box sx={{
                            width: mixedWasteMunicipality.perPerson/(boundary/100)+'%',
                            height: 40,
                            backgroundColor: WASTE_COLORS.municipal.main,
                            position: 'relative',
                            borderRadius: 2.5,
                            mb: 1,
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 9,
                                left: 'calc(50% - 20px)',
                            }}>
                                {mixedWasteMunicipality.perPerson.toFixed(0)+' kg'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            width: recyclingWasteMunicipality.perPerson/(boundary/100)+'%',
                            height: 40,
                            backgroundColor: WASTE_COLORS.recycling.main,
                            position: 'relative',
                            borderRadius: 2.5,
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 9,
                                left: 'calc(50% - 20px)',
                            }}>
                                {recyclingWasteMunicipality.perPerson.toFixed(0)+' kg'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: {
                        xs: 1,
                        sm: 2,
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    }
                }}>
                    <Box sx={{
                        width: {
                            xs: '100%',
                            sm: 90,
                            md: 120,
                        },
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h5" component="div">
                            {selectedRegion.name}
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        opacity: 0.6,
                    }}>
                        <Box sx={{
                            width: mixedWasteData.perPerson/(boundary/100)+'%',
                            height: 40,
                            backgroundColor: WASTE_COLORS.municipal.main,
                            position: 'relative',
                            borderRadius: 2.5,
                            mb: 1,
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 9,
                                left: 'calc(50% - 20px)',
                            }}>
                                {mixedWasteData.perPerson.toFixed(0)+' kg'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            width: recyclingWasteData.perPerson/(boundary/100)+'%',
                            height: 40,
                            backgroundColor: WASTE_COLORS.recycling.main,
                            position: 'relative',
                            borderRadius: 2.5,
                        }}>
                            <Typography variant="h5" component="div" sx={{
                                position: 'absolute',
                                color: 'white',
                                top: 9,
                                left: 'calc(50% - 20px)',
                            }}>
                                {recyclingWasteData.perPerson.toFixed(0)+' kg'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2" component="p" sx={{
                        textAlign: 'center',
                        color: 'customGrey.secondaryText',
                        mb: 1,
                    }}>
                        Průměr pro {selectedRegion.name} vypočten proporcionálně z dat za celý rok {year}.
                    </Typography>
                    <Typography variant="body2" component="p" sx={{
                        textAlign: 'center',
                        color: 'customGrey.secondaryText',
                    }}>
                        Průměr pro {municipalityName} vypočten z Vámi zvolených dat ({totalMonths} měsíců).
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
