import React from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import dayjs from 'dayjs'

export default function FormChangedAlert({
    lastUpdateAt,
    lastUpdater,
}: {
    lastUpdateAt?: string,
    lastUpdater?: {
        familyName?: string | null | undefined,
        givenName?: string | null | undefined,
    } | null,
}) {

    return (
        <Alert
            severity="error"
            sx={(theme) => ({
                flexGrow: 1,
                margin: theme.spacing(1, 0),
            })}
            action={
                <Button
                    color="error"
                    size="small"
                    startIcon={<Icon>publish</Icon>}
                    type="submit"
                    sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: 'errorRedLight.main',
                        '&:hover': {
                            backgroundColor: 'errorRedLight.dark',
                        }
                    }}
                >
                    Přesto upravit
                </Button>
            }
        >
            Během editace došlo ke změně dat na serveru jiným uživatelem: 
            {' '+dayjs(lastUpdateAt).format('D.MM.YYYY HH:mm')}
            {lastUpdater && ' - '+lastUpdater?.givenName+' '+lastUpdater?.familyName}
        </Alert>
    )
}
