import {
    useEffect,
    useRef,
} from 'react'

// Custom hook to skip first run of useEffect
function useMounted() {
    const isMountedRef = useRef(false)
    useEffect(() => {
        isMountedRef.current = true
    }, [])
    return isMountedRef.current
}

export default useMounted
