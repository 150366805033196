import { useForm, type SubmitHandler } from "react-hook-form"
import Box from '@mui/material/Box'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import {
    type EkokomBonus,
    type EkokomBonusOtherFormInput,
    EkokomBonusType,
} from "@/types"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import { convertFromCents } from "@/helpers"
import { useUpsertEkokomBonusOther } from "@/hooks/api/use-ekokom-bonus"

const materialKeys = [
    "paper",
    "plastic",
    "glass_mixed",
    "glass_clear",
    "liquid_paperboard_single",
    "liquid_paperboard_combined",
    "metal_single",
    "metal_combined",
    "wood",
]

const EkokomBonusOtherForm = ({
    bonuses,
    closeCallback,
    year,
} : {
    bonuses?: Array<EkokomBonus>,
    closeCallback?: () => void,
    year: number
}) => {

    const bonus = bonuses.find(item => item.bonus_type === EkokomBonusType.other)

    const formDataId = `ekokomBonusOtherForm-${EkokomBonusType.other}-${year}-v${APP_VERSION}`

    const initialValues: EkokomBonusOtherFormInput = {
        year,
        bonusType: EkokomBonusType.other,
        paper: bonus ? bonus.paper_cents != null ? convertFromCents(bonus.paper_cents) : 0 : '',
        plastic: bonus ? bonus.plastic_cents != null ? convertFromCents(bonus.plastic_cents) : 0 : '',
        glass_mixed: bonus ? bonus.glass_mixed_cents != null ? convertFromCents(bonus.glass_mixed_cents) : 0 : '',
        glass_clear: bonus ? bonus.glass_clear_cents != null ? convertFromCents(bonus.glass_clear_cents) : 0 : '',
        liquid_paperboard_single: bonus ? bonus.liquid_paperboard_single_cents != null ? convertFromCents(bonus.liquid_paperboard_single_cents) : 0 : '',
        liquid_paperboard_combined: bonus ? bonus.liquid_paperboard_combined_cents != null ? convertFromCents(bonus.liquid_paperboard_combined_cents) : 0 : '',
        metal_single: bonus ? bonus.metal_single_cents != null ? convertFromCents(bonus.metal_single_cents) : 0 : '',
        metal_combined: bonus ? bonus.metal_combined_cents != null ? convertFromCents(bonus.metal_combined_cents) : 0 : '',
        wood: bonus ? bonus.wood_cents != null ? convertFromCents(bonus.wood_cents) : 0 : '',
    }

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useUpsertEkokomBonusOther(
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<EkokomBonusOtherFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading="Upravit hodnoty pro: Ostatní"
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            error={error}
            setError={setError}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowX: 'auto',
            }}>
                <table style={{
                    minWidth: 1000,
                }}>
                    <thead>
                        <tr>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Papír</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Plast</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Směsné sklo</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Čiré sklo</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Nápoj. kartony (samostatné)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Nápoj. kartony (směs)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Kov (samostatné)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Kov (směs)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Dřevo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {materialKeys.map(key => (
                                <td key={key}>
                                    <RHFNumberTextfield
                                        name={key as keyof EkokomBonusOtherFormInput}
                                        control={control}
                                        thousandSeparator
                                        disallowNegative
                                        size="small"
                                        required
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </Box>
        </Form>
    )
}

export default EkokomBonusOtherForm
