import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, DOM_NODE_ID } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import RHFTextarea from "@/components/form-fields/rhf-textarea"
import type { FileWithMetadata, IllegalDumpReportFormInput, IllegalDumpFormInputWithFiles } from "@/types"
import { useSendIllegalDumpReport } from "@/hooks/api/use-illegal-dump"
import useFileUpload from '@/hooks/use-file-upload'
import ImageUpload from "@/components/form-fields/image-upload"

const domNode = document.getElementById(DOM_NODE_ID)
const userEmail = domNode?.getAttribute('user-email')

const IllegalDumpReportForm = ({
    illegalDumpId,
    closeCallback,
} : {
    closeCallback?: () => void,
    illegalDumpId: string | number,
}) => {

    const formDataId = `illegalDumpReportForm-${illegalDumpId}-v${APP_VERSION}`

    const initialValues: IllegalDumpReportFormInput = {
        description: '',
        sender_email: userEmail ?? '',
    }

    const {
        files,
        handleAddFiles,
        handleFileRemove,
        handleReset,
        getFileMetadata,
        getBase64,
    } = useFileUpload()
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useSendIllegalDumpReport(
        illegalDumpId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback() {
                closeCallback()
            },
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<IllegalDumpFormInputWithFiles> = async data => {
        setRequestInProgress(true)
        setError(null)

        try {
            const filesWithMetadata: FileWithMetadata[]  = await Promise.all(
                Array.from(files).map(async file => {
                    const fileMetadata = await getFileMetadata(file)
                    const base64File = await getBase64(file)
                    return {
                        filename: fileMetadata.filename,
                        byte_size: fileMetadata.byteSize,
                        checksum: fileMetadata.checksum,
                        content_type: 'text/plain',
                        file_data: base64File as string,
                    }
                })
            )
    
            mutation.mutate({ ...data, files: filesWithMetadata })
        } catch (error) {
            console.error(error)
        } finally {
            setRequestInProgress(false)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Připojte další hlášení'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={'Připojit k hlášení'}
            error={error}
            setError={setError}
        >
            <RHFTextarea
                name="description"
                control={control}
                label="Popis"
                size="small"
            />
            <RHFTextfield
                name="sender_email"
                control={control}
                label="Váš email"
                size="small"
                required
            />
            <ImageUpload
                files={files}
                handleAddFiles={handleAddFiles}
                handleReset={handleReset}
                handleFileRemove={handleFileRemove}
            />
        </Form>
    )
}

export default IllegalDumpReportForm
