import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CollectionCalendar from '@/components/collection-calendar/calendar'
import CollectionTables from '@/components/collection-calendar/tables'

function CollectionCalendarView() {

    const title = 'Svozový kalendář'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Typography variant="h2" component="h1" mb={3}>
                    {title}
                </Typography>
                <CollectionTables />
                <Box pt={5} />
                <CollectionCalendar />
            </CustomPaper>
        </>
    )
}

export default CollectionCalendarView
