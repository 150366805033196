import type { Dispatch, SetStateAction, SyntheticEvent } from "react"
import { useAtom } from "jotai"
import Autocomplete from "@mui/material/Autocomplete"
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import TextField from "@mui/material/TextField"
import { useMotivationModels } from "@/hooks/api/use-motivation-model"

import { selectedMunicipalityIdAtom } from "@/state"

export default function MotivationModelSelector({
    motivationModelId,
    setMotivationModelId,
    year,
}: {
    motivationModelId: number,
    setMotivationModelId: Dispatch<SetStateAction<number>>,
    year: number,
}) {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const { data } = useMotivationModels(municipalityId, year)

    const options = data ? data : []

    return (
        <Autocomplete
            value={
                municipalityId != null
                    ? options.find((option) => {
                        return motivationModelId === option.id
                    }) ?? null
                    : null
            }
            getOptionLabel={(option) => {
                return `${option.name}${option.active ? ' 🟢' : ''}`
            }}
            getOptionKey={(option) => {
                return option.id
            }}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue)
                const parts = parse(option.name, matches)

                const text = (parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400,
                        }}
                    >
                        {part.text}
                    </span>
                )))

                return (
                    <li {...props} key={props.key}>
                        <div>
                            {text}{option.active ? ' 🟢' : ''}
                        </div>
                    </li>
                );
            }}
            onChange={(_event: SyntheticEvent, newValue) => {
                setMotivationModelId(newValue ? newValue.id : null)
            }}
            id="motivation-model-selector"
            options={options}
            disableClearable
            noOptionsText={`Obec zatím nemá pro rok ${year} žádné modely`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Motivační model"
                    inputProps={{
                        ...params.inputProps,
                        // disable autocomplete and autofill
                        autoComplete: "off",
                    }}
                    autoComplete="off"
                    size="small"
                />
            )}
        />
    )
}
