import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { Link } from 'react-router-dom'

import { convertFromCents, getYearTab, printPrice, roundPrice } from '@/helpers'
import type { MotivationModelScenario, MunicipalityFinanceYearSum } from '@/types'
import { ROUTE_SEGMENTS, START_YEAR, YEARS_FROM_START_TO_NEXT, YEARS_FROM_START_TO_THIS } from '@/constants/general'

export default function IncomeStatement({
    scenario,
    previousYearScenario,
    twoYearsAgoScenario,
    sumsTwoYearsAgo,
    modelYear,
    isLoading,
}: {
    scenario: MotivationModelScenario,
    previousYearScenario?: MotivationModelScenario,
    twoYearsAgoScenario?: MotivationModelScenario,
    sumsTwoYearsAgo?: MunicipalityFinanceYearSum,
    modelYear: number,
    isLoading: boolean,
}) {

    const hasRealData = !!sumsTwoYearsAgo

    const realExpenses = hasRealData ? sumsTwoYearsAgo.bin_collection_expenses
        +sumsTwoYearsAgo.collection_yard_expenses
        +sumsTwoYearsAgo.other_expenses : null

    const expenses = [
        {
            name: 'Náklady',
            value: scenario.expenses.total,
            previousValue: previousYearScenario?.expenses.total ?? '-',
            realityValue: realExpenses ? realExpenses*100 : twoYearsAgoScenario?.expenses.total ?? '-',
            header: true,
        },
        {
            name: 'Nádobový sběr',
            value:
                scenario.expenses.bin_dump + scenario.expenses.weight + scenario.expenses.penalty,
            previousValue: Number.isNaN(
                previousYearScenario?.expenses.bin_dump +
                    previousYearScenario?.expenses.weight +
                    previousYearScenario?.expenses.penalty
            )
                ? '-'
                : previousYearScenario?.expenses.bin_dump +
                  previousYearScenario?.expenses.weight +
                  previousYearScenario?.expenses.penalty,
            realityValue: hasRealData ? sumsTwoYearsAgo.bin_collection_expenses*100 : Number.isNaN(
                twoYearsAgoScenario?.expenses.bin_dump +
                    twoYearsAgoScenario?.expenses.weight +
                    twoYearsAgoScenario?.expenses.penalty
            )
                ? '-'
                : twoYearsAgoScenario?.expenses.bin_dump +
                  twoYearsAgoScenario?.expenses.weight +
                  twoYearsAgoScenario?.expenses.penalty,
        },
        {
            name: 'Výklopy',
            value: scenario.expenses.bin_dump,
            previousValue: previousYearScenario?.expenses.bin_dump ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoScenario?.expenses.bin_dump ?? '-',
            secondary: true,
        },
        {
            name: 'Odstranění odpadu',
            value: scenario.expenses.weight,
            previousValue: previousYearScenario?.expenses.weight ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoScenario?.expenses.weight ?? '-',
            secondary: true,
        },
        {
            name: 'Fond živ. prostředí',
            value: scenario.expenses.penalty,
            previousValue: previousYearScenario?.expenses.penalty ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoScenario?.expenses.penalty ?? '-',
            secondary: true,
        },
        {
            name: 'Provoz sběrných dvorů',
            value: scenario.expenses.collection_yard_operation_cents,
            previousValue: previousYearScenario?.expenses.collection_yard_operation_cents ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.collection_yard_expenses*100 : twoYearsAgoScenario?.expenses.collection_yard_operation_cents ?? '-',
        },
        {
            name: 'Ostatní náklady',
            value: scenario.expenses.waste_management_cents,
            previousValue: previousYearScenario?.expenses.waste_management_cents ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.other_expenses*100 : twoYearsAgoScenario?.expenses.waste_management_cents ?? '-',
        },
    ]

    const ekokomIncomes = scenario.incomes.ekokom
    const previousEkokomIncomes = previousYearScenario?.incomes.ekokom
    const twoYearsAgoEkokomIncomes = twoYearsAgoScenario?.incomes.ekokom

    const realIncomes = hasRealData ? sumsTwoYearsAgo.citizen_fee_income+sumsTwoYearsAgo.collection_yard_income
        +sumsTwoYearsAgo.ekokom_income+sumsTwoYearsAgo.other_income : null

    const incomes = [
        {
            name: 'Příjmy',
            value: scenario.incomes.total,
            previousValue: previousYearScenario?.incomes.total ?? '-',
            realityValue: realIncomes ? realIncomes*100 : twoYearsAgoScenario?.incomes.total ?? '-',
            header: true,
        },
        {
            name: 'EKOKOM',
            value: ekokomIncomes.ekokom_total,
            previousValue: previousEkokomIncomes?.ekokom_total ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.ekokom_income*100 : twoYearsAgoEkokomIncomes?.ekokom_total ?? '-',
        },
        {
            name: 'Papír',
            value: ekokomIncomes.paper,
            previousValue: previousEkokomIncomes?.paper ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoEkokomIncomes?.paper ?? '-',
            secondary: true,
        },
        {
            name: 'Plast',
            value: ekokomIncomes.plastic,
            previousValue: previousEkokomIncomes?.plastic ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoEkokomIncomes?.plastic ?? '-',
            secondary: true,
        },
        {
            name: 'Sklo',
            value: ekokomIncomes.glass_clear + ekokomIncomes.glass_mixed,
            previousValue: Number.isNaN(
                previousEkokomIncomes?.glass_clear + previousEkokomIncomes?.glass_mixed
            )
                ? '-'
                : previousEkokomIncomes?.glass_clear + previousEkokomIncomes?.glass_mixed,
            realityValue: hasRealData ? '-' : Number.isNaN(
                twoYearsAgoEkokomIncomes?.glass_clear + twoYearsAgoEkokomIncomes?.glass_mixed
            )
                ? '-'
                : twoYearsAgoEkokomIncomes?.glass_clear + twoYearsAgoEkokomIncomes?.glass_mixed,
            secondary: true,
        },
        {
            name: 'Kov',
            value: ekokomIncomes.metal_combined + ekokomIncomes.metal_single,
            previousValue: Number.isNaN(
                previousEkokomIncomes?.metal_combined + previousEkokomIncomes?.metal_single
            )
                ? '-'
                : previousEkokomIncomes?.metal_combined + previousEkokomIncomes?.metal_single,
            realityValue: hasRealData ? '-' : Number.isNaN(
                twoYearsAgoEkokomIncomes?.metal_combined + twoYearsAgoEkokomIncomes?.metal_single
            )
                ? '-'
                : twoYearsAgoEkokomIncomes?.metal_combined + twoYearsAgoEkokomIncomes?.metal_single,
            secondary: true,
        },
        {
            name: 'Nápojové kartony',
            value:
                ekokomIncomes.liquid_paperboard_combined + ekokomIncomes.liquid_paperboard_single,
            previousValue: Number.isNaN(
                previousEkokomIncomes?.liquid_paperboard_combined +
                    previousEkokomIncomes?.liquid_paperboard_single
            )
                ? '-'
                : previousEkokomIncomes?.liquid_paperboard_combined +
                  previousEkokomIncomes?.liquid_paperboard_single,
            realityValue: hasRealData ? '-' : Number.isNaN(
                twoYearsAgoEkokomIncomes?.liquid_paperboard_combined +
                    twoYearsAgoEkokomIncomes?.liquid_paperboard_single
            )
                ? '-'
                : twoYearsAgoEkokomIncomes?.liquid_paperboard_combined +
                  twoYearsAgoEkokomIncomes?.liquid_paperboard_single,
            secondary: true,
        },
        {
            name: 'Dřevo',
            value: ekokomIncomes.wood,
            previousValue: previousEkokomIncomes?.wood ?? '-',
            realityValue: hasRealData ? '-' : twoYearsAgoEkokomIncomes?.wood ?? '-',
            secondary: true,
        },
        {
            name: 'Příjmy ze sběr. dvorů',
            value: scenario.incomes.collection_yard_income,
            previousValue: previousYearScenario?.incomes.collection_yard_income ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.collection_yard_income*100 : twoYearsAgoScenario?.incomes.collection_yard_income ?? '-',
        },
        {
            name: 'Ostatní příjmy',
            value: scenario.incomes.other_income,
            previousValue: previousYearScenario?.incomes.other_income ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.other_income*100 : twoYearsAgoScenario?.incomes.other_income ?? '-',
        },
        {
            name: 'Poplatek občanů',
            value: scenario.incomes.citizens_income,
            previousValue: previousYearScenario?.incomes.citizens_income ?? '-',
            realityValue: hasRealData ? sumsTwoYearsAgo.citizen_fee_income*100 : twoYearsAgoScenario?.incomes.citizens_income ?? '-',
        },
        {
            name: 'Poplatek bez slevy',
            value: scenario.incomes.citizens_income_without_discount-scenario.incomes.non_paying_citizens_expanse,
            previousValue: hasRealData ? '-' : Number.isNaN(
                    previousYearScenario?.incomes.citizens_income_without_discount - previousYearScenario?.incomes.non_paying_citizens_expanse
                )
                    ? '-'
                    : previousYearScenario?.incomes.citizens_income_without_discount - previousYearScenario?.incomes.non_paying_citizens_expanse,
            realityValue: hasRealData ? '-' : Number.isNaN(
                twoYearsAgoScenario?.incomes.citizens_income_without_discount - twoYearsAgoScenario?.incomes.non_paying_citizens_expanse
            )
                ? '-'
                : twoYearsAgoScenario?.incomes.citizens_income_without_discount - twoYearsAgoScenario?.incomes.non_paying_citizens_expanse,
            secondary: true,
        },
        {
            name: 'Sleva z min. roku',
            value: scenario.incomes.this_year_discount != null ? scenario.incomes.this_year_discount*(-1) : '-',
            previousValue: previousYearScenario?.incomes.this_year_discount != null ? previousYearScenario.incomes.this_year_discount*(-1) : '-',
            realityValue: twoYearsAgoScenario?.incomes.this_year_discount != null ? twoYearsAgoScenario.incomes.this_year_discount*(-1) : '-',
            secondary: true,
        },
    ]

    const balance = [
        // {
        //     name: 'Podíl SKO',
        //     value: (scenario.mixed_ratio * 100).toFixed(2)+'%',
        // },
        {
            name: 'Doplatek obce',
            value: scenario.annual_surcharge_cents,
            previousValue: previousYearScenario?.annual_surcharge_cents ?? '-',
            realityValue: hasRealData ? (realIncomes-realExpenses)*100 : twoYearsAgoScenario?.annual_surcharge_cents ?? '-',
            header: true,
        },
        {
            name: 'Sleva příští rok',
            value: scenario.incomes?.next_year_discount != null ? scenario.incomes.next_year_discount : '-',
            previousValue: previousYearScenario?.incomes?.next_year_discount != null ? previousYearScenario.incomes.next_year_discount : '-',
            realityValue: hasRealData ? '-' : twoYearsAgoScenario?.incomes?.next_year_discount != null ? twoYearsAgoScenario.incomes.next_year_discount : '-',
        },
        // {
        //     name: 'Doplatek občané',
        //     value: scenario.balance.citizens_costs,
        // },
        // {
        //     name: 'Doplatek celkem',
        //     value: scenario.balance.total_costs,
        // },
    ]

    const thStyles = {
        paddingLeft: '1.5rem',
        paddingBottom: '0.3rem',
        borderBottom: '1px solid #E8E8E8',
    }
    const headerStyles = {
        paddingBottom: '0.3rem',
        borderBottom: '1px solid #E8E8E8',
        paddingTop: '0.3rem',
    }

    const footerStyles = {
        paddingBottom: '0.1rem',
        paddingTop: '0.3rem',
        borderTop: '1px solid #E8E8E8',
    }
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 1,
            opacity: isLoading ? 0.3 : 'unset',
            flexGrow: 1,
            overflowX: 'auto',
        }}>
            <Typography variant="h4" component="h2" mb={2} textAlign="center">
                Ekonomická rozvaha
            </Typography>
            <Box sx={(theme) => ({
                borderRadius: 2.5,
                border: `1px solid ${theme.palette.divider}`,
                px: 3,
                py: 1.5,
                // color: theme.palette.customGrey.tableText,
                overflowX: 'auto',
                zIndex: 2,
                mb: 2,
            })}>
                <table style={{ width: '100%', minWidth: 500 }}>
                    <tbody>
                        <tr>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25 }}>
                                    Plán&nbsp;{modelYear}
                                </Typography>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25, color: 'customGrey.muteText' }}>
                                    Odhad&nbsp;{modelYear-1}
                                </Typography>
                            </th>
                            <th style={{ ...thStyles, textAlign: 'right' }}>
                                <Typography variant="h5" component="span" sx={{ lineHeight: 1.25, color: 'customGrey.muteText' }}>
                                    {hasRealData ? 'Realita' : 'Odhad'}&nbsp;{modelYear-2}
                                </Typography>
                            </th>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '0.2rem' }}/>
                        </tr>
                        {expenses.map(expenseItem => (
                            <tr key={expenseItem.name}>
                                <td style={{
                                    paddingLeft: expenseItem.secondary ? '1.5rem' : 'unset',
                                    ...expenseItem.header ? headerStyles : null
                                }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...expenseItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {expenseItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...expenseItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {typeof expenseItem.value === 'number' ?
                                            printPrice(roundPrice(convertFromCents(expenseItem.value)), false)
                                            :
                                            expenseItem.value
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, color: 'customGrey.muteText', ...expenseItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof expenseItem.previousValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(expenseItem.previousValue)), false)
                                            :
                                            expenseItem.previousValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...expenseItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={expenseItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, color: 'customGrey.muteText', ...expenseItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof expenseItem.realityValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(expenseItem.realityValue)), false)
                                            :
                                            expenseItem.realityValue
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ paddingTop: '1rem' }}/>
                        </tr>
                        {incomes.map(incomeItem => (
                            <tr key={incomeItem.name}>
                                <td style={{
                                    paddingLeft: incomeItem.secondary ? '1.5rem' : 'unset',
                                    ...incomeItem.header ? headerStyles : null
                                }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...incomeItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {incomeItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, ...incomeItem.secondary ? { color: 'customGrey.secondaryText'} : null }}
                                    >
                                        {typeof incomeItem.value === 'number' ?
                                            printPrice(roundPrice(convertFromCents(incomeItem.value)), false)
                                            :
                                            incomeItem.value
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, color: 'customGrey.muteText', ...incomeItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof incomeItem.previousValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(incomeItem.previousValue)), false)
                                            :
                                            incomeItem.previousValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...incomeItem.header ? headerStyles : null }}>
                                    <Typography
                                        variant={incomeItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25, color: 'customGrey.muteText', ...incomeItem.secondary ? { color: 'customGrey.muteText'} : null }}
                                    >
                                        {typeof incomeItem.realityValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(incomeItem.realityValue)), false)
                                            :
                                            incomeItem.realityValue
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ paddingTop: '1rem' }}/>
                            <td style={{ paddingTop: '1rem' }}/>
                        </tr>
                        {balance.map(balanceItem => (
                            <tr key={balanceItem.name}>
                                <td style={balanceItem.header ? footerStyles : null }>
                                    <Typography
                                        variant={balanceItem.header ? 'h5' : 'body1'}
                                        component="span"
                                        sx={{ lineHeight: 1.25 }}
                                    >
                                        {balanceItem.name}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography variant={balanceItem.header ? 'h5' : 'body1'} component="span" sx={{ lineHeight: 1.25 }}>
                                        {typeof balanceItem.value === 'number' ?
                                            printPrice(roundPrice(convertFromCents(balanceItem.value)), false)
                                            :
                                            balanceItem.value
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography variant={balanceItem.header ? 'h5' : 'body1'} component="span" sx={{ lineHeight: 1.25, color: 'customGrey.muteText' }}>
                                        {typeof balanceItem.previousValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(balanceItem.previousValue)), false)
                                            :
                                            !previousYearScenario && (modelYear-1 >= START_YEAR) ?
                                                <ErrorOutlineRoundedIcon color="error" />
                                                : 
                                                balanceItem.previousValue
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '2rem', ...balanceItem.header ? footerStyles : null }}>
                                    <Typography variant={balanceItem.header ? 'h5' : 'body1'} component="span" sx={{ lineHeight: 1.25, color: 'customGrey.muteText' }}>
                                        {typeof balanceItem.realityValue === 'number' ?
                                            printPrice(roundPrice(convertFromCents(balanceItem.realityValue)), false)
                                            :
                                            !twoYearsAgoScenario && (modelYear-2 >= START_YEAR) ?
                                                <ErrorOutlineRoundedIcon color="error" />
                                                : 
                                                balanceItem.realityValue
                                        }
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
            {!previousYearScenario && (modelYear-1 >= START_YEAR) &&
                <Box>
                    <Alert
                        severity="error"
                        sx={(theme) => ({
                            flexGrow: 1,
                            margin: theme.spacing(1, 0),
                        })}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            Odhad pro {modelYear-1} je prázdný, protože nemáte aktivní žádný model pro {modelYear-1}. Model můžete doplnit v seznamu motivačních modelů nebo kliknutím na tlačítko níže.
                            <Box display="flex" justifyContent="center" pr={4} pt={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    component={Link}
                                    to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationProgram}?tab=${getYearTab(modelYear-1, YEARS_FROM_START_TO_NEXT)}`}
                                >
                                    Zvolit model pro {modelYear-1}
                                </Button>
                            </Box>
                        </Box>
                    </Alert>
                </Box>
            }
            {!twoYearsAgoScenario && !hasRealData && (modelYear-2 >= START_YEAR) &&
                <Alert
                    severity="error"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(1, 0),
                    })}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        Odhad pro {modelYear-2} je prázdný, protože nemáte doplněná historická data hospodaření obce ani žádný aktivní model pro {modelYear-2}. Data můžete doplnit v záložce 'Hospodaření obce' nebo kliknutím na tlačítko níže. Model můžete doplnit v seznamu motivačních modelů nebo kliknutím na tlačítko níže.
                        <Box display="flex" justifyContent="center" gap={1} pr={4} pt={2}>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                component={Link}
                                to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.finances}?tab=${getYearTab(modelYear-2, YEARS_FROM_START_TO_THIS)}`}
                            >
                                Doplnit data pro {modelYear-2}
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                component={Link}
                                to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationProgram}?tab=${getYearTab(modelYear-2, YEARS_FROM_START_TO_NEXT)}`}
                            >
                                Zvolit model pro {modelYear-2}
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            }
        </Box>
    )
}
