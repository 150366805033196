import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import Alert from '@mui/material/Alert'

import type { User } from '@/types'
import { useSendUserWelcomeEmail } from '@/hooks/api/use-users'

function SendUserWelcomeEmail({
    user,
}: {
    user: User,
}) {

    const [requestInProgress, setRequestInProgress] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)

    const toggleActiveMutation = useSendUserWelcomeEmail(user.id, {
        onSuccessCallback(data) {
            if(data) {
                setSuccess(true)
            }
        },
        onErrorCallback(errorMessage) {
            setError(errorMessage)
        },
        setRequestInProgress,
    })

    const handleSendEmail = () => {
        toggleActiveMutation.mutate()
    }

    return (
        <Box mt={1}>
            {success &&
                <Alert
                    severity="success"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(1, 0),
                    })}
                    onClose={() => setSuccess(false)}
                >
                    Email úspěšně odeslán.
                </Alert>
            }
            {error &&
                <Alert
                    severity="error"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(1, 0),
                    })}
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            }
            {!success && !error &&
                <Button
                    startIcon={<EmailRoundedIcon />}
                    onClick={() => handleSendEmail()}
                    color='terciary'
                    variant="outlined"
                    disabled={requestInProgress}
                >
                    Odeslat přístupový email
                </Button>
            }
        </Box>
    )
}

export default SendUserWelcomeEmail
