import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { APP_VERSION } from "@/constants/general"

import { useVersionCheck } from '@/hooks/api/use-version-check'

const VersionCheck = () => {

    const handleReload = () => {
        location.reload()
    }

    const { data } = useVersionCheck()
    
    if (data && APP_VERSION !== data) {
        return (
            <Box pb={1.5}>
                <Alert
                    severity="warning"
                    sx={{
                        flexGrow: 1,
                        borderRadius: 2.5,
                    }}
                    action={
                        <Button
                            color="warning"
                            size="small"
                            onClick={() => handleReload()}
                        >
                            Obnovit stránku
                        </Button>
                    }
                >
                    Je k dispozici nová verze aplikace. Uložte si neuloženou práci a obnovte stránku.
                </Alert>
            </Box>
        )
    }

    return null
}

export default VersionCheck
