import axios, { type AxiosError } from 'axios'
import {
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import type {
    CollectionPointDetail,
    CollectionPointListItem,
    ISODateString,
    CollectionPointWeightingsRespose,
    CollectionPointMixedWeightCategory,
    CollectionPointMixedVolumeCategory,
    CollectionCalendarEntry,
    MutationOptions,
} from '@/types'
import { isErrorResponse } from '@/helpers'

export function useCollectionPoints(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['collectionPoints', municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<CollectionPointListItem> } = await axios.get(
                `/api/eoko/household/list?municipality_id=${municipalityId}`,
            )
            return data
        },
        enabled: !!municipalityId,
    })
}

export function useCollectionPoint(collectionPointId) {
    return useQuery({
        queryKey: ['collectionPoint', collectionPointId],
        queryFn: async () => {
            const { data } : { data: CollectionPointDetail } = await axios.get(
                '/api/eoko/household/single?id=' + collectionPointId,
            )
            return data
        },
    })
}

export function useCollectionPointWeightings(
    collectionPointId: number,
    beginsAt: ISODateString,
    endsAt: ISODateString,
) {
    return useQuery({
        queryKey: ['collectionPointWeightings', collectionPointId, 'beginsAt-'+beginsAt, 'endsAt-'+endsAt],
        queryFn: async () => {
            const { data } : { data: CollectionPointWeightingsRespose } = await axios.get(
                `/api/eoko/household/bin_weightings?id=${collectionPointId
                    }&begins_at=${beginsAt
                    }&ends_at=${endsAt}`,
            )
            return data
        },
    })
}

export function useCollectionPointMixedWeightCategory(collectionPointId) {
    return useQuery({
        queryKey: ['collectionPointMixedWeightCategory', collectionPointId],
        queryFn: async () => {
            const { data } : { data: CollectionPointMixedWeightCategory } = await axios.get(
                '/api/eoko/household/mixed_weight_category_info?household_id=' + collectionPointId,
            )
            return data
        },
        retry: false,
    })
}

export function useCollectionPointMixedVolumeCategory(collectionPointId) {
    return useQuery({
        queryKey: ['collectionPointMixedVolumeCategory', collectionPointId],
        queryFn: async () => {
            const { data } : { data: CollectionPointMixedVolumeCategory } = await axios.get(
                '/api/eoko/household/mixed_volume_category_info?household_id=' + collectionPointId,
            )
            return data
        },
        retry: false,
    })
}

export function useCollectionPointCollectionCalendar(
    collectionPointId: number,
    year: number,
) {
    return useQuery({
        queryKey: ['collectionPointCollectionCalendar', collectionPointId, 'year-'+year],
        queryFn: async () => {
            const { data } : { data: Array<CollectionCalendarEntry> } = await axios.get(
                `/api/eoko/household/collection_calendar?household_id=${collectionPointId
                    }&year=${year}`,
            )
            return data
        },
        enabled: !!collectionPointId,
    })
}

export const useVerifyCollectionPointByUser = (collectionPointId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        setRequestInProgress,
        onErrorCallback,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/eoko/household/verify_by_user?household_id=${collectionPointId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collectionPoint', collectionPointId] })
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}
