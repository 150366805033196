import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'
import PeopleIcon from '@mui/icons-material/People'
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded'

import DetailHeader from '@/components/collection-point/detail/detail-header'
import CollectionPointDetailBins from '@/components/collection-point/detail/waste-containers'
import CollectionPointAdmin from '@/components/collection-point/detail/admin'
// import { Payers } from '@/components/collection-point/detail/payers'
import { useCollectionPoint } from '@/hooks/api/use-collection-point'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { selectedCollectionPointIdAtom } from '@/state'
import HeadingWithIcon from '@/components/heading-with-icon'
import MixedWeightDiscountTarget from './mixed-weight-discount-target'
import MixedVolumeDiscountTarget from './mixed-volume-discount-target'
import ForcedDiscountTarget from './forced-discount-target'

export default function CollectionPointDetailWrapper() {
    
    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)
    
    const { status, data, error } = useCollectionPoint(collectionPointId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst detail svozového místa s ID ${collectionPointId}`}
        />
    }

    const disabledDiscounts = data.force_discount_cents === 0 || (!data.force_discount_cents && data.mixed_volume_categories_disabled && data.mixed_weight_categories_disabled)
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        }}>
            <Box  sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h2" component="h1" mb={2.5}>
                    Detail svozového místa: {data.code}
                </Typography>
                <DetailHeader collectionPointDetail={data} />
            </Box>
            {!disabledDiscounts &&
                <Box>
                    <HeadingWithIcon
                        text="Slevový cíl"
                        icon={<SavingsRoundedIcon />}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row',
                            },
                            columnGap: 3,
                            rowGap: 1,
                            pt: 2,
                        }}
                    >
                        {data.force_discount_cents ? 
                            <ForcedDiscountTarget amountCents={data.force_discount_cents} />
                            :
                            <>
                                {!data.mixed_weight_categories_disabled &&
                                    <MixedWeightDiscountTarget
                                        collectionPointDetail={data}
                                    />
                                }
                                {!data.mixed_volume_categories_disabled &&
                                    <MixedVolumeDiscountTarget
                                        collectionPointDetail={data}
                                    />
                                }
                            </>
                        }
                    </Box>
                </Box>
            }
            <CollectionPointDetailBins bins={data.bins} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 6,
                flexWrap: 'wrap',
            }}>
                <CollectionPointAdmin admin={data.admin}/>
                {/* <Payers /> */}
                <Box>
                    <HeadingWithIcon
                        text="Celkový počet poplatníků"
                        icon={<PeopleIcon />}
                    />
                    <Box pt={1.5}>{data.number_of_members ? data.number_of_members : 'Zatím nedoplněno v EOKU'}</Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'center',
            }}>
                <Button
                    variant="contained"
                    color="terciary"
                    disabled
                    onClick={() => alert('klik!')} // @todo
                >
                    Nahlásit změnu
                </Button>
            </Box>
        </Box>
    )
}
