import { useState } from "react"
import dayjs, { type Dayjs } from "dayjs"

interface Options {
    callback?: () => void
    initialStartDate?: Dayjs
    initialEndDate?: Dayjs
}

const useDateInterval = (options?: Options) => {

    const {
        callback,
        initialStartDate,
        initialEndDate,
    } = options || {}
    
    const currentDate = dayjs()
    const lastCalendarYearStartDate = currentDate.subtract(1, 'year').startOf('year')
    const lastCalendarYearEndDate = currentDate.subtract(1, 'year').endOf('year')

    const thisCalendarYearStartDate = currentDate.startOf('year')
    const thisCalendarYearEndDate = currentDate.endOf('year')

    const last12MonthsStartDate = currentDate.subtract(1, 'year').startOf('month')
    const last12MonthsEndDate = currentDate.subtract(1, 'month').endOf('month')

    const last365DaysStartDate = currentDate.subtract(1, 'year').startOf('day')
    const last365DaysEndDate = currentDate.endOf('day')

    const [startDate, setStartDate] = useState<Dayjs>(initialStartDate ?? last12MonthsStartDate)
    const [endDate, setEndDate] = useState<Dayjs>(initialEndDate ?? last12MonthsEndDate)
    const [startDateValue, setStartDateValue] = useState<Dayjs>(initialStartDate ?? last12MonthsStartDate)
    const [endDateValue, setEndDateValue] = useState<Dayjs>(initialEndDate ?? last12MonthsEndDate)

    const handleSetDates = () => {
        setStartDate(startDateValue.startOf('month'))
        setEndDate(endDateValue.endOf('month'))
        if (callback) callback()
    }

    const handleSetLast12Months = () => {
        setStartDateValue(last12MonthsStartDate)
        setStartDate(last12MonthsStartDate)
        setEndDateValue(last12MonthsEndDate)
        setEndDate(last12MonthsEndDate)
        if (callback) callback()
    }

    const handleSetLast365Days = () => {
        setStartDateValue(last365DaysStartDate)
        setStartDate(last365DaysStartDate)
        setEndDateValue(last365DaysEndDate)
        setEndDate(last365DaysEndDate)
        if (callback) callback()
    }

    const handleSetLastCalendarYear = () => {
        setStartDateValue(lastCalendarYearStartDate)
        setStartDate(lastCalendarYearStartDate)
        setEndDateValue(lastCalendarYearEndDate)
        setEndDate(lastCalendarYearEndDate)
        if (callback) callback()
    }

    const handleSetThisCalendarYear = () => {
        setStartDateValue(thisCalendarYearStartDate)
        setStartDate(thisCalendarYearStartDate)
        setEndDateValue(thisCalendarYearEndDate)
        setEndDate(thisCalendarYearEndDate)
        if (callback) callback()
    }

    let selectedInterval: 'last365days' | 'last12months' | 'thisCalendarYear' | 'lastCalendarYear' | 'other'  = 'other'

    if (startDate.isSame(last365DaysStartDate) && endDate.isSame(last365DaysEndDate)) selectedInterval = 'last365days'
    if (startDate.isSame(last12MonthsStartDate) && endDate.isSame(last12MonthsEndDate)) selectedInterval = 'last12months'
    if (startDate.isSame(thisCalendarYearStartDate) && endDate.isSame(thisCalendarYearEndDate)) selectedInterval = 'thisCalendarYear'
    if (startDate.isSame(lastCalendarYearStartDate) && endDate.isSame(lastCalendarYearEndDate)) selectedInterval = 'lastCalendarYear'

    const isDirty = startDate !== startDateValue || endDate !== endDateValue

    const isInvalid = startDateValue > endDateValue

    return {
        handleSetDates,
        handleSetLast12Months,
        handleSetLastCalendarYear,
        handleSetLast365Days,
        handleSetThisCalendarYear,
        startDate,
        endDate,
        startDateValue,
        setStartDateValue,
        endDateValue,
        setEndDateValue,
        selectedInterval,
        isDirty,
        isInvalid,
    }

}

export default useDateInterval
