import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type {
    CollectionPointCategoryWithSingleExtension,
    UpdateCollectionPointParticipationFormInput,
} from "@/types"
import { convertFromCents } from "@/helpers"
import { useUpdateCollectionPointParticipation } from "@/hooks/api/use-municipality"
import RHFNumberTextfield from "../form-fields/rhf-number-textfield"

const CollectionPointParticipationForm = ({
    collectionPoint,
    year,
    closeCallback,
} : {
    collectionPoint: CollectionPointCategoryWithSingleExtension,
    year: number,
    closeCallback?: () => void,
}) => {
    
    const formDataId = `collectionPointParticipationForm-${collectionPoint?.id}-v${APP_VERSION}`

    const initialValues: UpdateCollectionPointParticipationFormInput = {
        in_motivation_program: collectionPoint.ext?.in_motivation_program != null ?
            collectionPoint.ext.in_motivation_program : true,
        force_discount: collectionPoint.ext?.force_discount_cents != null ?
            convertFromCents(collectionPoint.ext.force_discount_cents).toString() :
            '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useUpdateCollectionPointParticipation(
        collectionPoint.id,
        Number(year),
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<UpdateCollectionPointParticipationFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    const inMotivationProgram = watch('in_motivation_program')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            heading="Upravit účast/slevu svozového místa:"
        >
            <Typography variant="h5" component="h3" gutterBottom>
                {collectionPoint.address?.street ?? '-'} {collectionPoint.address?.street_number ?? '-'}
            </Typography>
            <Box mx="auto" mb={1}>
                <FormControlLabel control={
                    <Controller
                        name="in_motivation_program"
                        control={control}
                        render={({ field }) => <Switch
                            {...field}
                            value={!!field.value}
                            checked={!!field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                        />}
                    />
                } label="Svozové místo se účastní motivačního programu" />
            </Box>
            <RHFNumberTextfield
                name="force_discount"
                control={control}
                label="Fixní sleva"
                disabled={inMotivationProgram}
                disallowNegative
            />
            
        </Form>
    )
}

export default CollectionPointParticipationForm
