import { useState } from 'react'
import { useAtom } from 'jotai'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

import type { CollectionPointCategoryListFilters, CreateCategoryListExportResponse } from '@/types'
import { useCreateCategoryListExport } from '@/hooks/api/use-export'
import CollectionPointCategoriesExportDownload from './export-download'
import { exportIdAtom } from '@/state'

export default function CollectionPointCategoriesExport({
    year,
    motivationModelId,
    filters,
}: {
    year: number,
    motivationModelId: number,
    filters: CollectionPointCategoryListFilters,
}) {

    const [exportId, setExportId] = useAtom(exportIdAtom)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

    const successCallback = (data: CreateCategoryListExportResponse) => {
        if (data.id) {
            setExportId(data.id)
        }
    }

    const mutation = useCreateCategoryListExport(
        year,
        motivationModelId,
        {
            onSuccessCallback: successCallback,
            setRequestInProgress,
        },
    )

    const handleClickExport = () => {
        setRequestInProgress(true)
        mutation.mutate(filters)
    }

    return (
        <>
            <Button
                variant="outlined"
                color="terciary"
                startIcon={<CloudDownloadIcon />}
                onClick={() => handleClickExport()}
                disabled={requestInProgress}
            >
                Export do .xlsx
            </Button>
            {exportId &&
                <CollectionPointCategoriesExportDownload
                    exportId={exportId}
                    handleClose={() => setExportId(null)}
                />
            }
        </>
    )
}
