import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'

export default function CategoryLabel({
    text,
    severity,
    color,
    small,
}: {
    text: string,
    severity: number,
    color: string,
    small?: boolean,
}) {

    return (
        <Box display="flex" alignItems="center" gap={1} sx={{ color }}>
            <Typography variant="h5" component="h3">
                {text}
            </Typography>
            {small ?
                <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                :
                <Box display="flex">
                    {[...Array(severity)].map((_cell, i) => (
                        <DeleteIcon sx={{ fontSize: '1.1rem' }} key={i} />
                    ))}
                </Box>
            }
        </Box>
    )
}
